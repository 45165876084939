import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { ButtonProps, CircularProgress } from '@wooriga/design-system';

export const REGISTRATION_BUTTON_STYLES = {
  ['ACTIVATING']: {
    startDecorator: <CircularProgress />,
    disabled: true,
    children: '등기 변동 알림 켜는중...',
  },
  ['ACTIVATED']: {
    color: 'primary',
    startDecorator: <NotificationsActiveIcon />,
    children: '등기 변동 알림 켜짐',
  },
  ['DEACTIVATING']: {
    startDecorator: <CircularProgress />,
    disabled: true,
    children: '등기 변동 알림 끄는중...',
  },
  ['DEACTIVATED']: {
    color: 'neutral',
    startDecorator: <NotificationsOffIcon />,
    children: '등기 변동 알림 꺼짐',
  },
} as {
  [key: string]: ButtonProps;
};
