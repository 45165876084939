import {
  Box,
  DataGrid,
  DateRangePicker,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CounselsParams, useCounselsQuery } from 'apis/counsel/apis';
import { COUNSELS_GRID_COLUMNS } from 'apis/counsel/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import counselDetailPage from 'pages/main/counsel-management/counsels/detail';
import counselModifyPage from 'pages/main/counsel-management/counsels/modify';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

export interface CounselsFormValues {
  unionSeq: number;
  unionRegisterNo?: string;
  unionRegisterName?: string;
  unionRegisterSeq?: string;
  searchFromTo?: string[] | undefined[];
  searchFrom?: string;
  searchTo?: string;
  serviceType?: string;
  page?: number;
  pageSize?: number;
}

const CounselsPage = () => {
  const params = useParams();
  const { datagridApiRef } = useGridUtils({ key: 'counsels' });
  const unionSeq = Number(params.unionSeq);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const defaultSearchParams = {
    unionRegisterNo: '',
    unionRegisterName: '',
    searchFromTo: [undefined, undefined],
    serviceType: '',
    searchFrom: '',
    searchTo: '',
    unionSeq,
  };

  const [searchParams, setSearchParams] = useState<CounselsFormValues>({
    ...defaultSearchParams,
  });

  const handleSearchParams = (values: Partial<CounselsFormValues>) => {
    if (!values) {
      return;
    }

    const { searchFromTo, ...other } = values;

    const body: CounselsParams = {
      unionSeq,
      ...other,
    };

    if (searchFromTo) {
      body.searchFrom = values.searchFromTo?.[0];
      body.searchTo = values.searchFromTo?.[1];
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...body }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...defaultSearchParams,
    }));
  };

  const {
    data: counselsReturnData,
    isLoading,
    isPending,
    isError,
    error,
  } = useCounselsQuery({
    ...searchParams,
    // ...paginationModel,
  });

  const rows = useMemo(
    () => counselsReturnData?.data || [],
    [counselsReturnData],
  );

  const { columns } = useCreateGridColumns({
    handlers: { onClickName: handleClickName },
    columns: COUNSELS_GRID_COLUMNS,
  });

  if (isError) throw error;

  const totalElements = counselsReturnData?.pagination?.totalElements || 0;
  const totalDataCount = counselsReturnData?.pagination?.totalDataCount || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Search
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={handleSearchParams}
            defaultValues={defaultSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="연번"
                    name="unionRegisterNo"
                    placeholder='숫자 또는 "-"입력'
                    validateOptions={{
                      maxLength: 11,
                      regex: /^(?!.*--)[0-9-]*$/,
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="조합원 이름 입력"
                    label="조합원 이름"
                    name="unionRegisterName"
                    slotProps={{
                      input: { maxLength: 30 },
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="기간 조회"
                    name="searchFromTo"
                    sx={{ field: { xs: 12, maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
        </Stack>

        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Box>
            <DataGrid
              apiRef={datagridApiRef}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.counselSeq}
              loading={isLoading || isPending}
              checkboxSelection
              disableRowSelectionOnClick
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>

          <Stack alignItems="center">
            <Pagination
              color="neutral"
              variant="plain"
              size="md"
              orientation="horizontal"
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={false}
              hideNextButton={false}
              count={pageTotalCount}
              onChange={(_, page) => {
                page &&
                  setPaginationModel({
                    ...paginationModel,
                    page: page - 1,
                  });
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

const counselsPage: CustomRouteObject = {
  path: '/unions/:unionSeq/counsel-management/counsels',
  children: [
    { path: '', index: true, element: <CounselsPage /> },
    counselDetailPage,
    counselModifyPage,
  ],
  handle: {
    getTitle: () => '상담 내역',
    getMenuCode: () => 'M0902',
  },
};

export default counselsPage;
