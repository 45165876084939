import {
  Button,
  Radio,
  Stack,
  Typography,
  RadioGroup,
  IconButton,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import { MeetStatus } from 'apis/types/meet';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  useMeetsJointBookQuery,
  useRegisterJointBookMutation,
} from 'lim/generalMeetingHistoryDetail/apis';
import { formatTelephone } from 'utils/format';

// import { MeetStatus } from 'lim/type';

const JointBookStatusContent = ({
  meetStatus,
  meetSeq,
}: {
  meetStatus: MeetStatus;
  meetSeq: number;
}) => {
  const { pageContext } = useLayoutContext();
  const memberInfo = pageContext?.memberInfo;
  const { data, refetch } = useMeetsJointBookQuery(meetSeq);
  // const jointBookStatusData = useMeetsJointBookQuery(meetSeq)?.data?.data;
  const jointBookStatusData = data?.data;

  const [isNotificationAllowed, setIsNotificationAllowed] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const { snackbar } = useFeedback();

  const { mutate: registerJointBook } = useRegisterJointBookMutation();
  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const handleApplyJointBook = () => {
    registerJointBook(
      {
        meetSeq: meetSeq,
        isNotificationAllowed,
        memberSeq: memberInfo?.memberSeq || 0,
        phoneNo: memberInfo?.phoneNo || '',
        name: memberInfo?.name || '',
      },
      {
        onSuccess: () => {
          snackbar('연명부 신청이 완료 되었습니다.', {
            color: 'success',
          });
          refetch();
          // useMeetsJointBookQuery(meetSeq).refetch();
        },
        onError: (error) => {
          snackbar(error?.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleChangeApplyJointBook = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setIsNotificationAllowed(value);
  };
  const handleChangeDownloadJointBook = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setIsPrivate(value);
  };

  const handleDownloadJointBook = () => {
    if (!jointBookStatusData) return;
    // downloadJointBook
    const fileSeq = isPrivate
      ? jointBookStatusData?.publicFileSeq
      : jointBookStatusData?.privateFileSeq;

    downloadFile(
      { fileSeq: fileSeq },
      {
        onSuccess: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;

          link.download = '연명부.pdf';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        },
        onError: () => {},
      },
    );
  };

  if (
    meetStatus === 'COMPLETE' ||
    meetStatus === 'COUNTING'
    // 임시로 ...
  ) {
    //
    if (jointBookStatusData && jointBookStatusData?.status === 'BEFORE') {
      return (
        <>
          <Stack mt={2} gap={1}>
            <Typography level="body-md">
              참석자 연명부를 생성하려면 아래 버튼을 눌러주세요.
            </Typography>
            <RadioGroup
              orientation="vertical"
              // orientation="horizontal"
              size="md"
              key="isNotificationAllowed"
              name="isNotificationAllowed"
              onChange={handleChangeApplyJointBook}
              value={isNotificationAllowed}
            >
              <Radio
                label={
                  formatTelephone(memberInfo?.phoneNo || '') +
                  '로 안내 메시지 받기'
                }
                value={true}
              ></Radio>
              <Radio label="안내 메시지 받지 않기" value={false}></Radio>
            </RadioGroup>
            <Button onClick={handleApplyJointBook}>연명부 생성 신청</Button>
          </Stack>
        </>
      );
    }
    if (jointBookStatusData && jointBookStatusData?.status === 'COMPLETED') {
      return (
        <Stack mt={2} gap={1}>
          <Typography level="body-md">
            참석자 연명부 생성이 완료되었습니다.
            <br />
            개인정보 보호 수준을 선택 후, 아래 다운로드 버튼을 눌러주세요.
          </Typography>
          <RadioGroup
            orientation="vertical"
            size="md"
            key="isPrivate"
            name="isPrivate"
            onChange={handleChangeDownloadJointBook}
            value={isPrivate}
          >
            <Radio label="개인정보 모두 표시하기" value={true}></Radio>
            <Radio label="일부 정보만 표시하기" value={false}></Radio>
          </RadioGroup>
          <IconButton
            variant="outlined"
            color="primary"
            onClick={handleDownloadJointBook}
          >
            연명부 다운로드
          </IconButton>
        </Stack>
      );
    }
    if (jointBookStatusData && jointBookStatusData?.status === 'PROCESSING') {
      return (
        <Stack mt={2} gap={1}>
          <Typography level="body-md">
            참석자 연명부를 생성 중입니다. 잠시만 기다려 주세요.
          </Typography>
        </Stack>
      );
    }
    if (jointBookStatusData && jointBookStatusData?.status === 'FAILED') {
      return (
        <Stack mt={2} gap={1}>
          <Typography level="body-md">생성 실패</Typography>
        </Stack>
      );
    }
  }
};

export default JointBookStatusContent;
