import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Callout,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Sheet,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import VoterDeleteModal from 'components/pages/meet-management/history/detail/VoteEdit/VoterDeleteModal';
import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';
import useFeedback from 'hooks/useFeedback';
import { useMeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import {
  ParticipantDetailDeleteMutationBody,
  ParticipantDetailEditBody,
  useMeetsParticipantsDetailDeleteMutation,
  useMeetsParticipantsDetailEditMutation,
  useMeetsParticipantsDetailQuery,
} from 'lim/generalMeetingHistoryDetail/voterEdit/apis';
import { CustomRouteObject } from 'types/route';

const commonStyle = { marginRight: '12px' };

const MeetHistoryDetailEditPage = () => {
  const { meetSeq, meetParticipantSeq } = useParams();
  const { snackbar } = useFeedback();
  const navigate = useNavigate();

  const meetId = Number(meetSeq);
  const meetParticipantId = Number(meetParticipantSeq);

  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formatedCompanyNo, setFormatedCompanyNo] = useState('');

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    content: <></>,
    onConfirm: () => {},
  });

  const { getGroupCode } = useCommonCodes();

  const { data: meetData, isPending: isMeetDataPending } =
    useMeetsDetailQuery(meetId);

  const {
    data,
    refetch,
    isPending: isMeetPartipantsDetailPending,
  } = useMeetsParticipantsDetailQuery(meetId, meetParticipantId);

  const { mutate: editMutate } = useMeetsParticipantsDetailEditMutation(
    meetId,
    meetParticipantId,
  );
  const { mutate: deleteMutate } = useMeetsParticipantsDetailDeleteMutation(
    meetId,
    meetParticipantId,
  );

  const { ownerTypeCodeGroup } = useMemo(
    () => ({
      ownerTypeCodeGroup: getGroupCode('UNION_REGISTER_USER_DIVIDE'),
    }),
    [getGroupCode],
  );

  const [formData, setFormData] = useState<ParticipantDetailEditBody>({
    name: '',
    nameClass: '',
    ownerType: '',
    companyNo: '',
    birth: '',
    gender: '',
    phoneNo: '',
    postAddress: {
      address: '',
      detailAddress: '',
      zipNo: '',
    },
    realAddress: {
      address: '',
      detailAddress: '',
      city: '',
      gu: '',
      dong: '',
    },
  });

  const handleOnChange =
    (type?: 'agent' | 'postAddress' | 'realAddress') =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      let formattedValue = value;
      if (name === 'companyNo') {
        // 숫자만 남기기
        formattedValue = value.replace(/\D/g, '');

        // 입력된 숫자의 길이에 따라 포맷 적용
        if (formattedValue.length <= 10) {
          formattedValue = formattedValue.replace(
            /(\d{3})(\d{2})(\d{0,5})/,
            '$1-$2-$3',
          );
        } else {
          formattedValue = formattedValue.replace(
            /(\d{3})(\d{2})(\d{5})(\d{0,1})/,
            '$1-$2-$3$4',
          );
        }
        setFormatedCompanyNo(formattedValue);
      }

      if (type) {
        return setFormData({
          ...formData,
          [type]: {
            ...formData[type],
            [name]: value,
          },
        });
      }
      setFormData({
        ...formData,
        [name]: value,
      });
      if (!type && name === 'gender' && value === 'NONE') {
        setFormData((prev) => ({
          ...prev,
          birth: '',
        }));
      }
    };

  const handleOnSubmit = () => {
    // const { name, birth, phoneNo, postAddress } = formData;
    const { phoneNo, companyNo, birth, ownerType } = formData;
    // 사업자인 경우 예외 처리
    if (companyNo && ownerType === 'COMPANY') {
      formData.companyNo = companyNo.replace(/-/g, '');
      if (
        formData.companyNo.length !== 10 &&
        formData.companyNo.length !== 13
      ) {
        snackbar('사업자등록번호를 확인해 주세요.', {
          color: 'danger',
        });
        return;
      }
    }
    if (birth) {
      if (birth.length !== 8) {
        snackbar('생년월일을 확인해 주세요.', {
          color: 'danger',
        });
        return;
      }
    }

    if (!phoneNo) {
      // return alert('전화번호를 입력하세요.');
      snackbar('전화번호를 입력하세요.', {
        color: 'danger',
      });
      return;
    }
    if (ownerType === 'NONE') {
      delete formData.gender;
      delete formData.ownerType;
    }

    if (ownerType === 'PERSON') {
      delete formData.companyNo;
      if (gender === 'NONE' || !gender) {
        delete formData.gender;
      }
    }
    if (ownerType === 'COMPANY') {
      delete formData.birth;
      delete formData.gender;
    }

    if (
      formData?.agent &&
      (!formData.agent.name ||
        !formData.agent.birth ||
        !formData.agent.gender ||
        !formData.agent.phoneNo)
    ) {
      snackbar('대리인 정보를 모두 입력해주세요.', {
        color: 'danger',
      });
      return; // 함수 종료
    }

    editMutate(formData, {
      onSuccess: () => {
        navigate('..');
        refetch();
        snackbar('변경사항 저장 되었습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error?.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleDelete = (formData: ParticipantDetailDeleteMutationBody) => {
    deleteMutate(formData, {
      onSuccess: () => {
        refetch();
        snackbar('삭제되었습니다.', {
          color: 'success',
        });
        // toast navigation 이슈로 인한 임시조치
        setTimeout(handleGoList, 1000);
      },
      onError: (error) => {
        snackbar(error?.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { userSelectedType, roadAddress, jibunAddress, zonecode } = result;

    const address = userSelectedType === 'R' ? roadAddress : jibunAddress;
    const zipNo = zonecode;

    setFormData({
      ...formData,
      postAddress: {
        ...formData.postAddress,
        address,
        zipNo,
      },
    });
  };

  const handleChangeRealAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { userSelectedType, roadAddress, jibunAddress } = result;

    const address = userSelectedType === 'R' ? roadAddress : jibunAddress;

    setFormData({
      ...formData,
      realAddress: {
        ...formData.realAddress,
        address,
      },
    });
  };

  useEffect(() => {
    if (isMeetDataPending || isMeetPartipantsDetailPending) return;

    const meetMethod = meetData?.data?.meetMethod;
    const meetStatus = meetData?.data?.meetStatus;
    const progressStatus = data?.data?.electronicVoteStatus?.progressStatus;

    if (meetStatus !== 'PROCESS') {
      snackbar('수정이 불가능한 상태입니다.', {
        color: 'danger',
      });
      navigate('..', {
        state: {
          currentTab: 'VOTER',
        },
      });
      return;
    }

    if (meetMethod === 'ELECTRONIC' || meetMethod === 'ONSITE_ELECTRONIC') {
      if (progressStatus === 'WAIT') {
        snackbar('수정이 불가능한 상태입니다.', {
          color: 'danger',
        });
        navigate('..', {
          state: {
            currentTab: 'VOTER',
          },
        });
        return;
      }
      return;
    }
  }, [data?.data, meetData]);

  useEffect(() => {
    if (data?.data) {
      const {
        name,
        birth,
        gender,
        phoneNo,
        agent,
        postAddress,
        ownerType,
        companyNo,
        realAddress,
      } = data.data;

      const formData = {
        ...name,
        birth,
        gender,
        phoneNo,
        ownerType: ownerType ?? 'NONE',
        companyNo: companyNo,
        postAddress: {
          address: postAddress?.address,
          detailAddress: postAddress?.detailAddress,
          zipNo: postAddress?.zipNo,
        },
        realAddress: {
          address: realAddress?.address,
          detailAddress: realAddress?.detailAddress,
          city: realAddress?.city,
          gu: realAddress?.gu,
          dong: realAddress?.dong,
        },
        agent: agent
          ? {
              ...agent?.name,
              birth: agent.birth,
              phoneNo: agent.phoneNo,
              gender: agent.gender,
            }
          : undefined,
      };

      setFormData(formData);

      companyNo && setFormatedCompanyNo(companyNo);
    }
    console.log('!companyNo', companyNo);
  }, [data?.data]);

  const {
    name,
    nameClass,
    birth,
    gender,
    phoneNo,
    ownerType,
    companyNo,
    postAddress,
    realAddress,
  } = formData;
  const { address, detailAddress, zipNo } = postAddress;
  const { address: addressReal, detailAddress: detailAddressReal } =
    realAddress;

  const handleDeleteAgent = () => {
    setModalInfo({
      show: true,
      title: '대리인 정보를 삭제하시겠습니까?',
      content: <>입력된 내용이 모두 삭제됩니다.</>,
      onConfirm: () => {
        setModalInfo({
          ...modalInfo,
          show: false,
        });
        setShowAgentInfo(false);
        setFormData({
          ...formData,
          agent: undefined,
        });
      },
    });
  };

  const agentJSON = useMemo(() => {
    return generateDescriptionsJSON(
      ['이름', '이름 구분', '생년월일', '성별', '연락처'],
      [
        <Input
          key="name"
          sx={commonStyle}
          name="name"
          value={formData.agent?.name}
          onChange={handleOnChange('agent')}
          slotProps={{
            input: {
              maxLength: 30,
            },
          }}
        />,
        <Input
          key="nameClass"
          name="nameClass"
          value={formData.agent?.nameClass}
          onChange={handleOnChange('agent')}
          slotProps={{
            input: { maxLength: 20 },
          }}
        />,
        <Input
          key="birth"
          sx={commonStyle}
          name="birth"
          value={formData.agent?.birth}
          onChange={handleOnChange('agent')}
          validateOptions={{
            maxLength: 8,
            regex: /^[0-9]*$/,
          }}
        />,
        <RadioGroup
          key="gender"
          name="gender"
          value={formData.agent?.gender}
          size="md"
          orientation="horizontal"
          onChange={handleOnChange('agent')}
        >
          <Radio label="남" value="M" />
          <Radio label="여" value="F" />
        </RadioGroup>,
        <Input
          key="phoneNo"
          sx={commonStyle}
          name="phoneNo"
          value={formData.agent?.phoneNo}
          onChange={handleOnChange('agent')}
          validateOptions={{
            maxLength: 11,
            regex: /^[0-9]*$/,
          }}
        />,
      ],
    );
  }, [formData]);

  const handleGoList = () => {
    navigate('..', {
      state: {
        currentTab: 'VOTER',
      },
    });
  };

  useEffect(() => {
    if (data?.data?.agent?.name) {
      setShowAgentInfo(true);
    }
  }, [data?.data]);

  return (
    <>
      <Stack gap={5}>
        <Stack gap={1.75}>
          <Typography level="title-lg">선거인 정보</Typography>
          <Sheet
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              borderRadius: 6,
              gap: 4,
            }}
          >
            <Stack flexDirection="row" flexWrap="wrap">
              <Stack
                flex={1}
                flexDirection="row"
                flexWrap="wrap"
                alignItems="baseline"
                gap={2}
              >
                <Grid container columnGap={10} rowGap={3}>
                  <Grid maxWidth={1424} width="100%">
                    <RadioGroup
                      key="ownerType"
                      name="ownerType"
                      label="구분"
                      value={ownerType}
                      size="md"
                      orientation="horizontal"
                      onChange={handleOnChange()}
                    >
                      <Radio label="미지정" value="NONE" />
                      {ownerTypeCodeGroup?.items.map(({ code, name }) => (
                        <Radio key={code} label={name} value={code} />
                      ))}
                    </RadioGroup>
                  </Grid>

                  <Grid width={316}>
                    <TextField
                      label="이름/법인명"
                      key="name"
                      placeholder="이름/법인명 입력"
                      validateOptions={{ maxLength: 30 }}
                      name="name"
                      value={name}
                      onChange={handleOnChange()}
                    />
                  </Grid>
                  <Grid width={316}>
                    <TextField
                      label="이름 구분"
                      key="name"
                      placeholder="이름 구분 입력"
                      validateOptions={{ maxLength: 20 }}
                      name="nameClass"
                      value={nameClass}
                      onChange={handleOnChange()}
                    />
                  </Grid>

                  {/* <Grid maxWidth={1424} width="100%"> */}
                  {ownerType === 'PERSON' && (
                    <>
                      <Grid container columnGap={10} rowGap={3}>
                        <Grid width={316}>
                          <RadioGroup
                            key="gender"
                            label="성별"
                            name="gender"
                            value={gender || 'NONE'}
                            size="md"
                            orientation="horizontal"
                            onChange={handleOnChange()}
                            disabled={ownerType !== 'PERSON'}
                          >
                            <Radio label="미지정" value="NONE" />
                            <Radio label="남" value="M" />
                            <Radio label="여" value="F" />
                          </RadioGroup>
                        </Grid>

                        <Grid width={316}>
                          <TextField
                            key="birth"
                            label="생년월일"
                            name="birth"
                            value={birth}
                            onChange={handleOnChange()}
                            disabled={gender === 'NONE' ? true : false}
                            validateOptions={{
                              maxLength: 8,
                              regex: /^(?!.*--)[0-9]*$/,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {ownerType === 'COMPANY' && (
                    <>
                      <Grid maxWidth={316} width="100%">
                        <TextField
                          key="companyNo"
                          label="사업자등록번호"
                          // sx={commonStyle}
                          name="companyNo"
                          value={formatedCompanyNo}
                          onChange={handleOnChange()}
                          validateOptions={{ maxLength: 15 }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            </Stack>
            <Divider />
            <Grid container columnGap={10} rowGap={3}>
              <Grid>
                <Grid container gap={3}>
                  <Grid maxWidth={676} width="100%">
                    <TextField
                      key="phoneNo"
                      label="조합원 연락처"
                      // sx={commonStyle}
                      name="phoneNo"
                      value={phoneNo}
                      onChange={handleOnChange()}
                      validateOptions={{
                        maxLength: 11,

                        regex: /^[0-9]*$/,
                      }}
                      // slotProps={{
                      //   input: {
                      //     // component: PatternFormatAdapter,
                      //     foramt:
                      //       companyNo === undefined ||
                      //       companyNo?.length < 11
                      //         ? '###-##-#####'
                      //         : '###-##-######',
                      //   },
                      // }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid width="100%" gap={3.75}>
                <Grid maxWidth={732} width="100%">
                  <Typography
                    fontSize="sm"
                    fontWeight="md"
                    lineHeight="sm"
                    mb={0.75}
                  >
                    실제 거주지
                  </Typography>
                  <Stack key="address" flexDirection="row" gap={1}>
                    <AddressModalTrigger onComplete={handleChangeRealAddress}>
                      <Input
                        fullWidth
                        readOnly
                        placeholder="주소"
                        startDecorator={<SearchIcon />}
                        name="addressReal"
                        value={addressReal}
                        onChange={handleOnChange('realAddress')}
                      />
                    </AddressModalTrigger>
                    <Input
                      fullWidth
                      key="detailAddress"
                      name="detailAddress"
                      placeholder="상세주소 입력"
                      value={detailAddressReal}
                      onChange={handleOnChange('realAddress')}
                    />
                  </Stack>
                </Grid>

                <Grid maxWidth={732} width="100%">
                  <Typography
                    fontSize="sm"
                    fontWeight="md"
                    lineHeight="sm"
                    mb={0.75}
                    mt={0.75}
                  >
                    우편물 수령지
                  </Typography>
                  <Stack key="address" flexDirection="row" gap={1}>
                    <AddressModalTrigger onComplete={handleChangeAddress}>
                      <Input
                        fullWidth
                        readOnly
                        placeholder="주소"
                        startDecorator={<SearchIcon />}
                        name="address"
                        value={address}
                        onChange={handleOnChange('postAddress')}
                      />
                    </AddressModalTrigger>
                    <Input
                      fullWidth
                      key="detailAddress"
                      name="detailAddress"
                      placeholder="상세주소 입력"
                      value={detailAddress}
                      onChange={handleOnChange('postAddress')}
                    />
                    <Input key="zipNo" name="zipNo" value={zipNo} disabled />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Sheet>
        </Stack>

        <Stack gap={1.75}>
          <Typography level="title-lg">대리인 정보</Typography>
          {showAgentInfo ? (
            <>
              <Callout
                color="primary"
                variant="outlined"
                startDecorator={<InfoIcon />}
              >
                대리인을 등록하려면 추가 버튼을 눌러 이름, 생년월일, 성별,
                연락처를 모두 입력하고 저장해야 합니다.
              </Callout>

              <DescriptionsRenderer columns={2} json={agentJSON} />

              <Button
                color="danger"
                variant="outlined"
                startDecorator={<DeleteOutlineIcon />}
                onClick={handleDeleteAgent}
              >
                대리인 삭제
              </Button>
            </>
          ) : (
            <Button
              color="neutral"
              variant="outlined"
              startDecorator={<AddIcon />}
              onClick={() => setShowAgentInfo(true)}
            >
              대리인 추가
            </Button>
          )}
        </Stack>

        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button
            disabled={meetData?.data?.meetStatus !== 'PROCESS'}
            variant="outlined"
            color="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            선거인 삭제
          </Button>

          <Stack direction="row" alignSelf="end" gap={2}>
            {/* <Button component={Link} variant="outlined" href="..">
              취소
            </Button> */}
            <Button variant="outlined" onClick={handleGoList}>
              취소
            </Button>
            <Button onClick={handleOnSubmit}>저장</Button>
          </Stack>
        </Stack>
      </Stack>

      <VoterDeleteModal
        open={showDeleteModal}
        onClose={setShowDeleteModal}
        onSubmit={handleDelete}
      />

      <Modal
        open={modalInfo.show}
        onClose={() =>
          setModalInfo({
            ...modalInfo,
            show: false,
          })
        }
      >
        <ModalDialog>
          <DialogTitle>{modalInfo.title}</DialogTitle>
          <DialogContent>{modalInfo.content}</DialogContent>
          <DialogActions>
            <Button onClick={modalInfo.onConfirm}>확인</Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() =>
                setModalInfo({
                  ...modalInfo,
                  show: false,
                })
              }
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

const MeetHistoryDetailEdit: CustomRouteObject = {
  path: ':meetParticipantSeq',
  element: <MeetHistoryDetailEditPage />,
  handle: {
    getTitle: () => '총회 내역 상세',
  },
};

export default MeetHistoryDetailEdit;
