import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  Callout,
  DatePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
} from '@wooriga/design-system';
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useMemo,
  useState,
} from 'react';

import {
  RegisterConsentRequest,
  useRegisterConsentMutation,
} from 'apis/agreements/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';

export interface AgreeModalProps {
  open: boolean;
  onClose: () => void;
  selectedRow: GridRowSelectionModel;
  refetch: () => void;
}

const AgreeModal = (
  props: AgreeModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { pageContext } = useLayoutContext();
  const { memberUnionInfo } = pageContext || {};
  const { getGroupCode } = useCommonCodes();
  const codeGroup = useMemo(
    () => getGroupCode('UNION_AGREEMENT_RESPONSE_TYPE'),
    [getGroupCode],
  );
  const { mutate: registerConsentMutate, isPending } =
    useRegisterConsentMutation();
  const { snackbar } = useFeedback();

  const [radioValue, setRadioValue] = useState('');

  const { open, onClose, selectedRow: targetSeq, refetch } = props;

  const numberArray = targetSeq.map((id) => Number(id));

  const [formData, setFormData] = useState<RegisterConsentRequest>({
    unionSeq: memberUnionInfo?.unionMemberSeq || 0,
    unionRegisterSeqs: numberArray,
    consentStatus: '',
  });

  const handleSentAgreement = () => {
    if (!formData?.consentStatus) {
      snackbar('동의 현황을 선택해주세요.', { color: 'danger' });
      return;
    }
    registerConsentMutate(formData, {
      onSuccess: () => {
        refetch && refetch();
        snackbar('등록 되었습니다.', { color: 'success' });
        onClose();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
      },
    });
  };

  const handleOnChange = () => (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      consentStatus: e.target.value,
      unionRegisterSeqs: numberArray,
    });
    setRadioValue(e.target.value);
  };

  const handleConsentAt = (values: string) => {
    // console.log('values', values);
    // // 선택된 날짜를 UTC 시간대로 변환
    // // const utcDate = new Date(
    // //   values.getTime() + values.getTimezoneOffset() * 60000,
    // // );

    // // // UTC 시간에 9시간을 더해 한국 시간으로 변환
    // // const koreanTimeOffset = 9 * 60 * 60000 * 3;
    // // const koreanDate = new Date(utcDate.getTime() + koreanTimeOffset);

    // // const koreanDateString = koreanDate.toISOString();

    setFormData({
      ...formData,
      responseAt: new Date(values).toISOString(),
    });
  };

  return (
    <Modal ref={ref} open={open}>
      <ModalDialog
        sx={{
          width: 600,
        }}
      >
        <DialogTitle>동의현황 저장</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <RadioGroup
              orientation="horizontal"
              label="동의현황"
              size="md"
              key="consentStatus"
              name="consentStatus"
              value={radioValue}
            >
              {codeGroup?.items.map((item) => {
                return (
                  <Radio
                    key={item.code}
                    label={item.name}
                    value={item.code}
                    onChange={handleOnChange()}
                  />
                );
              })}
            </RadioGroup>
            {radioValue === 'NONE' ? null : (
              <DatePicker
                variant="outlined"
                color="neutral"
                size="md"
                label="응답일"
                onChange={(values) => {
                  values && handleConsentAt(String(values));
                }}
              />
            )}
          </Stack>
          <Stack>
            <Callout variant="plain" size="sm">
              미확인은 조합원의 의사를 확인하지 못한 상태이며, 선택하게 되면
              응답일을 입력 할 수 없습니다. 조합원의 동의현황을 확인하고 저장해
              주세요.
            </Callout>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSentAgreement}
            disabled={!formData.unionRegisterSeqs.length}
            loading={isPending}
          >
            확인
          </Button>
          <Button variant="outlined" color="neutral" onClick={onClose}>
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
export default forwardRef(AgreeModal);
