import { yupResolver } from '@hookform/resolvers/yup';
import TuneIcon from '@mui/icons-material/Tune';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  CheckOption,
  DataGrid,
  DataGridProps,
  DateTimePicker,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreateMessageBody } from 'apis/message/apis';
import { MeetMethods, MeetParticipantResponse } from 'apis/types/meet';
import { Caller } from 'apis/types/message';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import MessageForm, {
  MessageFormValues,
} from 'components/pages/messages/MessageForm';
import useFeedback from 'hooks/useFeedback';
import useStepperDialog from 'hooks/useStepperDialog';
import { MeetsParticipantsParams } from 'lim/generalMeetingHistoryDetail/apis';

const schema = yup.object({
  outgoingPhoneNo: yup.string().required('발신번호를 선택해 주세요.'),
  sendType: yup.string().required('발송방식을 선택해 주세요.'),
  scheduledAt: yup.string().when('sendType', {
    is: 'SCHEDULE',
    then: (schema) => schema.required('예약발송일시를 선택해 주세요.'),
  }),
  recipients: yup
    .array()
    .required()
    .min(1, '선택된 수신자가 없습니다. 수신자를 선택해 주세요.'),
  message: yup
    .string()
    .when('files', {
      is: (value: File[]) => value.length === 0,
      then: (schema) =>
        schema.required('발송할 문자 내용을 입력 또는 이미지를 첨부해 주세요.'),
    })
    .default(''),
  files: yup.array(),
  title: yup.string().default(''),
});

type MessageFormSchema = yup.InferType<typeof schema>;

export interface MessageElectorStepProps<
  T,
  V extends MeetMethods = 'ELECTRONIC',
> {
  rows: T[];
  callers: Caller[];
  columns: DataGridProps['columns'];
  isElectronic: boolean;
  onSubmit?: (data: MessageFormSchema) => void;
  onSearch?: (params: MeetsParticipantsParams<V>) => void;
}

const MessageElectorStep = <
  T extends MeetParticipantResponse,
  V extends MeetMethods = 'ELECTRONIC',
>({
  rows,
  callers,
  columns = [],
  isElectronic,
  onSearch,
}: MessageElectorStepProps<T, V>) => {
  const { snackbar } = useFeedback();
  const { values, updateValues, stepNext, PreviousButton } =
    useStepperDialog<MessageFormSchema>();

  const { groupCode, filterName } = useMemo(() => {
    const groupCode = isElectronic
      ? 'MEET_PARTICIPANT_VOTE_STATUS'
      : 'WRITTEN_SUBMIT_TYPE';
    const filterName = isElectronic
      ? 'electronicVoteProgressStatus'
      : 'writtenSubmissionType';

    return { groupCode, filterName };
  }, [isElectronic]);

  const { getGroupCode } = useCommonCodes();
  const { sendTypeCodeGroup, meetParticipantVoteStatusCodeGroup } = useMemo(
    () => ({
      sendTypeCodeGroup: getGroupCode('SMS_SEND_TYPE'),
      meetParticipantVoteStatusCodeGroup: getGroupCode(groupCode),
    }),
    [getGroupCode, groupCode],
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit: validateForm,
  } = useForm<MessageFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      outgoingPhoneNo: callers?.[0]?.outgoingPhoneNo || '',
    },
    values,
  });

  const [filters, setFilters] = useState({
    electronicVoteProgressStatus: [],
    writtenSubmissionType: [],
  });
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(
      values.recipients.map(({ unionRegisterSeq }) => unionRegisterSeq) ?? [],
    );

  const title = watch('title');
  const message = watch('message');
  const files = watch('files') ?? [];
  const isReservation = watch('sendType') === 'SCHEDULE';

  const filterValue = useMemo(
    () =>
      isElectronic
        ? filters.electronicVoteProgressStatus
        : filters.writtenSubmissionType,
    [filters, isElectronic],
  );

  const handleChangeRowSelectionModel = (model: GridRowSelectionModel) => {
    setRowSelectionModel(model);
    setValue(
      'recipients',
      rows
        .filter(({ unionRegisterSeq }) => model.includes(unionRegisterSeq))
        .map(({ unionRegisterSeq, name, phoneNo }) => ({
          unionRegisterSeq,
          name: name?.name,
          phoneNo,
        })),
    );
  };

  const handleChangeFilter =
    (name: string) => (_: unknown, value: string[]) => {
      const newValue = { ...filters, [name]: value };

      setFilters(newValue);
      // electronicVoteProgressStatus가 string[]일 경우에 대한 type 대처가 어려움

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSearch?.(newValue);
    };

  const handleChangeMessageForm = (data: MessageFormValues) => {
    Object.entries(data).map(([key, value]) =>
      setValue(key as FieldPath<CreateMessageBody>, value),
    );
  };

  const handleSubmit = validateForm(
    (data) => {
      updateValues(data);
      stepNext();
    },
    (error) => {
      const message = Object.values(error)[0].message;
      snackbar(message, { color: 'danger' });
    },
  );

  return (
    <Grid
      component="form"
      container
      rowGap={2.5}
      columnGap={5}
      maxWidth={1280}
      onSubmit={handleSubmit}
    >
      <Grid xs={12}>
        <Typography color="neutral">
          선거인에게 서면 독려/참석 독려 등의 문자발송을 하실 수 있습니다.
          <br />
          선거인 정보 수정은 선거인 정보 수정 탭에서 수정하실 수 있습니다.
        </Typography>
      </Grid>

      <Grid xs={8}>
        <Stack flexDirection="column" gap={4}>
          <Stack direction="row" gap={2}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>발신번호 선택</FormLabel>

              <Controller
                control={control}
                name="outgoingPhoneNo"
                render={({ field: { onChange, ...other } }) => (
                  <Select
                    placeholder={
                      callers.length > 0 ? '발신번호 선택' : '발신번호 없음'
                    }
                    {...other}
                    onChange={(_, value) => onChange(value)}
                    disabled={callers.length === 0}
                  >
                    {callers.map(({ smsCallerSeq, outgoingPhoneNo, name }) => (
                      <Option
                        key={`caller_${smsCallerSeq}`}
                        value={outgoingPhoneNo}
                      >
                        {outgoingPhoneNo} {name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>발송 시간 설정</FormLabel>

              <Stack flexDirection="row" gap={2}>
                <Controller
                  control={control}
                  name="sendType"
                  render={({ field }) => (
                    <RadioGroup orientation="horizontal" {...field}>
                      {sendTypeCodeGroup?.items.map(({ code, name }) => (
                        <Radio
                          key={`message_method_${code}_radio`}
                          label={name}
                          value={code}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />

                <Controller
                  control={control}
                  name="scheduledAt"
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      color="neutral"
                      disabled={!isReservation}
                    />
                  )}
                />
              </Stack>
            </FormControl>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <TuneIcon />
              <Typography level="title-sm">필터</Typography>
            </Stack>

            <Select<string, true>
              multiple
              placeholder="전체"
              name={filterName}
              value={filterValue}
              onChange={handleChangeFilter(filterName)}
              startDecorator={
                <Typography level="body-md">제출현황 :</Typography>
              }
            >
              {meetParticipantVoteStatusCodeGroup?.items.map(
                ({ code, name }) => (
                  <CheckOption key={`filter_option_${code}`} value={code}>
                    {name}
                  </CheckOption>
                ),
              )}
            </Select>
          </Stack>

          <Stack height={482}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleChangeRowSelectionModel}
              getRowId={(row) => row.unionRegisterSeq}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid xs={3.5}>
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>문자 내용 작성</FormLabel>

          <MessageForm
            value={{ title, message, files }}
            onChange={handleChangeMessageForm}
          />
        </FormControl>
      </Grid>

      <Grid xs={12}>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <PreviousButton />
          <Button type="submit">다음</Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MessageElectorStep;
