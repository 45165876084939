import { Link } from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';

const FileView = ({
  name = '첨부파일',
  type,
  fileSeq,
  width,
  height,
  download,
  ...other
}: {
  name?: string;
  type: 'img' | 'link';
  fileSeq: number;
  width?: number;
  height?: number;
  download?: string;
}) => {
  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const [url, setUrl] = useState('');

  useEffect(() => {
    downloadFile({
      fileSeq,
    }).then((res) => {
      setUrl(URL.createObjectURL(res));
    });
  }, [fileSeq, downloadFile]);

  if (type === 'img')
    return (
      <img
        src={url}
        style={{ objectFit: 'contain' }}
        alt="file_image"
        width={width}
        height={height}
        {...other}
      />
    );

  return (
    <Link
      href={url}
      download={download}
      type="download"
      target="_blank"
      rel=""
      {...other}
    >
      {name}
    </Link>
  );
};

export default FileView;
