import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { MeetParticipantResponse } from 'apis/types/meet';
import { MessageRegister } from 'apis/types/message';
import { GridColumnsHandlers } from 'types/grid';
import { formatTelephone } from 'utils/format';

export interface MessageRegisterColumnProps {
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}

export const MESSAGE_REGISTER_COLUMNS = ({
  onClickUnionMember,
}: GridColumnsHandlers<MessageRegisterColumnProps>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 140,
      valueGetter: (value: MessageRegister['name']) =>
        `${value?.name ?? ''}${value?.nameClass ?? ''}`,
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'positionDescription',
      headerName: '직책',
      width: 100,
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 110,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'phoneMemo',
      headerName: '비고',
      flex: 1,
    },
  ] as GridColDef<MessageRegister>[];

export const MESSAGE_ELECTOR_COLUMNS = ({
  isElectronic,
  onClickUnionMember,
  getCommonCode,
}: GridColumnsHandlers<{
  isElectronic: boolean;
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      valueGetter: (_, row) =>
        `${row.name?.name ?? ''}${row.name?.nameClass ?? ''}`,
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'positionDescription',
      headerName: '직책',
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 110,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'status',
      headerName: '제출현황',
      valueGetter: (_, row) =>
        getCommonCode(
          isElectronic ? 'MEET_PARTICIPANT_VOTE_STATUS' : 'WRITTEN_SUBMIT_TYPE',
          isElectronic
            ? row.electronicVoteStatus.progressStatus
            : row.onsiteVoteStatus.writtenSubmissionType,
        )?.name,
    },
  ] as GridColDef<MeetParticipantResponse & { status: string }>[];

export const MESSAGE_AGREEMENT_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 90,
      valueGetter: (_, row) =>
        row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음',
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 110,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'consentStatus',
      headerName: '동의현황',
      width: 78,
      valueGetter: (value) =>
        getCommonCode('UNION_AGREEMENT_RESPONSE_TYPE', value)?.name,
    },
    // 확인 필요
    {
      field: 'phoneMemo',
      headerName: '메모',
      width: 200,
    },
  ] as GridColDef<MessageRegister & { status: string }>[];
