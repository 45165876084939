import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  AlertDialog,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  DataGrid,
  Modal,
  ModalProps,
  Pagination,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState, useCallback } from 'react';

import UnionInfoCard from 'components/pages/unionInfo/UnionInfoCard';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  CreateUnionInfo,
  RegisterManagerRequest,
  useCreateUnionInfoMutation,
  useDeleteUnionManagerMutation,
  // useModifyUnionInfoMutation,
  useOfficeInfoQuery,
  useRegisterManagerMutation,
  useUnionManagerListQuery,
} from 'kwon/union-info/apis';
import AddUnionInfoModal from 'pages/main/union-management/componenets/addUnionInfoModal';
import AddUnionManagerModal from 'pages/main/union-management/componenets/addUnionManagerModal';
import ModifyUnionInfoModal from 'pages/main/union-management/componenets/modifyUnionInfoModal';
import { CustomRouteObject } from 'types/route';

const UNION_INFO_MANAGER_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: '조합 관리자 명',
    width: 100,
    editable: false,
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 420,
    editable: false,
  },
  { field: 'email', headerName: '이메일', editable: false },
];

export interface UnionInfoSearchParams {
  officeName: string;
  officeAddress: string;
}

const UnionInfoPage = () => {
  const { snackbar } = useFeedback();
  const { pageContext } = useLayoutContext();
  const memberUnionInfo = pageContext?.memberUnionInfo;

  const [alertContent, setAlertContent] = useState({
    open: false,
    messageId: '',
    type: '',
    message: '',
    detail: '',
  });

  const [selectedManagerSeq, setSelectedManagerSeq] = useState<number>(0);
  const [defaultRowSelectionModel, setDefaultRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const handleChangeDefaultSelection = useCallback(
    (rows: GridRowSelectionModel) => {
      if (rows?.length > 1) {
        return;
      } else {
        setDefaultRowSelectionModel(rows);
      }
    },
    [],
  );

  const {
    data: MANAGER_LIST_DATA,
    isLoading,
    isError,
    error,
    refetch,
  } = useUnionManagerListQuery({
    unionSeq: memberUnionInfo?.unionSeq || 0,
  });

  const { data: OFFICE_LIST_DATA, refetch: refetchOfficInfo } =
    useOfficeInfoQuery({
      unionSeq: memberUnionInfo?.unionSeq || 0,
    });

  const office_info = useMemo(
    () => OFFICE_LIST_DATA?.data || [],
    [OFFICE_LIST_DATA],
  );

  const rows = useMemo(
    () => MANAGER_LIST_DATA?.data || [],
    [MANAGER_LIST_DATA],
  );

  if (isError) throw error;

  const [openAddUnionInfoModal, setOpenAddUnionInfoModal] =
    useState<boolean>(false);
  const [openAddUnionManagerModal, setOpenAddUnionManagerModal] =
    useState<boolean>(false);
  const [openModifyUnionInfoModal, setOpenModifyUnionInfoModal] =
    useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleClickAdd = () => {
    setOpenAddUnionInfoModal(true);
  };
  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setAlertOpen(false);
  };

  const handleClickModify = () => {
    setOpenModifyUnionInfoModal(true);
  };
  const { mutate: registerManger } = useRegisterManagerMutation();
  const handleRegisterManager = (data: RegisterManagerRequest) => {
    registerManger(data, {
      onSuccess: () => {
        snackbar('등록 되었습니다.', {
          color: 'success',
        });
        refetch();
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
    setOpenAddUnionManagerModal(false);
  };

  const handleClickRemove = () => {
    const selectedRowsSeq = defaultRowSelectionModel
      .map((id) => {
        const selectedRow = rows.find((row) => row.unionManagerSeq === id);
        return selectedRow?.unionManagerSeq;
      })
      .filter((seq) => seq !== undefined);
    if (selectedRowsSeq.length === 0) {
      snackbar('삭제할 조합 관리자를 선택해주세요.', {
        color: 'success',
      });
      return;
    }
    setAlertContent({
      messageId: '1',
      open: true,
      type: 'REMOVE',
      message: '조합 관리자 삭제',
      detail: '조합 관리자를 삭제 하시겠습니까?',
    });
    setAlertOpen(true);
    setSelectedManagerSeq(Number(selectedRowsSeq));
  };

  const handleAlertAction = (type: string) => {
    setAlertOpen(false);
    setAlertContent({
      open: false,
      messageId: '',
      type: '',
      message: '',
      detail: '',
    });
    if (type === 'OK') {
      deleteUnionManager(
        {
          unionSeq: memberUnionInfo?.unionSeq || 0,
          unionManagerSeq: selectedManagerSeq,
        },
        {
          onSuccess: () => {
            snackbar('삭제되었습니다.', { color: 'success' });
            refetch();
          },
          onError: (error) => {
            snackbar(error.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    }
    // setAlertOpen(false);
  };

  const handleClickAddUnionManager = () => {
    setOpenAddUnionManagerModal(true);
  };

  const { mutate: createUnionInfo } = useCreateUnionInfoMutation();

  const { mutate: deleteUnionManager } = useDeleteUnionManagerMutation();

  const addUnionInfo = (data: CreateUnionInfo) => {
    if (!data) return;
    createUnionInfo(data, {
      onSuccess: () => {
        // 등록 후, 갱신
        snackbar('등록 되었습니다.', { color: 'success' });
        refetchOfficInfo();
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          조합 사무실 정보
        </Typography>
        <Card>
          <Stack display="flex" flexDirection="row">
            <CardContent>
              <UnionInfoCard
                json={office_info || {}}
                keysToRender={[
                  'address',
                  'name',
                  'zipNo',
                  'email',
                  'faxNo',
                  'phoneNo',
                ]}
              />
            </CardContent>
            <Stack>
              <CardActions>
                <Box justifyContent="right" display="flex">
                  {office_info && Object.keys(office_info).length ? (
                    <Button
                      color="neutral"
                      size="md"
                      variant="outlined"
                      onClick={handleClickModify}
                    >
                      수정
                    </Button>
                  ) : (
                    <Button
                      color="neutral"
                      size="md"
                      variant="outlined"
                      onClick={handleClickAdd}
                    >
                      등록
                    </Button>
                  )}
                </Box>
              </CardActions>
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <Stack gap={2}>
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          조합 관리자 목록
        </Typography>
        <Stack
          sx={{
            width: '100%',
            height: '520px',
          }}
          gap={2}
        >
          <DataGrid
            rows={rows}
            columns={UNION_INFO_MANAGER_TABLE_COLUMNS}
            getRowId={(row) => row.unionManagerSeq}
            loading={isLoading}
            checkboxSelection
            onRowSelectionModelChange={handleChangeDefaultSelection}
            disableMultipleRowSelection={true}
          />
          <Stack alignItems="center">
            <Pagination
              size="md"
              orientation="horizontal"
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={false}
              hideNextButton={false}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
        <Button
          type="reset"
          variant="outlined"
          size="lg"
          color="danger"
          onClick={handleClickRemove}
        >
          삭제
        </Button>
        <Button type="submit" size="lg" onClick={handleClickAddUnionManager}>
          조합 관리자 등록
        </Button>
      </Stack>
      <AddUnionInfoModal
        open={openAddUnionInfoModal}
        onClose={() => setOpenAddUnionInfoModal(false)}
        onSubmit={addUnionInfo}
        unionSeq={memberUnionInfo?.unionSeq || 0}
      />
      <AddUnionManagerModal
        open={openAddUnionManagerModal}
        unionSeq={memberUnionInfo?.unionSeq || 0}
        onClose={() => {
          setOpenAddUnionManagerModal(false);
        }}
        onSubmit={handleRegisterManager}
      />
      <ModifyUnionInfoModal
        open={openModifyUnionInfoModal}
        unionSeq={memberUnionInfo?.unionSeq || 0}
        onClose={() => setOpenModifyUnionInfoModal(false)}
        refetch={refetchOfficInfo}
      />
      <Modal open={alertOpen} onClose={handleClose}>
        <AlertDialog
          type="OKCancel"
          message={alertContent.message}
          detail={alertContent.detail}
          onSubmit={handleAlertAction}
        />
      </Modal>
    </Stack>
  );
};

const unionInfoPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/union-infos',
  element: <UnionInfoPage />,
  handle: {
    getTitle: () => '조합 정보 관리',
    getMenuCode: () => 'M1102',
  },
};

export default unionInfoPage;
