import { QueryClient } from '@tanstack/react-query';

import ContainerLayout from 'layouts/ContainerLayout';
import loginPage from 'pages/public/login';
import ErrorBoundary from 'routes/ErrorBoundary';
import { CustomRouteObject } from 'types/route';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loader = (queryClient: QueryClient) => async () => {
  return true;
};

const children: CustomRouteObject[] = [loginPage];

const publicRouter = {
  element: <ContainerLayout />,
  errorElement: <ErrorBoundary />,
  loader,
  children,
};

export default publicRouter;
