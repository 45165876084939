import { styled } from '@mui/joy';
import debounce from '@mui/material/utils/debounce';
import { isDeepEqual } from '@mui/x-data-grid/utils/utils';
import {
  gridQuickFilterValuesSelector,
  GridToolbarQuickFilterProps,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Input } from 'design-system/components/inputs';

const defaultSearchValueParser = (searchText: string) =>
  searchText.split(' ').filter((word) => word !== '');

const defaultSearchValueFormatter = (values: string[]) => values.join(' ');

const JoyToolbarQuickFilter = (
  props: GridToolbarQuickFilterProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const quickFilterValues = useGridSelector(
    apiRef,
    gridQuickFilterValuesSelector,
  );

  const {
    quickFilterParser = defaultSearchValueParser,
    quickFilterFormatter = defaultSearchValueFormatter,
    debounceMs = rootProps.filterDebounceMs,
  } = props;

  const [searchValue, setSearchValue] = useState(() =>
    quickFilterFormatter(quickFilterValues ?? []),
  );

  const prevQuickFilterValuesRef = useRef(quickFilterValues);

  useEffect(() => {
    if (!isDeepEqual(prevQuickFilterValuesRef.current, quickFilterValues)) {
      // The model of quick filter value has been updated
      prevQuickFilterValuesRef.current = quickFilterValues;

      // Update the input value if needed to match the new model
      setSearchValue((prevSearchValue) =>
        isDeepEqual(quickFilterParser(prevSearchValue), quickFilterValues)
          ? prevSearchValue
          : quickFilterFormatter(quickFilterValues ?? []),
      );
    }
  }, [quickFilterValues, quickFilterFormatter, quickFilterParser]);

  const updateSearchValue = useCallback(
    (newSearchValue: string) => {
      const newQuickFilterValues = quickFilterParser(newSearchValue);
      prevQuickFilterValuesRef.current = newQuickFilterValues;
      apiRef.current.setQuickFilterValues(newQuickFilterValues);
    },
    [apiRef, quickFilterParser],
  );

  const debouncedUpdateSearchValue = useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue, debounceMs],
  );

  const handleSearchValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = event.target.value;
      setSearchValue(newSearchValue);
      debouncedUpdateSearchValue(newSearchValue);
    },
    [debouncedUpdateSearchValue],
  );

  const handleSearchReset = useCallback(() => {
    setSearchValue('');
    updateSearchValue('');
  }, [updateSearchValue]);

  return (
    <JoyToolbarQuickFilterRoot
      variant="plain"
      value={searchValue}
      onChange={handleSearchValueChange}
      placeholder={apiRef.current.getLocaleText(
        'toolbarQuickFilterPlaceholder',
      )}
      aria-label={apiRef.current.getLocaleText('toolbarQuickFilterLabel')}
      type="search"
      startDecorator={<rootProps.slots.quickFilterIcon fontSize="small" />}
      endDecorator={
        <rootProps.slots.baseIconButton
          aria-label={apiRef.current.getLocaleText(
            'toolbarQuickFilterDeleteIconLabel',
          )}
          size="small"
          sx={[
            searchValue
              ? {
                  visibility: 'visible',
                }
              : {
                  visibility: 'hidden',
                },
          ]}
          onClick={handleSearchReset}
          {...rootProps.slotProps?.baseIconButton}
        >
          <rootProps.slots.quickFilterClearIcon fontSize="small" />
        </rootProps.slots.baseIconButton>
      }
      ref={ref}
    />
  );
};

const JoyToolbarQuickFilterRoot = styled(Input, {
  name: 'JoyDataGrid',
  slot: 'ToolbarQuickFilter',
  overridesResolver: (styles) => styles.toolbarQuickFilter,
})({
  width: 'auto',
  [`& input[type="search"]::-webkit-search-decoration,
  & input[type="search"]::-webkit-search-cancel-button,
  & input[type="search"]::-webkit-search-results-button,
  & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: 'none',
  },
});

JoyToolbarQuickFilter.displayName = 'JoyToolbarQuickFilter';

export default forwardRef<HTMLDivElement, GridToolbarQuickFilterProps>(
  JoyToolbarQuickFilter,
);
