import {
  Button,
  DialogActions,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Sheet,
  Skeleton,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo } from 'react';

import { RegistrationReceiptInfo } from 'apis/types/union';
import {
  RegistrationChangesQueryParams,
  useRegistrationChangesConfirmMutation,
  useRegistrationChangesQuery,
} from 'apis/union/register/registration-chages/apis';
import { formatDate, parseDate } from 'utils/format';

export type RegistrationChangesModalParams = RegistrationChangesQueryParams;

export interface RegistrationChangesModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit' | 'onClose'> {
  params: RegistrationChangesQueryParams;
  onClose: () => void;
}

const RegistrationChangesModal = (props: RegistrationChangesModalProps) => {
  const { params, onClose, ...other } = props;

  const {
    data: registrationChangesReturnData,
    isLoading,
    isPending,
  } = useRegistrationChangesQuery(params);

  const { mutate: confirmMutate, isPending: isPendingConfirmMutate } =
    useRegistrationChangesConfirmMutation();

  const { autoChanges, manualChanges } = useMemo(
    () => registrationChangesReturnData?.data || {},
    [registrationChangesReturnData?.data],
  );
  manualChanges;
  const renderChanges = useCallback(
    (
      changes: {
        changeHistorySeq: number;
        registrationReceipt: RegistrationReceiptInfo;
      }[],
    ) =>
      changes.map((change) => {
        const { changeHistorySeq, registrationReceipt } = change;
        const { registrationPurpose, receivedAt, receiptStep } =
          registrationReceipt;
        return (
          <Stack flexDirection="row" gap={2} key={changeHistorySeq}>
            <Typography fontSize="sm" fontWeight="lg" lineHeight="lg">
              {registrationPurpose}
            </Typography>
            <Typography
              fontSize="sm"
              fontWeight="md"
              lineHeight="lg"
              textColor="neutral.500"
            >
              {formatDate(parseDate(receivedAt, 'yyyyMMdd'))}
            </Typography>
            <Typography fontSize="sm" fontWeight="lg" lineHeight="lg">
              {receiptStep}
            </Typography>
          </Stack>
        );
      }),
    [],
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSumbit = useCallback(() => {
    confirmMutate(params, {
      onSuccess: handleClose,
    });
  }, [confirmMutate, handleClose, params]);

  return (
    <Modal {...other} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog minWidth="sm">
          <DialogTitle>부동산 등기 변동 알림 내역</DialogTitle>

          <Stack gap={3}>
            <Skeleton
              loading={isLoading || isPending}
              variant="rectangular"
              height={120}
            >
              {manualChanges && manualChanges?.length > 0 && (
                <Stack>
                  <Typography fontSize="sm" fontWeight="md" lineHeight="lg">
                    변동 내역
                  </Typography>

                  <Sheet
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      borderRadius: 'md',
                      p: 2,
                    }}
                  >
                    {renderChanges(manualChanges)}
                  </Sheet>
                </Stack>
              )}

              {autoChanges && autoChanges.length > 0 && (
                <Stack>
                  <Typography fontSize="sm" fontWeight="md" lineHeight="lg">
                    자동 갱신된 내역
                  </Typography>
                  <Sheet
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      borderRadius: 'md',
                      p: 2,
                    }}
                  >
                    {renderChanges(autoChanges)}
                  </Sheet>
                </Stack>
              )}
            </Skeleton>
          </Stack>

          <DialogActions>
            <Button onClick={handleSumbit} loading={isPendingConfirmMutate}>
              열람 처리
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={handleClose}
              loading={isPendingConfirmMutate}
            >
              닫기
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default RegistrationChangesModal;
