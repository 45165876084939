import {
  // DefinedUseQueryResult,
  useQuery,
  useMutation,
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
// import { generateInitRealData } from 'lim/_api';
import { MeetMethods } from 'apis/types/meet';
import { PromotionUserResponse } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

// import {
//   promotionUserConnectionHistoriesData,
//   // promotionUserExecutionHistoriesData,
// } from './json';

export interface PromotionMeet {
  no: number;
  meetSeq: number;
  name: string;
  openType: string;
  meetType: string;
  meetMethod: MeetMethods;
  meetStatus: string;
  meetAt: string;
  agendaCount: number;
  participantCount: number;
  // moveName: string;
  createdAt: string;
  onsiteVote: {
    onsiteVoteSeq: number;
    meetAt: string;
    submissionStartAt: string;
    submissionEndAt: string;
    jibunAddress: string;
    roadAddress: string;
    detailAddress: string;
    attendanceRate: number;
    contactNo: string;
  };
  electronicVote: {
    electronicVoteSeq: number;
    senderName: string;
    contactNo: string;
    description: string;
    documentNo: string;
    voteStartAt: string;
    voteEndAt: string;
  };
}

export interface PromotionMeetData {
  meet: PromotionMeet;
  promotionHeadCount: number;
  promotionManagerCount: number;
}

export type PromotionMeetsQuery = UseQueryResult<
  ApiResponseData<PromotionMeetData[]>,
  AxiosError
>;

export interface PromotionMeetsParams {
  meetName: string;
  meetMethod: MeetMethods | '';
  meetType: string;
  searchFrom: string;
  searchTo: string;
}

export const usePromotionMeetsQuery = (
  unionSeq: number,
  params: PromotionMeetsParams,
): PromotionMeetsQuery =>
  useQuery({
    queryKey: [`promotion-meets?unionSeq=${unionSeq}`, params],
    // initialData: generateInitRealData(promotionMeetsData),
    placeholderData: keepPreviousData,
  });

export type MeetsPromotionUsersQuery = UseQueryResult<
  ApiResponseData<PromotionUserResponse[]>,
  AxiosError
>;

export interface MeetsPromotionUsersParams {
  name: string;
  id: string;
  phoneNo: string;
  userStatus: string;
  searchFrom: string;
  searchTo: string;
}

export const useMeetsPromotionUsersQuery = (
  meetSeq: number,
  position: string,
  params?: MeetsPromotionUsersParams,
): MeetsPromotionUsersQuery =>
  useQuery({
    queryKey: [`meets/${meetSeq}/promotion-users?position=${position}`, params],
    // initialData: generateInitRealData(promotionUsersData),
    placeholderData: keepPreviousData,
  });

export interface PromotionUserUpdateBody {
  userStatus: string;
  memo: string;
}

export type MeetsPromotionUserUpdateMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PromotionUserUpdateBody
>;

export const useMeetsPromotionUserUpdateMutation = (
  meetSeq: number,
  promotionUserSeq: number,
): MeetsPromotionUserUpdateMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/meets/${meetSeq}/promotion-users/${promotionUserSeq}`,
        formData,
      );

      return data;
    },
  });

export interface PromotionUsersAccountSendBody {
  promotionUserSeqs: number[];
}

export type MeetsPromotionUsersAccountSendMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PromotionUsersAccountSendBody
>;

export const useMeetsPromotionUsersAccountSendMutation = (
  meetSeq: number,
): MeetsPromotionUsersAccountSendMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/promotion-users/account/send`,
        formData,
      );

      return data;
    },
  });

export interface PromotionUserBody {
  name: string;
  phoneNo: string;
  memo: string;
}

export type MeetsPromotionUsersMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PromotionUserBody
>;

export const useMeetsPromotionUsersMutation = (
  meetSeq: number,
): MeetsPromotionUsersMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/promotion-users`,
        formData,
      );

      return data;
    },
  });

export interface PromotionUserConnectionHistory {
  promotionUserConnectionHistorySeq: number;
  promotionUser: PromotionUserResponse;
  actionType: string;
  actionedAt: string;
}

export type PromotionUserConnectionHistoriesQuery = UseQueryResult<
  ApiResponseData<PromotionUserConnectionHistory[]>,
  AxiosError
>;

export interface PromotionUserConnectionHistoriesProps {
  name: string;
  id: string;
  searchFrom: string;
  searchTo: string;
}

export const usePromotionUserConnectionHistoriesQuery = (
  meetSeq: number,
  params: PromotionUserConnectionHistoriesProps,
): PromotionUserConnectionHistoriesQuery =>
  useQuery({
    queryKey: [
      `/meets/${meetSeq}/promotion-users/connection-histories`,
      params,
    ],
    placeholderData: keepPreviousData,

    // initialData: generateInitRealData(promotionUserConnectionHistoriesData),
  });

export interface PromotionUserExecutionHistoriesParams {
  name: string;
  keyword: string;
  searchFrom: string;
  searchTo: string;
}

export interface PromotionUserExecutionHistory {
  promotionUserConnectionHistorySeq: number;
  promotionUser: PromotionUserResponse;
  menu: string;
  message: string;
  actionedAt: string;
}

export type PromotionUserExecutionHistoriesQuery = UseQueryResult<
  ApiResponseData<PromotionUserExecutionHistory[]>,
  AxiosError
>;

export const usePromotionUserExecutionHistoriesQuery = (
  meetSeq: number,
  params: PromotionUserExecutionHistoriesParams,
): PromotionUserExecutionHistoriesQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/promotion-users/execution-histories`, params],
    placeholderData: keepPreviousData,

    // initialData: generateInitRealData(promotionUserExecutionHistoriesData),
  });
