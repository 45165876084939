/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  Select,
  Option,
  FormControl,
  FormLabel,
  useGridUtils,
  Grid,
  DateRangePicker,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MeetMethods } from 'apis/types/meet';
import PasswordConfirmModal from 'components/pages/common/PasswordConfirmModal';
import PromotionInfoModal from 'components/pages/meet-management/promotion/modals/PromotionInfoModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import {
  PromotionMeetsParams,
  usePromotionMeetsQuery,
} from 'lim/meetPromotion/apis';
import { PROMOTION_TABLE_COLUMNS } from 'lim/meetPromotion/fixtures';
import promotionDetail from 'pages/main/meet-management/promotion/detail';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultParams: PromotionMeetsParams = {
  meetName: '',
  meetMethod: '',
  meetType: '',
  searchFrom: '',
  searchTo: '',
};

const PromotionPage = () => {
  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: `promotion-list`,
    activeSaveSnapshot: true,
  });

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [showPromotionInfoModal, setShowPromotionInfoModal] = useState(false);

  const [selectedId, setSelectedId] = useState(0);
  const [promotionType, setPromotionType] = useState('HEAD');

  const [params, setParams] = useState(defaultParams);
  const [selectParams, setSelectParams] = useState<
    Pick<PromotionMeetsParams, 'meetMethod' | 'meetType'>
  >({
    meetMethod: '',
    meetType: '',
  });

  const { data, isLoading, isError, error } = usePromotionMeetsQuery(
    unionId,
    params,
  );

  const handleConfirmModal = (id: number | string) => {
    setIsShowConfirmModal(true);
    setSelectedId(Number(id));
  };

  const handleOnCountClick = (id: number | string, type: string) => {
    setPromotionType(type);
    setSelectedId(Number(id));

    setShowPromotionInfoModal(true);
  };

  const handleOnSelect = (
    key: 'meetMethod' | 'meetType',
    value: MeetMethods | string,
  ) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  const handleOnSearch = (params: PromotionMeetsParams) => {
    let newValue = {} as PromotionMeetsParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams({
      ...newValue,
      ...selectParams,
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          meetSeq,
          name,
          onsiteVote,
          meetType,
          no,
          meetMethod,

          participantCount,

          meetStatus,
        } = row.meet;

        return {
          id: meetSeq,
          no,
          name,
          meetAt: onsiteVote?.meetAt,
          meetType,

          meetMethod,

          participantCount,

          meetStatus,
          promotionHeadCount: row.promotionHeadCount,
          promotionManagerCount: row.promotionManagerCount,
        };
      }) || []
    );
  }, [data?.data]);
  const { columns } = useCreateGridColumns({
    columns: PROMOTION_TABLE_COLUMNS,
    handlers: {
      onNameClick: handleConfirmModal,
      onCountClick: handleOnCountClick,
    },
  });

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          //@ts-ignore
          onSubmit={handleOnSearch}
          //@ts-ignore
          defaultValues={defaultParams}
          onReset={() => {
            setParams(defaultParams);
            setSelectParams({
              meetMethod: '',
              meetType: '',
            });
          }}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <TextField
                  placeholder="총회명을 입력하세요"
                  label="총회명"
                  name="meetName"
                  fullWidth
                />
              </Search.Field>
            </Grid>

            <Search.Field>
              <DateRangePicker
                label="기간조회"
                name="search"
                sx={{ field: { xs: 12, maxWidth: 200 } }}
              />
            </Search.Field>

            <Grid container xs={12} gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>총회 방식</FormLabel>
                    <Select
                      sx={{ width: '100%' }}
                      placeholder="총회 방식을 선택하세요."
                      name="meetMethod"
                      value={selectParams.meetMethod}
                      onChange={(_, value) =>
                        handleOnSelect(
                          'meetMethod',
                          value as unknown as MeetMethods,
                        )
                      }
                    >
                      <Option value="">전체</Option>
                      <Option value="ELECTRONIC">전자투표</Option>
                      <Option value="ONSITE">현장 투표</Option>
                      <Option value="ONSITE_ELECTRONIC">전자‧현장 투표</Option>
                    </Select>
                  </FormControl>
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>총회종류</FormLabel>
                    <Select
                      sx={{ width: '100%' }}
                      placeholder="총회 종류를 선택하세요"
                      name="meetType"
                      value={selectParams.meetType}
                      onChange={(_, value) =>
                        handleOnSelect('meetType', value as unknown as string)
                      }
                    >
                      <Option value="">전체</Option>
                      <Option value="REGULAR">정기 총회</Option>
                      <Option value="SPECIAL">임시 총회</Option>
                    </Select>
                  </FormControl>
                </Search.Field>
              </Grid>
            </Grid>
          </Grid>
        </Search>
      </Stack>

      <Stack flexDirection="row" gap={1}>
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          전체{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalDataCount || 0)}
          </Typography>
        </Typography>

        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          조회 목록{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalElements || 0)}
          </Typography>
        </Typography>
      </Stack>

      <Stack height={442}>
        <DataGrid
          apiRef={datagridApiRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </Stack>
      <Stack direction="row" gap={1} alignSelf="end">
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            exportExcel();
          }}
        >
          내역 다운
        </Button>
      </Stack>

      <PasswordConfirmModal
        meetSeq={selectedId}
        open={isShowConfirmModal}
        onClose={setIsShowConfirmModal}
      />

      {showPromotionInfoModal && (
        <PromotionInfoModal
          meetSeq={selectedId}
          promotionType={promotionType}
          open={showPromotionInfoModal}
          onClose={setShowPromotionInfoModal}
        />
      )}
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <PromotionPage />,
  handle: {
    getTitle: () => '총회 내역관리',
  },
};

const promotion = {
  path: '/unions/:unionSeq/meet-management/promotion',
  children: [route, promotionDetail],
  handle: {
    getTitle: () => '홍보 관리',
    getMenuCode: () => 'M1006',
  },
};

export default promotion;
