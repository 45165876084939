import { Link } from '@wooriga/design-system';

import CommonCode from 'components/CommonCode';
import { OnLinkClick, TypeGuardCol } from 'lim/_fixtures/type';
import { GridColumnsHandlers } from 'types/grid';
import {
  commaizeNumber,
  formatDate,
  formatDateTime,
  formatTelephone,
} from 'utils/format';

export type PromotionTableProps = {
  no: number;
  name: string;
  meetAt: string;
  openType: string;
  meetMethod: string;
  participantCount: number;

  promotionHeadCount: number;
  promotionManagerCount: number;
  meetStatus: string;
};

export interface PromotionTableColumnsProps {
  onNameClick: OnLinkClick;
  onCountClick: (id: number | string, type: string) => void;
}

export const PROMOTION_TABLE_COLUMNS = ({
  onNameClick,
  onCountClick,
  getCommonCode,
}: GridColumnsHandlers<PromotionTableColumnsProps>) =>
  [
    // { field: 'no', headerName: 'No' },
    {
      field: 'name',
      headerName: '총회명',

      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.id)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'meetAt',
      headerName: '총회일자',
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'meetType',
      headerName: '총회종류',
      valueFormatter: (value) => getCommonCode('MEET_TYPE', value)?.name,
    },
    {
      field: 'meetMethod',
      headerName: '총회방식',
      valueFormatter: (value) => getCommonCode('MEET_METHOD', value)?.name,
    },
    {
      field: 'participantCount',
      headerName: '총 선거인',
      valueFormatter: (value) => commaizeNumber(value),
    },
    {
      field: 'promotionHeadCount',
      headerName: '홍보 팀장',
      valueFormatter: (value) => commaizeNumber(value),
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onCountClick(params.id, 'HEAD')}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'promotionManagerCount',
      headerName: '홍보담당자',
      valueFormatter: (value) => commaizeNumber(value),
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onCountClick(params.id, 'PROM')}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'meetStatus',
      headerName: '상태',
      valueFormatter: (value) => getCommonCode('MEET_STATUS', value)?.name,
    },
  ] as TypeGuardCol<PromotionTableProps>[];

export type PromotionInfoHeadTableProps = {
  userStatus: string;
  name: string;
  accountID: string;
  phoneNo: string;
  memo: string;
};

export const PROMOTION_INFO_HEAD_TABLE_COLUMNS: TypeGuardCol<PromotionInfoHeadTableProps>[] =
  [
    { field: 'name', headerName: '이름' },
    {
      field: 'userStatus',
      headerName: '계정상태',
      renderCell: (params) => (
        <CommonCode groupCode="PROMOTION_USER_STATUS" code={params.value} />
      ),
    },
    { field: 'accountID', headerName: '아이디' },
    {
      field: 'phoneNo',
      headerName: '연락처',
      valueGetter: (_, value) => formatTelephone(value.phoneNo),
    },
    { field: 'memo', headerName: '팀장 메모' },
  ];

export type PromotionInfoManagerTableProps = PromotionInfoHeadTableProps & {
  position: string;
  assignedParticipantCount: number;
};

export const PROMOTION_INFO_MANAGER_TABLE_COLUMNS: TypeGuardCol<PromotionInfoManagerTableProps>[] =
  [
    { field: 'name', headerName: '이름' },
    {
      field: 'userStatus',
      headerName: '계정상태',
      renderCell: (params) => (
        <CommonCode groupCode="PROMOTION_USER_STATUS" code={params.value} />
      ),
    },
    { field: 'accountID', headerName: '아이디' },
    { field: 'position', headerName: '직무' },

    {
      field: 'phoneNo',
      headerName: '연락처',
      valueGetter: (_, value) => formatTelephone(value.phoneNo),
    },
    {
      field: 'assignedParticipantCount',
      headerName: '담당 선거인 수',
    },

    { field: 'memo', headerName: '담당자 메모' },
  ];

export type PromotionDetailHeadTableProps = PromotionInfoHeadTableProps & {
  createdAt: string;
};

export interface PromotionDetailHeadTableColumnsProps {
  onNameClick: OnLinkClick;
}

export const PROMOTION_DETAIL_HEAD_TABLE_COLUMNS = ({
  onNameClick,
  getCommonCode,
}: GridColumnsHandlers<PromotionDetailHeadTableColumnsProps>) =>
  [
    {
      field: 'userStatus',
      headerName: '계정상태',
      valueFormatter: (value) =>
        getCommonCode('PROMOTION_USER_STATUS', value)?.name,
    },
    {
      field: 'name',
      headerName: '이름',

      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.id)}
          >
            {params.value}
          </Link>
        );
      },
    },

    { field: 'accountID', headerName: '아이디' },
    {
      field: 'phoneNo',
      headerName: '연락처',
      valueGetter: (_, value) => formatTelephone(value.phoneNo),
    },

    {
      field: 'createdAt',
      headerName: '등록일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    { field: 'memo', headerName: '메모' },
  ] as TypeGuardCol<PromotionDetailHeadTableProps>[];

export type PromotionConnectionTableProps = {
  no: number;
  actionType: string;
  name: string;
  userID: string;
  actionedAt: string;
  userStatus: string;
};

export const PROMOTION_CONNECTION_TABLE_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    // { field: 'no', headerName: 'No' },
    {
      field: 'actionType',
      headerName: '구분',
      valueGetter: (value) => getCommonCode('VMS_ACTION_TYPE', value)?.name,
    },
    { field: 'name', headerName: '이름' },
    { field: 'userID', headerName: '아이디' },
    {
      field: 'actionedAt',
      headerName: '사용일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'userStatus',
      headerName: '사용가능',
      valueFormatter: (value) =>
        getCommonCode('PROMOTION_USER_STATUS', value)?.name,
    },
  ] as TypeGuardCol<PromotionConnectionTableProps>[];

export type PromotionExecutionTableProps = {
  name: string;
  userID: string;
  position: string;
  actionedAt: string;
  menu: string;
  message: string;
};

export const PROMOTION_EXECUTION_TABLE_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    { field: 'userID', headerName: '아이디' },
    {
      field: 'position',
      headerName: '직무',
      valueGetter: (value) =>
        getCommonCode('PROMOTION_USER_POSITION', value)?.name,
    },
    { field: 'name', headerName: '이름' },
    {
      field: 'actionedAt',
      headerName: '사용일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'menu',
      headerName: '사용 메뉴',
      valueFormatter: (value) =>
        getCommonCode('PROMOTION_USER_EXECUTION_MENU', value)?.name,
    },
    { field: 'message', headerName: '내용' },
  ] as TypeGuardCol<PromotionExecutionTableProps>[];
