import { QueryFunctionContext } from '@tanstack/react-query';
import type { AxiosInstance } from 'axios';

export const defaultQueryFn =
  (axiosInstance: AxiosInstance) =>
  async ({ queryKey }: QueryFunctionContext) => {
    if (queryKey[1] && typeof queryKey[1] !== 'object')
      throw Error('query second key must be object');

    const key = queryKey[0] as string;
    const params = Object.entries(queryKey[1] || {})
      .filter((param) => param[1])
      .reduce((acc: { [key: string]: unknown }, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, {});

    try {
      if (key.indexOf('/_mock') === 0) {
        const url = key.replace('/_mock', '');
        const { data } = await axiosInstance.get(url, {
          baseURL: '/',
          params,
        });

        return data;
      } else {
        const url = `/${key}`.replace('//', '/');
        const { data } = await axiosInstance.get(url, {
          params,
        });

        return data;
      }
    } catch (err) {
      if (err instanceof Error) {
        throw err;
      }
    }
  };
