import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  defaultQueryFn,
  ReactQueryProvider,
  ReactRouterProvider,
} from '@wooriga/common-providers';
import { DesignSystemProvider } from '@wooriga/design-system';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

import axiosInstance from 'apis/axiosInstance';
import FeedbackProvider from 'components/Feedback/FeedbackProvider';
import routes from 'routes';
import { CustomRouteObject } from 'types/route';

const reactQueryOptions = {
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn(axiosInstance),
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
};

export default function App() {
  return (
    <HelmetProvider>
      <CookiesProvider>
        <DesignSystemProvider mode="light" defaultColorScheme="light">
          <ReactQueryProvider config={reactQueryOptions}>
            <ReactRouterProvider<CustomRouteObject> routes={routes} />
            <ReactQueryDevtools position="left" />
          </ReactQueryProvider>
          <FeedbackProvider />
        </DesignSystemProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}
