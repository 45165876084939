import { TabPanel } from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import { MeetMethods } from 'apis/types/meet';
import BroadcastManagementTab from 'components/pages/meet-management/history/detail/tabs/BroadcastManagementTab';
import TallyResultTab from 'components/pages/meet-management/history/detail/tabs/TallyResultTab';
import VoteHomeTab from 'components/pages/meet-management/history/detail/tabs/VoteHomeTab';
import VoteManagementTab from 'components/pages/meet-management/history/detail/tabs/VoteManagementTab';
import VoterManagementTab from 'components/pages/meet-management/history/detail/tabs/VoterManagementTab';
import useFeedback from 'hooks/useFeedback';
import {
  CompleteMeetBody,
  MeetsParticipantsParams,
  useCompleteMeetMutation,
  useElectronicVoteSendStatusCountQuery,
  useElectronicVoteTendencyCountQuery,
  useElectronicVoteTimeSeriesQuery,
  useMeetsAgendasQuery,
  useMeetsAttendExpectedCountQuery,
  useMeetsDetailQuery,
  useMeetsParticipantsMutation,
  useMeetsParticipantsQuery,
  useMeetsPasswordVerifyMutation,
  useMeetsRealtimeStatusCountQuery,
  useMeetsStatusPatchMutation,
  useMeetsVoteResultMutation,
  useMeetsVoteResultQuery,
  useMeetsVoteResultSummaryQuery,
  useMeetsWrittenSubmissionCountQuery,
  useMeetsWrittenSubmissionTimeSeriesQuery,
  useUnionRegistersQuery,
  VoteResultBody,
  VoterMeetsParticipantsParams,
  useMeetsJointBookQuery,
  useMeetsOnsiteVoteCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

import RealTimeAttendance from '../RealTimeAttendance';

interface OverallTabPanelProps {
  currentTab: string;
  unionSeq: number;
  meetSeq: number;
  meetName?: string;
  meetAt?: string;
}

const voterDefaultParams: VoterMeetsParticipantsParams = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
};

const electronicDefaultParams: MeetsParticipantsParams<'ELECTRONIC'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
  isElectronicVoteResent: '',
  tendency: '',
  receivedAtFrom: '',
  receivedAtTo: '',
  openedAtTo: '',
  openedAtFrom: '',
  votedAtFrom: '',
  votedAtTo: '',
};

const onsiteDefaultParams: MeetsParticipantsParams<'ONSITE'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  tendency: '',
  attendExpectedType: '',
  writtenSubmissionStatus: '',
  writtenSubmissionAtTo: '',
  writtenSubmissionAtFrom: '',
  writtenSubmissionType: '',
};

const OverallTabPanel = ({
  currentTab,
  unionSeq,
  meetSeq,
  meetName,
  meetAt,
}: OverallTabPanelProps) => {
  const meetMethod: MeetMethods = 'ONSITE_ELECTRONIC';
  const electronic = 'ELECTRONIC';
  const onsite = 'ONSITE';
  console.log('########', meetAt);
  const currentParams =
    currentTab === electronic
      ? electronicDefaultParams
      : currentTab === onsite
      ? onsiteDefaultParams
      : voterDefaultParams;

  const { snackbar } = useFeedback();

  const [params, setParams] = useState<
    | MeetsParticipantsParams<'ELECTRONIC' | 'ONSITE'>
    | VoterMeetsParticipantsParams
  >(currentParams);

  const unionRegistersQuery = useUnionRegistersQuery(unionSeq);

  const meetsDetailQuery = useMeetsDetailQuery(meetSeq);
  const meetsAgendasQuery = useMeetsAgendasQuery(meetSeq);
  const meetsParticipantsQuery = useMeetsParticipantsQuery<
    'ELECTRONIC' | 'ONSITE'
  >(meetSeq, params);

  const meetsVoteResultSummaryQuery = useMeetsVoteResultSummaryQuery(meetSeq);

  const meetsVoteResultQuery = useMeetsVoteResultQuery(meetSeq);

  const meetsRealtimeStatusCountQuery =
    useMeetsRealtimeStatusCountQuery(meetSeq);
  // 연명부
  const meetsJointBookQuery = useMeetsJointBookQuery(meetSeq);
  const meetsOnsiteVoteCount = useMeetsOnsiteVoteCountQuery(meetSeq);

  // 전자투표
  const electronicVoteSendStatusCountQuery =
    useElectronicVoteSendStatusCountQuery(meetSeq);
  const electronicVoteTendencyCountQuery =
    useElectronicVoteTendencyCountQuery(meetSeq);

  const electronicVoteTimeSeriesQuery =
    useElectronicVoteTimeSeriesQuery(meetSeq);

  // 서면투표
  const meetsWrittenSubmissionCountQuery =
    useMeetsWrittenSubmissionCountQuery(meetSeq);
  const meetsAttendExpectedCountQuery =
    useMeetsAttendExpectedCountQuery(meetSeq);
  const meetsWrittenSubmissionTimeSeriesQuery =
    useMeetsWrittenSubmissionTimeSeriesQuery(meetSeq);

  const { mutate: passwordVerifyMutate } =
    useMeetsPasswordVerifyMutation(meetSeq);
  const { mutate: completeMeetMutate } = useCompleteMeetMutation(meetSeq);

  const { mutate: voteResultMutate } = useMeetsVoteResultMutation(meetSeq);

  const { mutate: statusPatchMutate } = useMeetsStatusPatchMutation(meetSeq);
  const { mutate } = useMeetsParticipantsMutation(meetSeq);

  const handleAddVoter = (unionRegisterSeqs: number[]) => {
    mutate(
      { unionRegisterSeqs },
      {
        onSuccess: () => {
          meetsParticipantsQuery.refetch();

          snackbar('선거인 추가가 완료되었습니다.', {
            color: 'success',
          });
        },
      },
    );
  };

  const handleEnterVoteResult = (formData: VoteResultBody) => {
    voteResultMutate(formData, {
      onSuccess: () => {
        window.location.reload();
        snackbar('개표 결과 입력이 완료되었습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error?.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleCompleteMeet = (formData: CompleteMeetBody) => {
    completeMeetMutate(formData, {
      onSuccess() {
        window.location.reload();

        snackbar('총회가 성공적으로 종료되었습니다.', {
          color: 'success',
        });
      },
    });
  };

  const handleResetMeet = () => {
    statusPatchMutate(
      { status: 'PROCESS' },
      {
        onSuccess: () => {
          window.location.reload();
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  useEffect(() => {
    setParams(
      currentTab === 'ELECTRONIC'
        ? electronicDefaultParams
        : onsiteDefaultParams,
    );
  }, [currentTab]);

  return (
    <>
      <TabPanel value={0}>
        <VoteHomeTab
          meetMethod={meetMethod}
          meetsDetailQuery={meetsDetailQuery}
          meetsAgendasQuery={meetsAgendasQuery}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          electronicVoteTendencyCountQuery={electronicVoteTendencyCountQuery}
          electronicVoteTimeSeriesQuery={electronicVoteTimeSeriesQuery}
          meetsWrittenSubmissionCountQuery={meetsWrittenSubmissionCountQuery}
          meetsAttendExpectedCountQuery={meetsAttendExpectedCountQuery}
          meetsWrittenSubmissionTimeSeriesQuery={
            meetsWrittenSubmissionTimeSeriesQuery
          }
        />
      </TabPanel>
      <TabPanel value={1}>
        <VoteManagementTab
          meetSeq={meetSeq}
          meetMethod={electronic}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          meetsParticipantsQuery={meetsParticipantsQuery}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          onSearch={setParams}
        />
      </TabPanel>

      <TabPanel value={2}>
        <VoteManagementTab
          meetSeq={meetSeq}
          meetMethod={onsite}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          meetsParticipantsQuery={meetsParticipantsQuery}
          meetsAttendExpectedCountQuery={meetsAttendExpectedCountQuery}
          meetsWrittenSubmissionCountQuery={meetsWrittenSubmissionCountQuery}
          onSearch={setParams}
        />
      </TabPanel>

      <TabPanel value={3}>
        <RealTimeAttendance
          meetMethod={meetMethod}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          meetsDetailQuery={meetsDetailQuery}
          meetsRealtimeStatusCountQuery={meetsRealtimeStatusCountQuery}
          meetsJointBookQuery={meetsJointBookQuery}
          meetSeq={meetSeq}
        />
      </TabPanel>

      <TabPanel value={4}>
        <TallyResultTab
          meetMethod={meetMethod}
          meetsDetailQuery={meetsDetailQuery}
          meetsVoteResultQuery={meetsVoteResultQuery}
          meetsRealtimeStatusCountQuery={meetsRealtimeStatusCountQuery}
          meetsOnsiteVoteCount={meetsOnsiteVoteCount}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          meetsVoteResultSummaryQuery={meetsVoteResultSummaryQuery}
          meetsAgendasQuery={meetsAgendasQuery}
          onEnterResult={handleEnterVoteResult}
          onMeetComplete={handleCompleteMeet}
          onMeetReset={handleResetMeet}
          passwordVerifyMutate={passwordVerifyMutate}
        />
      </TabPanel>
      <TabPanel value={5}>
        <VoterManagementTab
          meetMethod={meetMethod}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          unionRegistersQuery={unionRegistersQuery}
          meetsParticipantsQuery={meetsParticipantsQuery}
          onAddVoter={handleAddVoter}
          onSearch={setParams}
        />
      </TabPanel>
      <TabPanel value={6}>
        <BroadcastManagementTab
          meetSeq={meetSeq}
          meetMethod={meetMethod}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          meetName={meetName || ''}
          meetAt={meetAt || ''}
        />
      </TabPanel>
    </>
  );
};

export default OverallTabPanel;
