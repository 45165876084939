import { Link } from '@wooriga/design-system';

import { LocalAddress } from 'apis/types/common';
import { OnLinkClick, TypeGuardCol } from 'lim/_fixtures/type';
import { formatTelephone } from 'utils/format';

export type ElectronicPostsRecipientsDetailTableProps = {
  id: number;
  unionRegisterNo: string;
  shareType: string;
  positionDescription: string;
  name: string;
  memo: string;
  phoneNo: string;
  localAddresses: LocalAddress[];
  postAddress: string;
};

export const ELECTRONIC_POSTS_RECIPIENTS_TABLE_COLUMNS = (
  onLinkClick: OnLinkClick,
): TypeGuardCol<ElectronicPostsRecipientsDetailTableProps>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    width: 78,
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },

  {
    field: 'positionDescription',
    headerName: '직책',
    width: 100,
  },

  {
    field: 'name',
    headerName: '이름',
    width: 100,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onLinkClick(params.id)}
        >
          {params.value}
        </Link>
      );
    },
  },
  { field: 'memo', headerName: '비고', width: 200 },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 150,
    valueFormatter: (value) => formatTelephone(value),
  },
  {
    field: 'localAddresses',
    headerName: '소재지',
    width: 240,
    valueGetter: (_, row) => {
      const {
        city,
        gu,
        dong,
        jibun,
        building,
        apartmentDong,
        apartmentFloor,
        apartmentHosu,
      } = row?.localAddresses?.[0] || {};

      return (
        [
          city,
          gu,
          dong,
          jibun,
          building,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        ].join(' ') || ''
      );
    },
  },
  { field: 'postAddress', headerName: '우편물 수령지', width: 250 },
];
