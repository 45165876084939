/* eslint-disable @typescript-eslint/ban-ts-comment */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  AlertDialog,
  Link,
  Modal,
  Grid,
  IconButton,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useUnionRegistersQuery } from 'apis/union/register/apis';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import {
  useUnionRegisterGroupDetailQuery,
  useUnionRegisterGroupRegistersQuery,
  useUpdateRegisterGroupMutation,
} from 'lim/address-group/detail/apis';
import {
  ADDRESS_GROUP_DETAIL_TABLE_COLUMNS,
  AddressGroupDetailTableProps,
} from 'lim/address-group/detail/fixtures';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultParams = {
  unionRegisterNo: '',
  name: '',
  phoneNo: '',
  localAddress: '',
  realAddress: '',
};

const AddressGroupModifyPage = () => {
  const { snackbar } = useFeedback();
  const navigate = useNavigate();

  const { unionSeq, unionRegisterGroupSeq } = useParams();
  const unionId = Number(unionSeq);
  const unionRegisterGroupId = Number(unionRegisterGroupSeq);

  const [selectedUnionID, setSelectedUnionID] = useState(0);
  const [selectedCellIds, setSelectedCellIds] = useState<GridRowSelectionModel>(
    [],
  );
  const [selectedUnionCellIds, setSelectedUnionCellIds] =
    useState<GridRowSelectionModel>([]);

  const [formData, setFormData] = useState({
    name: '',
    memo: '',
  });

  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [unIncludedUnionData, setUnIncludedUnionData] = useState<
    AddressGroupDetailTableProps[]
  >([]);
  const [includedUnionData, setIncludedUnionData] = useState<
    AddressGroupDetailTableProps[]
  >([]);

  const [params, setParams] = useState(defaultParams);

  const { data: detailData } = useUnionRegisterGroupDetailQuery(
    unionId,
    unionRegisterGroupId,
  );

  const { data, refetch } = useUnionRegisterGroupRegistersQuery(
    unionId,
    unionRegisterGroupId,
  );

  const {
    data: rowData,
    isLoading,
    isError,
    error,
  } = useUnionRegistersQuery(unionId, params);

  const { mutate } = useUpdateRegisterGroupMutation(
    unionId,
    unionRegisterGroupId,
  );

  if (isError) throw error;

  const handleOnLinkClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    setSelectedUnionID(Number(id));
  };

  const addUnion = () => {
    const addedData: AddressGroupDetailTableProps[] = [];

    const newUnselectedData = unIncludedUnionData.filter((data) => {
      if (selectedCellIds.includes(data.id)) {
        addedData.push(data);

        return false;
      } else {
        return true;
      }
    });

    setSelectedCellIds([]);
    setUnIncludedUnionData(newUnselectedData);
    setIncludedUnionData((prev) => [...prev, ...addedData]);
  };

  const removeUnion = () => {
    const removedData: AddressGroupDetailTableProps[] = [];

    const newSelectedData = includedUnionData.filter((data) => {
      if (selectedUnionCellIds.includes(data.id)) {
        removedData.push(data);

        return false;
      } else {
        return true;
      }
    });

    setSelectedUnionCellIds([]);
    setIncludedUnionData(newSelectedData);
    setUnIncludedUnionData((prev) => [...prev, ...removedData]);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModifyGroup = () => {
    const { name, memo } = formData;
    if (!name) {
      return setAlertDialog({
        show: true,
        message: '그룹명을 입력해주세요.',
        detail: '그룹명을 입력해주세요.',
      });
    }

    const unionRegisterSeqs = includedUnionData.map((data) => data.id);

    mutate(
      {
        name,
        memo,
        unionRegisterSeqs,
      },
      {
        onSuccess: () => {
          refetch();
          snackbar('그룹 수정이 완료되었습니다.', {
            color: 'success',
          });
          navigate(-1);
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  useEffect(() => {
    if (rowData?.data) {
      const row = rowData.data
        .filter(
          (row) =>
            !includedUnionData.some(
              (includedRow) => includedRow.id === row.unionRegisterSeq,
            ),
        )
        .map((row) => {
          const {
            unionRegisterSeq,
            unionRegisterNo,
            shareType,
            positionDescription,
            name,
            memo,
            mainPhone,
            postAddress,
            localAddresses,
          } = row;
          return {
            id: unionRegisterSeq,
            unionRegisterNo,
            shareType,
            positionDescription,
            name: name?.name,
            memo,
            phoneNo: mainPhone?.phoneNo || '',
            postAddress: postAddress?.address || '',
            localAddresses,
          };
        });

      setUnIncludedUnionData(row);
    } else {
      setUnIncludedUnionData([]);
    }
  }, [rowData?.data, includedUnionData]);

  useEffect(() => {
    if (data?.data) {
      const row = data.data.map((row) => {
        const {
          unionRegisterSeq,
          unionRegisterNo,
          shareType,
          positionDescription,
          name,
          memo,
          mainPhone,
          postAddress,
          localAddresses,
        } = row;
        return {
          id: unionRegisterSeq,
          unionRegisterNo,
          shareType,
          positionDescription,
          name: name?.name,
          memo,
          phoneNo: mainPhone?.phoneNo || '',
          postAddress: postAddress?.address || '',
          localAddresses,
        };
      });

      setIncludedUnionData(row);
    }
  }, [data?.data]);

  useEffect(() => {
    if (detailData?.data) {
      const { name, memo } = detailData.data;

      setFormData({
        name,
        memo,
      });
    }
  }, [detailData?.data]);

  return (
    <>
      <Stack gap={3}>
        <Stack gap={1.75}>
          <Typography level="title-lg">그룹 수정</Typography>

          <Stack gap={1.75}>
            <TextField
              placeholder="그룹명을 입력해주세요."
              label="그룹명"
              name="name"
              value={formData.name}
              onChange={handleOnChange}
              fullWidth
              // error
              // helperText="중복된 그룹명입니다."
            />

            <TextField
              placeholder="그룹에 대한 메모를 입력해주세요(선택)"
              label="그룹메모"
              name="memo"
              value={formData.memo}
              onChange={handleOnChange}
              fullWidth
            />
          </Stack>
        </Stack>

        <Stack gap={1.75}>
          <Stack gap={1.75}>
            <Typography level="title-lg">그룹원 선택</Typography>

            <Search
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onSubmit={setParams}
              onReset={() => setParams(defaultParams)}
              defaultValues={defaultParams}
            >
              <Grid container gap={2}>
                <Grid container xs={12} gap={2}>
                  <Grid maxWidth={200}>
                    <Search.Field>
                      <TextField
                        placeholder="이름 입력"
                        label="이름"
                        name="name"
                        fullWidth
                        slotProps={{
                          input: { maxLength: 30 },
                        }}
                      />
                    </Search.Field>
                  </Grid>

                  <Grid maxWidth={200}>
                    <Search.Field>
                      <TextField
                        placeholder="숫자만 입력"
                        label="연락처"
                        name="phoneNo"
                        fullWidth
                        validateOptions={{
                          maxLength: 11,
                          regex: /^[0-9]*$/,
                        }}
                      />
                    </Search.Field>
                  </Grid>

                  <Grid maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="연번"
                        name="unionRegisterNo"
                        placeholder='숫자 또는 "-"입력'
                        validateOptions={{
                          maxLength: 11,
                          regex: /^(?!.*--)[0-9-]*$/,
                        }}
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <TextField
                      placeholder="주소 입력"
                      label="소재지"
                      name="localAddress"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <TextField
                      placeholder="주소 입력"
                      label="우편물 수령지"
                      name="postAddress"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>
              </Grid>
            </Search>
            <Stack flexDirection="row" gap={1}>
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                전체{' '}
                <Typography color="primary">
                  {commaizeNumber(rowData?.pagination?.totalDataCount || 0)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                검색{' '}
                <Typography color="primary">
                  {commaizeNumber(rowData?.pagination?.totalElements || 0)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                선택{' '}
                <Typography color="primary">
                  {commaizeNumber(selectedCellIds.length)}
                </Typography>
              </Typography>
            </Stack>
          </Stack>

          <Stack height={442}>
            <DataGrid
              rows={unIncludedUnionData}
              columns={ADDRESS_GROUP_DETAIL_TABLE_COLUMNS(handleOnLinkClick)}
              loading={isLoading}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={setSelectedCellIds}
            />
          </Stack>

          <Stack direction="row" justifyContent="center" gap={1}>
            <IconButton variant="outlined" onClick={addUnion}>
              <KeyboardArrowDownIcon />
            </IconButton>
            <IconButton variant="outlined" onClick={removeUnion}>
              <KeyboardArrowUpIcon />
            </IconButton>
          </Stack>

          {/* <Stack direction="row" alignSelf="end" gap={2}>
            <Button onClick={addUnion}>추가</Button>
          </Stack> */}
        </Stack>

        <Stack gap={1.75}>
          <Typography level="title-lg">선택된 그룹원 목록</Typography>

          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(includedUnionData.length)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택{' '}
              <Typography color="primary">
                {commaizeNumber(selectedUnionCellIds.length)}
              </Typography>
            </Typography>
          </Stack>

          <Stack height={442}>
            <DataGrid
              rows={includedUnionData}
              columns={ADDRESS_GROUP_DETAIL_TABLE_COLUMNS(handleOnLinkClick)}
              // loading={isSelectedLoading}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={setSelectedUnionCellIds}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button component={Link} color="neutral" variant="outlined" href="..">
            목록
          </Button>

          <Stack direction="row" gap={1}>
            {/* <Button color="danger" variant="outlined" onClick={removeUnion}>
              삭제
            </Button> */}
            <Button onClick={handleModifyGroup}>그룹 수정</Button>
          </Stack>
        </Stack>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq: unionId, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
    </>
  );
};

const addressGroupModifyPage: CustomRouteObject = {
  path: 'modify',
  element: <AddressGroupModifyPage />,
  handle: {
    getTitle: () => '그룹 수정',
  },
};

export default addressGroupModifyPage;
