/* eslint-disable @typescript-eslint/ban-ts-comment */

// import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  AlertDialog,
  Link,
  Grid,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
// import GroupMoveModal from 'components/pages/addressGroups/detail/GroupMoveModal';

import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import {
  UnionRegisterGroupRegistersParams,
  useUnionRegisterGroupDetailQuery,
  useUnionRegisterGroupRegistersQuery,
} from 'lim/address-group/detail/apis';
import { ADDRESS_GROUP_DETAIL_TABLE_COLUMNS } from 'lim/address-group/detail/fixtures';
import addressGroupModifyPage from 'pages/main/union-management/address-groups/modify';
// import { useAddressGroupDetailGroupMoveMutation } from 'lim/addressGroupDetail/apis';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber, formatDateTime } from 'utils/format';

const defaultParams = {
  name: '',
  unionRegisterNo: '',
  phoneNo: '',
  shareType: '',
  localAddress: '',
  postAddress: '',
  positionDescription: '',
};

const AddressGroupDetailPage = () => {
  const { unionSeq, unionRegisterGroupSeq } = useParams();
  const unionId = Number(unionSeq);
  const unionRegisterGroupId = Number(unionRegisterGroupSeq);

  const [selectedUnionID, setSelectedUnionID] = useState(0);

  // const [selectedCellIds, setSelectedCellIds] = useState<GridRowSelectionModel>(
  //   [],
  // );

  const [dialogContent, setDialogContent] = useState({
    show: false,
    message: '',
    detail: '',
  });

  // const [showGroupMoveModal, setShowGroupMoveModal] = useState(false);
  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [params, setParams] = useState(defaultParams);

  const { data, isLoading, isError, error } = useUnionRegisterGroupDetailQuery(
    unionId,
    unionRegisterGroupId,
  );

  const { data: rowData } = useUnionRegisterGroupRegistersQuery(
    unionId,
    unionRegisterGroupId,
    params,
  );

  const isAutoGenerated = data?.data?.autoGenerated;

  // const { data: groupList } = useUnionRegisterGroupsQuery(unionId);

  // const { mutate: moveMutate } = useAddressGroupDetailGroupMoveMutation();

  if (isError) throw error;

  // const handleShowGroupList = () => {
  //   alert('7/13 현재 API 부재');

  //   if (selectedCellIds.length === 0) {
  //     setDialogContent({
  //       show: true,
  //       message: '선택한 항목이 없습니다.',
  //       detail: '',
  //     });

  //     return;
  //   }

  //   setShowGroupMoveModal(true);
  // };

  const handleDialogOnSubmit = () => {
    setDialogContent({
      ...dialogContent,
      show: false,
    });
  };

  // const handleOnGroupMove = () => {
  //   moveMutate(null, {
  //     onSuccess: () => {
  //       setShowGroupMoveModal(false);
  //     },
  //   });
  // };

  const handleOnLinkClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    setSelectedUnionID(Number(id));
  };

  const handleOnSearch = (params: UnionRegisterGroupRegistersParams) => {
    setParams({
      ...params,
    });
  };

  const handleOnReset = () => {
    setParams(defaultParams);
  };

  const rows = useMemo(() => {
    return (
      rowData?.data?.map((row) => {
        const {
          unionRegisterSeq,
          unionRegisterNo,
          shareType,
          positionDescription,
          name,
          memo,
          mainPhone,
          localAddresses,
          postAddress,
        } = row;

        return {
          id: unionRegisterSeq,
          unionRegisterNo,
          shareType,
          positionDescription,
          name: name?.name,
          memo,
          phoneNo: mainPhone?.phoneNo || '',
          postAddress: postAddress?.address || '',
          localAddresses,
        };
      }) || []
    );
  }, [rowData]);

  const getDescriptions = () => {
    if (!data?.data)
      return generateDescriptionsJSON(
        ['그룹명', '그룹원 수', '생성일시', '생성자', '메모'],
        [],
      );
    const { name, unionRegisterCount, createdAt, createdBy, memo } = data.data;

    return generateDescriptionsJSON(
      ['그룹명', '그룹원 수', '생성일시', '생성자', '메모'],
      [
        name,
        unionRegisterCount,
        formatDateTime(createdAt, 'yyyy-MM-dd HH:mm:ss'),
        createdBy,
        memo,
      ],
    );
  };

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Typography level="title-lg">그룹 정보 상세</Typography>

          <DescriptionsRenderer json={getDescriptions()} />

          <Search
            //@ts-ignore
            onSubmit={handleOnSearch}
            onReset={handleOnReset}
            defaultValues={defaultParams}
          >
            <Grid container gap={2}>
              <Grid container xs={12} gap={2}>
                <Grid maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="이름 입력"
                      label="이름"
                      name="name"
                      fullWidth
                      slotProps={{
                        input: { maxLength: 30 },
                      }}
                    />
                  </Search.Field>
                </Grid>

                <Grid maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="숫자만 입력"
                      label="연락처"
                      name="phoneNo"
                      fullWidth
                      validateOptions={{
                        maxLength: 11,
                        regex: /^[0-9]*$/,
                      }}
                    />
                  </Search.Field>
                </Grid>

                <Grid maxWidth={200}>
                  <Search.Field>
                    <TextField
                      label="연번"
                      name="unionRegisterNo"
                      placeholder='숫자 또는 "-"입력'
                      validateOptions={{
                        maxLength: 11,
                        regex: /^(?!.*--)[0-9-]*$/,
                      }}
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="직책 입력"
                      label="직책"
                      name="positionDescription"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <TextField
                    placeholder="주소 입력"
                    label="소재지"
                    name="localAddress"
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <TextField
                    placeholder="주소 입력"
                    label="우편물 수령지"
                    name="postAddress"
                    fullWidth
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(rowData?.pagination?.totalDataCount || 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(rowData?.pagination?.totalElements || 0)}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Stack height={442}>
          <DataGrid
            rows={rows}
            columns={ADDRESS_GROUP_DETAIL_TABLE_COLUMNS(handleOnLinkClick)}
            loading={isLoading}
            rowHeight={38}
            checkboxSelection
            disableRowSelectionOnClick
            // onRowSelectionModelChange={setSelectedCellIds}
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button component={Link} color="neutral" variant="outlined" href="..">
            목록
          </Button>
          <Button component={Link} href="modify" disabled={isAutoGenerated}>
            그룹 수정
          </Button>
          {/* <Button onClick={handleShowGroupList}>그룹 이동</Button> */}
        </Stack>
      </Stack>

      {dialogContent.show && (
        <AlertDialog
          color="primary"
          size="md"
          message={dialogContent.message}
          detail={dialogContent.detail}
          onSubmit={handleDialogOnSubmit}
        />
      )}

      {/* <GroupMoveModal
        open={showGroupMoveModal}
        onClose={setShowGroupMoveModal}
        count={selectedCellIds.length}
        list={groupList.data}
        OnGroupMove={handleOnGroupMove}
      /> */}

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq: unionId, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

const addressGroupDetailPage: CustomRouteObject = {
  path: ':unionRegisterGroupSeq',
  children: [
    {
      path: '',
      index: true,
      element: <AddressGroupDetailPage />,
    },
    addressGroupModifyPage,
  ],

  handle: {
    getTitle: () => '주소록 그룹 상세 정보',
  },
};

export default addressGroupDetailPage;
