import {
  Button,
  DataGrid,
  Pagination,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useElectronicDocumentListQuery,
  useElectronicDocumentsSendMutation,
  useReadElectronicDocumentsBalanceMutation,
} from 'apis/electronic-posts/apis';
import { ELECTRONIC_POSTS_LIST_GRID_COLUMNS } from 'apis/electronic-posts/fixtures/send-lists';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import createPage from 'pages/main/union-management/electronic-posts/create';
import openPage from 'pages/main/union-management/electronic-posts/create/openPage';
import detailPage from 'pages/main/union-management/electronic-posts/detail';
import ElectronicPostsBalanceCheckModal from 'pages/main/union-management/electronic-posts/modal/ElectronicPostsBalanceCheckModal';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const ElectronicPostsListPage = () => {
  const params = useParams();
  const { unionSeq } = params;
  const navigate = useNavigate();
  const { snackbar } = useFeedback();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [isBalanceOpen, setIsBalanceOpen] = useState<boolean>(false);
  const [targetDocumentSeq, setTargetDocumentSeq] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);

  const {
    data: electronicPostsData,
    refetch,
    isLoading,
    isPending,
    isError,
    error,
  } = useElectronicDocumentListQuery({ unionSeq: Number(unionSeq) || 0 });

  const { mutate: electronicDocumentSendMutate } =
    useElectronicDocumentsSendMutation();
  const { mutate: electronicDocumentCheckBalanceMutate } =
    useReadElectronicDocumentsBalanceMutation();

  // 문서발송
  const handleSendDocument = useCallback((electronicDocumentSeq: number) => {
    electronicDocumentSendMutate(
      { unionSeq: Number(unionSeq), electronicDocumentSeq },
      {
        onSuccess: () => {
          snackbar('전자 등기가 발송 되었습니다.', {
            color: 'primary',
          });
          refetch();
        },
        onError: (error) => {
          snackbar(error?.response?.data?.message || '', {
            color: 'danger',
          });
        },
      },
    );
  }, []);

  const handleCheckBalance = useCallback((electronicDocumentSeq: number) => {
    electronicDocumentCheckBalanceMutate(
      { unionSeq: Number(unionSeq), electronicDocumentSeq },
      {
        onSuccess: (res) => {
          setTotalCost(res?.data?.totalCost ?? 0);

          setIsBalanceOpen(true);
          setTargetDocumentSeq(electronicDocumentSeq);
          //handleSendDocument(electronicDocumentSeq);
        },
        onError: (error) => {
          snackbar(error?.response?.data?.message || '', {
            color: 'danger',
          });
        },
      },
    );
  }, []);

  const columnHandlers = useMemo(
    () => ({
      onSendDocument: handleCheckBalance,
    }),
    [],
  );

  const { columns } = useCreateGridColumns({
    columns: ELECTRONIC_POSTS_LIST_GRID_COLUMNS,
    handlers: columnHandlers,
  });

  const handleClickButton = () => {
    navigate('recipient');
  };
  if (isError) throw error;

  const totalElements = electronicPostsData?.pagination?.totalElements || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  return (
    <>
      <Stack gap={2}>
        {/* <Search
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={handleSearchParams}
          // defaultValues={defaultSearchParams}
          // onReset={handleSearchReset}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="제목 입력"
                  label="제목"
                  name="title"
                  fullWidth
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <DateRangePicker
                  label="기간 조회"
                  name="searchFromTo"
                  sx={{ field: { xs: 12, maxWidth: 200 } }}
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search> */}
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(
                  electronicPostsData?.pagination?.totalDataCount || 0,
                )}
              </Typography>
            </Typography>

            {/* <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">

                {commaizeNumber(0)}
              </Typography>
            </Typography> */}
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Stack height={442}>
            <DataGrid
              // rows={Dummy_Rows}
              rows={electronicPostsData?.data || []}
              getRowId={(row) => row.electronicDocumentSeq}
              columns={columns}
              loading={isLoading || isPending}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            ></DataGrid>
          </Stack>
          <Stack alignItems="center">
            <Pagination
              color="neutral"
              variant="plain"
              size="md"
              orientation="horizontal"
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={false}
              hideNextButton={false}
              count={pageTotalCount}
              onChange={(_, page) => {
                page &&
                  setPaginationModel({
                    ...paginationModel,
                    page: page - 1,
                  });
              }}
            />
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
            <Button onClick={handleClickButton}>전자 등기 문서 생성</Button>
          </Stack>
        </Stack>
      </Stack>
      {isBalanceOpen && (
        <ElectronicPostsBalanceCheckModal
          open={isBalanceOpen}
          onClose={setIsBalanceOpen}
          totalCost={totalCost}
          handleSendDocument={handleSendDocument}
          electronicDocumentSeq={targetDocumentSeq}
        />
      )}
    </>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <ElectronicPostsListPage />,
  handle: {
    getTitle: () => '전자 등기',
  },
};

const open = {
  path: '/unions/:unionSeq/electronic-posts',
  children: [route, openPage, createPage, detailPage],
  handle: {
    getTitle: () => '전자 등기',
    getMenuCode: () => 'M0413',
  },
};

export default open;
