/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridColDef } from '@mui/x-data-grid-premium';
import { Checkbox, Link, Stack } from '@wooriga/design-system';
import { ChangeEvent } from 'react';

import { CreateDocumentIssuanceBody } from 'apis/document-issuance/apis';
import { RegistrationAddress } from 'apis/types/common';
import { DocumentIssuanceRegister } from 'apis/types/document-issuance';
import CommonCode from 'components/CommonCode';
import { GridColumnsHandlers } from 'types/grid';
import { formatDateTime } from 'utils/format';

export type DocumentIssuanceSelectedUnionRegisters =
  (CreateDocumentIssuanceBody['unionRegisters'][number] & {
    localAddressSeq: number;
  })[];

export interface DocumentIssuanceTopColumnProps {
  onClickUnionMember: (unionRegisterSeq: number) => void;
}

export interface DocumentIssuanceBottomColumnProps {
  unionRegisters: DocumentIssuanceSelectedUnionRegisters;
  onClickUnionMember: (unionRegisterSeq: number) => void;
  onChangeCellCheckbox: (
    event: ChangeEvent<HTMLInputElement>,
    localAddressSeq: number,
  ) => void;
}

export const DOCUMENT_ISSUANCE_TOP_COLUMNS = ({
  getCommonCode,
  onClickUnionMember,
}: GridColumnsHandlers<DocumentIssuanceTopColumnProps>): GridColDef<DocumentIssuanceRegister>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    width: 78,
    valueGetter: (_, row) => row?.unionRegister?.unionRegisterNo || '',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'name',
    headerName: '조합원 이름',
    width: 90,
    valueGetter: (_, row) =>
      row?.unionRegister &&
      (row?.unionRegister?.name
        ? [
            row?.unionRegister?.name?.name,
            row?.unionRegister?.name?.nameClass,
          ].join('')
        : '이름없음'),
    renderCell: ({ value, row }) => (
      <Link
        display="inline"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        onClick={() => onClickUnionMember(row.unionRegister.unionRegisterSeq)}
      >
        {value}
      </Link>
    ),
  },
  {
    field: 'shareType',
    headerName: '소유구분',
    width: 78,
    valueGetter: (_, row) =>
      (row?.unionRegister?.shareType &&
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row.unionRegister.shareType)
          ?.name) ||
      '',
  },
  {
    field: 'localAddress',
    headerName: '소재지',
    width: 240,
    valueGetter: (_, row) => {
      const {
        city,
        gu,
        dong,
        jibun,
        building,
        apartmentDong,
        apartmentFloor,
        apartmentHosu,
      } = row?.unionRegister?.localAddresses?.[0] || {};

      return (
        [
          city,
          gu,
          dong,
          jibun,
          building,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        ].join(' ') || ''
      );
    },
  },
  {
    field: 'realEstates',
    headerName: '부동산 구분',
    width: 78,
    valueGetter: (_, row) => {
      if (
        !row.unionRegister.localAddresses ||
        !Array.isArray(row.unionRegister.localAddresses)
      ) {
        return '';
      }

      const { registrationAddresses = [] } =
        row.unionRegister.localAddresses[0];

      return registrationAddresses
        .flatMap(
          ({ registrationType }) =>
            getCommonCode('REGISTRATION_TYPE', registrationType)?.name,
        )
        .join(', ');
    },
  },
  {
    field: 'lastIssuedAt',
    headerName: '최종발급일시',
    width: 180,
    valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const DOCUMENT_ISSUANCE_BOTTOM_COLUMNS = ({
  unionRegisters,
  getCommonCode,
  onClickUnionMember,
  onChangeCellCheckbox,
}: GridColumnsHandlers<DocumentIssuanceBottomColumnProps>): GridColDef<DocumentIssuanceRegister>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    width: 78,
    valueGetter: (_, row) => row?.unionRegister?.unionRegisterNo || '',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'name',
    headerName: '조합원 이름',
    width: 90,
    valueGetter: (_, row) =>
      row?.unionRegister &&
      (row?.unionRegister?.name
        ? [
            row?.unionRegister?.name?.name,
            row?.unionRegister?.name?.nameClass,
          ].join('')
        : '이름없음'),
    renderCell: ({ value, row }) => (
      <Link
        display="inline"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        onClick={() => onClickUnionMember(row.unionRegister.unionRegisterSeq)}
      >
        {value}
      </Link>
    ),
  },
  {
    field: 'shareType',
    headerName: '소유구분',
    width: 78,
    valueGetter: (_, row) =>
      (row?.unionRegister?.shareType &&
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row.unionRegister.shareType)
          ?.name) ||
      '',
  },
  {
    field: 'localAddress',
    headerName: '소재지',
    width: 240,
    valueGetter: (_, row) => {
      const {
        city,
        gu,
        dong,
        jibun,
        building,
        apartmentDong,
        apartmentFloor,
        apartmentHosu,
      } = row?.unionRegister?.localAddresses?.[0] || {};

      return (
        [
          city,
          gu,
          dong,
          jibun,
          building,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        ].join(' ') || ''
      );
    },
  },
  {
    field: 'realEstates',
    headerName: '부동산 구분',
    width: 240,
    display: 'flex',
    sortable: false,
    valueGetter: (_, row) => {
      if (
        !row.unionRegister.localAddresses ||
        !Array.isArray(row.unionRegister.localAddresses)
      ) {
        return '';
      }

      const { registrationAddresses = [] } =
        row.unionRegister.localAddresses[0];

      return registrationAddresses.map(
        ({ registrationAddressSeq, registrationType }) => ({
          registrationAddressSeq,
          registrationType,
        }),
      );
    },
    renderCell: ({ value, row }) => {
      const unionRegister = unionRegisters.find(
        ({ localAddressSeq }) =>
          localAddressSeq ===
          row.unionRegister.localAddresses[0].localAddressSeq,
      );

      return (
        <Stack direction="row" gap={1}>
          {value.map(
            (
              { registrationAddressSeq, registrationType }: RegistrationAddress,
              index: number,
            ) => {
              if (!registrationType) {
                return;
              }

              return (
                <Checkbox
                  size="sm"
                  key={`checkbox_${registrationAddressSeq}_${registrationType}_${index}`}
                  label={
                    <CommonCode
                      groupCode="REGISTRATION_TYPE"
                      code={registrationType}
                    />
                  }
                  checked={
                    unionRegister?.registrationAddressSeqs.includes(
                      registrationAddressSeq,
                    ) ?? false
                  }
                  value={registrationAddressSeq}
                  onChange={(event) =>
                    onChangeCellCheckbox(
                      event,
                      unionRegister?.localAddressSeq ?? 0,
                    )
                  }
                />
              );
            },
          )}
        </Stack>
      );
    },
  },
];
