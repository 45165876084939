import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';

interface MockMeetInfoModalProps {
  open: boolean;
  unionCount: number;

  onClose: (show: false) => void;
  onConfirm: (count: number) => void;
}

const MockMeetInfoModal = ({
  open,
  unionCount,
  onClose,
  onConfirm,
}: MockMeetInfoModalProps) => {
  const { alertDialog } = useFeedback();

  const [selectedCount, setSelectedCount] = useState(0);

  const handleConfirm = () => {
    if (selectedCount < unionCount) {
      return alertDialog(
        '전체 선거인 수는 실제 선거인 수보다 적을 수 없습니다.',
        {
          color: 'danger',
        },
      );
    }
    onClose(false);
    onConfirm(selectedCount);
  };

  useEffect(() => {
    setSelectedCount(unionCount);
  }, [unionCount]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>가상 선거인단 설정</DialogTitle>
        <DialogContent>
          <Stack marginBottom={3}>
            <Typography level="title-md">
              모의 투표를 선택하셨기 때문에 가상 선거인단을 설정하셔야 합니다.
            </Typography>
            <Typography fontWeight="bold" color="danger">
              아래 실제 선거인 수에 100명을 입력하시면 실제 선거인 10명과 가상
              선거인 90명으로 모의 투표가 진행됩니다.
            </Typography>
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel>
                실제 선거인을 포함한 전체 선거인 수를 입력해 주세요.
              </FormLabel>
              <Input
                defaultValue={unionCount}
                validateOptions={{
                  regex: /^[0-9\s]*$/,
                }}
                onChange={(e) =>
                  setSelectedCount(Number(e.currentTarget.value))
                }
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => onClose(false)}
          >
            취소
          </Button>

          <Button onClick={handleConfirm}>확인</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default MockMeetInfoModal;
