import { GridColDef } from '@mui/x-data-grid-premium';
import { Button, Input, Link } from '@wooriga/design-system';
import { ChangeEvent } from 'react';
import { Address } from 'react-daum-postcode';

import { PostRegister } from 'apis/types/post';
import AddressModalTrigger from 'components/pages/posts/AddressModalTrigger';
import { GridColumnsHandlers } from 'types/grid';
import { formatTelephone } from 'utils/format';

export interface PostRegisterColumnProps {
  onClickUnionMember: (unionRegisterSeq: number | undefined) => void;
  onClickAddress: (address: Address, id: number) => void;
}

export const POST_REGISTER_COLUMNS = ({
  getCommonCode,
  onClickUnionMember,
  onClickAddress,
}: GridColumnsHandlers<PostRegisterColumnProps>): GridColDef<
  PostRegister & { action: '' }
>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    width: 78,
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'name',
    headerName: '조합원 이름',
    width: 90,
    valueGetter: (_, row) =>
      row?.unionRegisterSeq &&
      (row?.name
        ? [row?.name?.name, row?.name?.nameClass].join('')
        : '이름없음'),
    renderCell: ({ value, row }) => (
      <Link
        display="inline"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        onClick={() => onClickUnionMember(row.unionRegisterSeq)}
      >
        {value}
      </Link>
    ),
  },
  {
    field: 'positionDescription',
    headerName: '직책',
    width: 78,
  },
  {
    field: 'tendency',
    headerName: '우호도',
    width: 78,
    valueGetter: (value) => getCommonCode('TENDENCY_TYPE', value)?.name,
  },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
    width: 80,
  },
  {
    field: 'phone',
    headerName: '연락처',
    width: 110,
    valueGetter: (_, row) =>
      formatTelephone(
        row.mainPhone?.phoneNo ??
          (Array.isArray(row.subPhones) && row.subPhones.length > 0
            ? row.subPhones[0].phoneNo
            : ''),
      ),
  },
  {
    field: 'address',
    headerName: '우편물 수령지',
    width: 230,
    valueGetter: (_, row) => row.postAddress?.address ?? '',
  },
  {
    field: 'detailAddressInput',
    headerName: '상세 우편물 수령지',
    width: 230,
    display: 'flex',
    editable: true,
    valueGetter: (_, row) => row.postAddress?.detailAddress ?? '',
    renderCell: ({ value }) => {
      return (
        <Input placeholder="상세주소 입력" value={value} readOnly fullWidth />
      );
    },
    renderEditCell: ({ id, value, api, field }) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        api.setEditCellValue({ id, field, value: event.currentTarget.value });
      };

      return (
        <Input
          placeholder="상세주소 입력"
          value={value}
          onChange={handleChange}
          fullWidth
        />
      );
    },
  },
  {
    field: 'zipNo',
    headerName: '우편번호',
    width: 90,
    valueGetter: (_, row) => row.postAddress?.zipNo ?? '',
  },
  {
    field: 'action',
    headerName: '주소변경',
    width: 80,
    renderCell: ({ id }) => (
      <AddressModalTrigger
        onComplete={(value) => onClickAddress(value, id as number)}
      >
        <Button variant="outlined" color="neutral" size="sm">
          검색
        </Button>
      </AddressModalTrigger>
    ),
  },
];
