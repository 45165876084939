import CloseIcon from '@mui/icons-material/Close';
import { ColorPaletteProp, styled, VariantProp } from '@mui/joy';
import useControlled from '@mui/utils/useControlled';
import * as _ from 'lodash-es';
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import List, {
  ListProps,
} from 'design-system/components/dataDisplay/list/List';
import ListItem from 'design-system/components/dataDisplay/list/ListItem';
import Typography from 'design-system/components/dataDisplay/Typography';
import IconButton from 'design-system/components/inputs/IconButton';
import Sheet from 'design-system/components/surfaces/Sheet';

export type FileListSlot = 'root' | 'label';

export interface FileListProps<T extends object = object>
  extends Omit<ListProps, 'defaultValue' | 'value' | 'onChange' | 'onBlur'> {
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: 'sm' | 'md' | 'lg';
  readOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;

  defaultValue?: T[];
  value?: T[];
  keyGetter?: (value: T) => string;
  labelGetter?: (value: T) => ReactNode;
  onChange?: (value: T[]) => void;
  onDelete?: (value: T) => void;
}

export type FileListOwnerState = FileListProps;

const FileListInner = <T extends object = object>(
  props: FileListProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    variant,
    color,
    size,
    readOnly,
    disabled,
    fullWidth,

    defaultValue = [],
    value: valueProp,
    onChange,
    onDelete,
    keyGetter,
    labelGetter,

    ...other
  } = props;

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'FileList',
    state: 'value',
  });

  const ownerState = {
    disabled,
    variant,
    color,
    size,
    fullWidth,
  };

  const handleDelete = useCallback(
    (deleteValue: T) => {
      const newValue = _.without(value, deleteValue);
      setValue(newValue);
      onDelete?.(deleteValue);
      onChange?.(newValue);
    },
    [onChange, onDelete, setValue, value],
  );

  const items = useMemo(() => {
    return value?.map((item, index) => {
      const key = keyGetter ? keyGetter(item) : index;
      const label = labelGetter && labelGetter(item);

      return (
        <ListItem key={key}>
          <Typography
            endDecorator={
              !readOnly && (
                <IconButton
                  size={size}
                  onClick={() => handleDelete(item)}
                  disabled={disabled}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            textColor="inherit"
          >
            {label}
          </Typography>
        </ListItem>
      );
    });
  }, [disabled, handleDelete, keyGetter, labelGetter, readOnly, size, value]);

  return (
    value?.length > 0 && (
      <FileListRoot ownerState={ownerState} ref={ref}>
        <List size={size} {...other}>
          {items}
        </List>
      </FileListRoot>
    )
  );
};

const FileList = forwardRef(FileListInner) as <T extends object = object>(
  props: FileListProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof FileListInner>;

const FileListRoot = styled(Sheet, {
  name: 'JoyFileList',
  slot: 'root',
})<{ ownerState: FileListOwnerState }>(
  ({
    theme,
    ownerState: {
      fullWidth,
      variant = 'outlined',
      size = 'sm',
      color = 'neutral',
      disabled,
    },
  }) => ({
    width: 348,

    borderRadius: theme.radius[size],
    ...theme.variants[variant][color],

    ...(fullWidth && { width: '100%' }),

    ...(disabled && {
      color: theme.palette.neutral[400],
    }),
  }),
);

export default FileList;
