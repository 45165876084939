import { styled, useTheme } from '@mui/joy';
import {
  Button,
  Chip,
  Input,
  Stack,
  Textarea,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useFileUploadMutation } from 'apis/common/apis';
import {
  AgendaResponse,
  CandidateResponse,
  MeetMethods,
  MeetRequest,
} from 'apis/types/meet';
import useFeedback from 'hooks/useFeedback';

import Candidate, { AgendaFormDataProps } from './Candidate';

export type AgendaProps = Omit<MeetRequest['agendas'][number], 'candidates'> & {
  candidates: AgendaFormDataProps[];
};

interface VoteItemProps {
  index: number;
  defaultData?: AgendaResponse;
  meetMethod: MeetMethods;
  agenda: AgendaProps;
  onChange: (formData: MeetRequest['agendas'][number]) => void;
  onDelete: () => void;
}

export const defaultCandidate = {
  name: '',
  description: '',
  attachFileSeq: undefined,
};

const ACCEPT_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

const VoteItem = ({
  index,
  defaultData,
  meetMethod,
  agenda,

  onDelete,
  onChange,
}: VoteItemProps) => {
  const { voteType, voteForm } = agenda;

  const count = agenda.candidates.length;

  const fileRefs = useRef<(HTMLDivElement | null)[]>([]);

  const theme = useTheme();
  const { alertDialog, snackbar } = useFeedback();

  const { mutate } = useFileUploadMutation();

  const [formData, setFormData] = useState<AgendaProps>({
    name: '',
    order: index + 1,
    voteType,
    voteForm,
    description: '',
    // attachFileSeq: 0,
    selectCount: 1,
    candidates: [],
  });

  const [defaultCandidates, setDefaultCandidates] = useState<
    CandidateResponse[]
  >([]);

  const [fileName, setFileName] = useState(
    'PDF, JPG, JPEG, PNG 파일 1개만 첨부',
  );

  const [fakeIds, setFakeIds] = useState<number[]>([]);

  const { name, description, candidates } = formData;

  const handleCandidatesNo = (index: number, isAdd: boolean = false) => {
    const candidates = [...formData.candidates];
    const ids = [...fakeIds];

    const newValue = {
      ...formData,
      candidates,
    };

    if (isAdd) {
      candidates.splice(index + 1, 0, defaultCandidate);
      ids.splice(index + 1, 0, Math.random());

      setFormData(newValue);
    } else {
      candidates.splice(index, 1);
      ids.splice(index, 1);

      handleStateChange(newValue);
    }

    setFakeIds(ids);
  };

  const handleStateChange = (newValue: AgendaProps) => {
    const candidates = newValue.candidates.map((candidate, i) => ({
      ...candidate,
      no: i + 1,
    }));

    setFormData(newValue);
    onChange({
      ...newValue,
      candidates,
    });
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    const newValue = {
      ...formData,
      [name]: value && !isNaN(Number(value)) ? Number(value) : value,
    };

    handleStateChange(newValue);
  };

  const handleOnCandidateChange =
    (index: number) => (agendaFormData: AgendaFormDataProps) => {
      const candidates = [...formData.candidates];

      candidates[index] = {
        ...agendaFormData,
      };

      const newValue = {
        ...formData,
        candidates,
      };

      handleStateChange(newValue);
    };

  const handleOnAdd = (index: number) => () => {
    handleCandidatesNo(index, true);
  };

  const handleOnDelete = (index: number) => () => {
    if (candidates.length === 2)
      return alertDialog('후보 수는 2명 이하일 수 없습니다.');
    handleCandidatesNo(index);
  };

  const handleChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files: originalFiles } = event.currentTarget;
    const files = Array.from(originalFiles ?? []);

    const isAcceptFiles = files.every((file) =>
      ACCEPT_FILE_TYPES.includes(file.type),
    );

    if (!isAcceptFiles) {
      return;
    }

    setFileName(originalFiles?.[0].name || '');

    if (originalFiles)
      mutate(
        { divisionCode: 'MEET', files: originalFiles },
        {
          onSuccess: ({ data }) => {
            if (data) {
              setFileName(originalFiles?.[0].name || '');

              const newValue = {
                ...formData,
                attachFileSeq: data[0].fileSeq,
              };
              handleStateChange(newValue);
              setFormData(newValue);
            }
          },
          onError: (error) => {
            snackbar(error?.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
  };

  const handleClickOpenFileFinder = () => {
    if (fileRefs.current[0]) {
      fileRefs.current[0].click();
    }
  };

  const handleOnBlur = () => {
    if (candidates.length < formData.selectCount) {
      alertDialog('후보 수가 복수선택 수보다 적을 수 없습니다.');
      setFormData({
        ...formData,
        selectCount: candidates.length,
      });
    }
  };

  useEffect(() => {
    if (defaultData?.name && defaultData.description) {
      const { attachFile, candidates, ...rest } = defaultData;

      if (defaultData.attachFile?.originName)
        setFileName(attachFile.originName);

      const postCandidates: MeetRequest['agendas'][number]['candidates'] =
        candidates.map((candidate) => {
          const { attachFile, ...rest } = candidate;
          return {
            ...rest,
            attachFileSeq: attachFile?.fileSeq,
          };
        });

      setDefaultCandidates(candidates);
      return setFormData({
        ...formData,
        ...rest,
        attachFileSeq: attachFile?.fileSeq,
        candidates: postCandidates,
      });
    }

    if (count > fakeIds.length) {
      setFakeIds((ids) => [...ids, Math.random()]);
    }

    const candidates = Array(count)
      .fill(0)
      .map(() => defaultCandidate);

    setFormData({
      ...formData,
      candidates,
      voteForm,
      voteType,
      selectCount: 1,
    });
  }, []);

  const isMultiple = voteForm === 'SELECT' && voteType === 'MULTI';
  const includeElectronic = meetMethod.includes('ELECTRONIC');
  const voteUnit = voteForm === 'AGAINST' ? '명(건)' : '개';
  const voteFormName =
    voteForm === 'SELECT'
      ? '선택투표'
      : voteForm === 'AGAINST'
      ? '찬반투표'
      : '';

  return (
    <Stack
      border={`1px ${theme.palette.neutral[300]} solid`}
      padding={2}
      borderRadius={5}
      gap={2}
      bgcolor={theme.palette.neutral[50]}
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack gap={1} flexDirection="row" alignItems="center">
          <Bar />
          <Typography level="title-md">제 {index + 1}호 안건</Typography>
          <Chip>
            {voteFormName} | {voteForm === 'AGAINST' && '후보'} {count}
            {voteUnit}
            {voteType === 'MULTI' && '이상'}
          </Chip>
        </Stack>
        <Button color="danger" variant="outlined" onClick={onDelete}>
          삭제
        </Button>
      </Stack>
      <Stack>
        <Input
          placeholder="안건 명 입력*"
          name="name"
          value={name}
          onChange={handleOnChange}
        />
      </Stack>

      <Stack>
        <Textarea
          placeholder="안건에 대한 설명 입력"
          name="description"
          value={description}
          onChange={handleOnChange}
          sx={{ height: '96px' }}
        />
      </Stack>

      {includeElectronic && (
        <Stack flexDirection="row" gap={1}>
          <Input
            type="file"
            name={name}
            sx={{ display: 'none' }}
            slotProps={{
              input: {
                ref: (element) => (fileRefs.current[0] = element),
              },
            }}
            placeholder={fileName}
            onChange={handleChangeFileInput}
          />
          <TextField
            // label={label}
            fullWidth
            name="attachFileSeq"
            placeholder={fileName}
            // value={file?.name ?? ''}

            required
            readOnly
            endDecorator={
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleClickOpenFileFinder}
              >
                내 PC에서 올리기
              </Button>
            }
          />
        </Stack>
      )}

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography level="title-md">
            후보 목록{' '}
            <span style={{ color: theme.palette.primary[500] }}>
              {candidates.length}
            </span>
          </Typography>

          {isMultiple && (
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography level="body-md">복수 선택 항목 갯수</Typography>
              <Input
                sx={{ width: '50px' }}
                name="selectCount"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                value={formData.selectCount}
              />{' '}
              <Typography level="body-md">건</Typography>
            </Stack>
          )}
        </Stack>

        {candidates.map((candidate, index) => (
          <Candidate
            key={fakeIds[index]}
            includeElectronic={includeElectronic}
            isOnly={count === 1}
            index={index}
            defaultData={defaultCandidates[index]}
            candidate={candidate}
            onChange={handleOnCandidateChange(index)}
            onAdd={handleOnAdd(index)}
            onDelete={handleOnDelete(index)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const Bar = styled('div')`
  width: 4px;
  height: 14px;
  background-color: ${({ theme }) => theme.palette.primary[500]};
`;

export default VoteItem;
