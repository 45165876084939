import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  DateTimePicker,
  Input,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

import { MeetMethods, OnsiteVoteBase } from 'apis/types/meet';
import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';

type VoteDates = 'meetAt' | 'submissionStartAt' | 'submissionEndAt';

interface OnsiteRadioProps {
  meetMethod: MeetMethods;
  voteEndAt: string;

  onChange: (formData: OnsiteVoteBase) => void;
}

const OnsiteRadio = ({ meetMethod, voteEndAt, onChange }: OnsiteRadioProps) => {
  const dates = useRef({
    meetAt: '',
    submissionStartAt: '',
    submissionEndAt: '',
  });
  // const times = useRef({
  //   meetAt: '',
  //   submissionStartAt: '',
  //   submissionEndAt: '',
  // });

  const [onsiteVoteInfo, setOnsiteVoteInfo] = useState<OnsiteVoteBase>({
    meetAt: '',
    submissionStartAt: '',
    submissionEndAt: '',
    jibunAddress: '',
    roadAddress: '',
    detailAddress: '',
    attendanceRate: 10,
  });

  const handleStateChange = useCallback(
    (value: OnsiteVoteBase) => {
      setOnsiteVoteInfo(value);
      onChange(value);
    },
    [onChange],
  );

  const handleVoteInfoChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    const newValue = {
      ...onsiteVoteInfo,
      [name]: value,
    };

    handleStateChange(newValue);
  };

  const handleChangeDate = useCallback(
    (at: 'StartAt' | 'EndAt' | 'meetAt', value: string | number | null) => {
      const key: VoteDates = at === 'meetAt' ? at : `submission${at}`;
      dates.current = {
        ...dates.current,
        [key]: value,
      };
      handleStateChange({ ...onsiteVoteInfo, [key]: value });
    },
    [handleStateChange, onsiteVoteInfo],
  );

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { roadAddress, jibunAddress } = result;

    handleStateChange({
      ...onsiteVoteInfo,
      jibunAddress,
      roadAddress,
    });
  };

  const meetAtMinDate = useMemo(() => {
    const voteEnd = new Date(voteEndAt).getTime();
    const submissionEnd = new Date(dates.current.submissionEndAt).getTime();

    return (voteEnd || 0) > (submissionEnd || 0)
      ? voteEndAt
      : dates.current.submissionEndAt;
  }, [voteEndAt, dates.current.submissionEndAt]);

  if (!meetMethod.includes('ONSITE')) return;

  return (
    <>
      <Typography level="title-md">현장 투표 정보</Typography>

      <Stack gap={1}>
        <Typography level="body-md">서면 제출 시작일시*</Typography>
        <Stack flexDirection="row" gap={1}>
          <DateTimePicker
            name="submissionStartAt"
            maxDateTime={dates.current.submissionEndAt}
            value={dates.current.submissionStartAt}
            onChange={(value) => handleChangeDate('StartAt', value)}
          />
          {/* <DatePicker
            color="neutral"
            onChange={handleOnDateChange('date', 'StartAt')}
          />
          <TimePicker
            color="neutral"
            onChange={handleOnDateChange('time', 'StartAt')}
          /> */}
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography level="body-md">서면 제출 마감일시*</Typography>
        <Stack flexDirection="row" gap={1}>
          <DateTimePicker
            name="submissionEndAt"
            minDateTime={dates.current.submissionStartAt}
            maxDateTime={dates.current.meetAt}
            value={dates.current.submissionEndAt}
            onChange={(value) => handleChangeDate('EndAt', value)}
          />
          {/* <DatePicker
            color="neutral"
            onChange={handleOnDateChange('date', 'EndAt')}
          />
          <TimePicker
            color="neutral"
            onChange={handleOnDateChange('time', 'EndAt')}
          /> */}
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography level="body-md">총회일시*</Typography>
        <Stack flexDirection="row" gap={1}>
          {/* <DatePicker
            color="neutral"
            onChange={handleOnDateChange('date', 'meetAt')}
          />
          <TimePicker
            color="neutral"
            onChange={handleOnDateChange('time', 'meetAt')}
          /> */}
          <DateTimePicker
            name="meetAt"
            value={dates.current.meetAt}
            minDateTime={meetAtMinDate}
            onChange={(value) => handleChangeDate('meetAt', value)}
          />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography level="body-md">총회장소*</Typography>

        <Stack
          flexDirection="row"
          gap={1}
          sx={{ width: 512, flexWrap: 'wrap' }}
        >
          <Stack
            key="address"
            flexDirection="row"
            alignItems="flex-end"
            width="100%"
            gap={1}
          >
            <Input
              variant="soft"
              name="roadAddress"
              placeholder="주소"
              value={onsiteVoteInfo.roadAddress}
              onChange={handleVoteInfoChange}
              startDecorator={<SearchIcon />}
              readOnly
              fullWidth
            />

            <AddressModalTrigger onComplete={handleChangeAddress}>
              <Button color="neutral" variant="outlined" sx={{ width: '80px' }}>
                검색
              </Button>
            </AddressModalTrigger>
          </Stack>

          <Input
            placeholder="상세주소 입력"
            name="detailAddress"
            value={onsiteVoteInfo.detailAddress}
            onChange={handleVoteInfoChange}
            fullWidth
          />
        </Stack>
      </Stack>
    </>
  );
};

export default OnsiteRadio;
