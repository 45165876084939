import { yupResolver } from '@hookform/resolvers/yup';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Callout,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  ModalProps,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  useUnionRegisterAgentDeleteMutation,
  useUnionRegisterAgentQuery,
  useUnionRegisterAgentUpdateMutation,
} from 'apis/union/register-agent/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useFeedback from 'hooks/useFeedback';
import { useUnionRegistersQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { deepFind } from 'utils/common';

const schema = yup.object().shape({
  unionRegisterSeq: yup.number().required(),
  name: yup.string().max(30).required('이름을 입력해 주세요.'),
  nameClass: yup.string().max(10),
  birth: yup
    .string()
    .matches(/^(|\d{8})$/, '생년월일을 확인해 주세요.')
    .required('생년월일을 입력해 주세요.'),
  gender: yup.string().required(),
  relationType: yup.string().required(),
  relationDescription: yup.string().when('relationType', {
    is: (value: string) => value === 'ETC',
    then: (schema) => schema.required('관계 직접입력을 확인해 주세요.'),
    otherwise: (schema) => schema.nullable(),
  }),
  phoneNo: yup
    .string()
    .matches(/^(\d{9,11})$/, '연락처를 확인해 주세요.')
    .required('연락처를 입력해 주세요.'),
});

export interface UnionRegisterAgentModifyFieldValues {
  unionRegisterSeq: number;
  name: string;
  nameClass?: string;
  birth?: string;
  gender: string;
  relationType: string; // UNION_REGISTER_USER_DIVIDE
  relationDescription?: string;
  phoneNo: string;
}

export interface UnionRegisterAgentModifyParams {
  // Path
  unionSeq: number;
  unionRegisterAgentSeq: number;
}

export interface UnionRegisterAgentModifyModalProps
  extends Omit<ModalProps, 'onSubmit' | 'onClose' | 'children'> {
  params: UnionRegisterAgentModifyParams;
  onClose: (value: boolean) => void;
}

const UnionRegisterAgentModifyModal = (
  props: UnionRegisterAgentModifyModalProps,
) => {
  const { snackbar, alertDialog, confirmDialog } = useFeedback();
  const { params, onClose, ...rest } = props;
  const { unionSeq, unionRegisterAgentSeq } = params;
  const {
    data: unionRegistersReturnData,
    isPending: isPendingUnionRegisters,
    isLoading: isLoadingUnionRegisters,
  } = useUnionRegistersQuery(unionSeq);
  const {
    data: unionRegisterAgentReturnData,
    isPending: isPendingRegisterAgent,
    isLoading: isLoadingRegisterAgent,
  } = useUnionRegisterAgentQuery({
    unionSeq,
    unionRegisterAgentSeq,
  });

  const { getGroupCode } = useCommonCodes();

  const agentRelationCodeGroup = useMemo(
    () => getGroupCode('AGENT_RELATION_TYPE'),
    [getGroupCode],
  );

  const { mutate: updateMutation, isPending: isPendingUpadte } =
    useUnionRegisterAgentUpdateMutation();
  const { mutate: deleteMutation, isPending: isPendingDelete } =
    useUnionRegisterAgentDeleteMutation();

  const isLoading = useMemo(
    () =>
      isPendingUnionRegisters ||
      isLoadingUnionRegisters ||
      isPendingRegisterAgent ||
      isLoadingRegisterAgent,
    [
      isLoadingRegisterAgent,
      isLoadingUnionRegisters,
      isPendingRegisterAgent,
      isPendingUnionRegisters,
    ],
  );

  const unionRegisters = useMemo(
    () =>
      unionRegistersReturnData?.data.filter(
        ({ name, unionRegisterNo }) => name || unionRegisterNo,
      ) || [],
    [unionRegistersReturnData?.data],
  );

  const unionRegisterAgent = useMemo(
    () => unionRegisterAgentReturnData?.data,
    [unionRegisterAgentReturnData?.data],
  );

  const defaultValues: UnionRegisterAgentModifyFieldValues = useMemo(
    () => ({
      unionRegisterSeq:
        unionRegisterAgent?.linkedUnionRegister.unionRegisterSeq || 0,
      relationType: unionRegisterAgent?.relationType || '',
      relationDescription: unionRegisterAgent?.relationDescription || '',
      name: unionRegisterAgent?.name?.name || '',
      nameClass: unionRegisterAgent?.name?.nameClass || '',
      birth: unionRegisterAgent?.birth || '',
      phoneNo: unionRegisterAgent?.phoneNo || '',
      gender: unionRegisterAgent?.gender || '',
    }),
    [unionRegisterAgent],
  );

  const { handleSubmit, control, watch, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const unionRegisterSeq = watch('unionRegisterSeq');

  const unionRegisterName = useMemo(() => {
    const value = unionRegisters.find(
      (unionRegister) => unionRegisterSeq === unionRegister?.unionRegisterSeq,
    );

    return value?.name
      ? [
          value.unionRegisterNo ? `[${value.unionRegisterNo}]` : '',
          value.name?.name,
          value.name?.nameClass,
        ].join('')
      : '';
  }, [unionRegisterSeq, unionRegisters]);

  const handleClose = useCallback(() => {
    onClose(false);
    reset();
  }, [onClose, reset]);

  const enhancedHandleSubmit = handleSubmit(
    (data) => {
      const body = {
        unionRegisterSeq: data?.unionRegisterSeq,
        relationType: data.relationType,
        relationDescription: data?.relationDescription,
        name: data.name,
        nameClass: data.nameClass,
        birth: data.birth,
        phoneNo: data.phoneNo,
        gender: data.gender,
      };

      updateMutation(
        { unionSeq, unionRegisterAgentSeq, ...body },
        {
          onSuccess: () => {
            snackbar('저장이 완료되었습니다.', {
              color: 'success',
            });

            handleClose();
          },
          onError: (error) => {
            const message = error.response?.data.message as ReactNode;
            console.log(error);

            snackbar(message, { color: 'danger' });
          },
        },
      );
    },
    (errors) => {
      const message = deepFind('message', errors);
      alertDialog(message, {
        message: '알림',
      });
    },
  );

  const handleDelete = useCallback(() => {
    confirmDialog('대리인을 삭제하시겠습니까?', {
      message: '확인',
      onConfirm: () => {
        deleteMutation(
          { unionSeq, unionRegisterAgentSeq },
          {
            onSuccess: () => {
              snackbar('삭제가 완료되었습니다.');
              handleClose();
            },
            onError: (error) => {
              const message = error.response?.data.message as ReactNode;
              console.log(error);
              snackbar(message, { color: 'danger' });
            },
          },
        );
      },
    });
  }, [
    confirmDialog,
    deleteMutation,
    handleClose,
    snackbar,
    unionRegisterAgentSeq,
    unionSeq,
  ]);

  useEffect(() => {
    if (defaultValues) {
      setTimeout(() => reset(defaultValues), 0);
    }
  }, [defaultValues, reset]);

  return (
    <>
      <Modal onClose={handleClose} {...rest}>
        <ModalDialog
          sx={{ width: '100%' }}
          maxWidth="md"
          component="form"
          onSubmit={enhancedHandleSubmit}
        >
          <DialogTitle>대리인 정보 수정</DialogTitle>

          <DialogContent>
            <Stack gap={2}>
              <Callout
                startDecorator={
                  <Typography
                    startDecorator={<ErrorIcon />}
                    textColor="neutral.500"
                  />
                }
              >
                <Typography>
                  대리인의 정보가 변경되었다면 수정해 주세요.
                </Typography>
                <Typography>
                  대리인은 조합원의 총회 출석권, 발언권, 의결권을 대신할 수
                  있으며, 대리인 삭제 전까지 권한이 유지됩니다.
                </Typography>
              </Callout>
              <Stack gap={2} position="relative">
                <Skeleton loading={isLoading} />

                <TextField
                  placeholder="선택"
                  label="연결조합원"
                  value={unionRegisterName}
                  readOnly
                />

                <Stack>
                  <Typography
                    fontSize="sm"
                    fontWeight="md"
                    lineHeight="sm"
                    mb={0.75}
                  >
                    관계
                  </Typography>

                  <Stack flexDirection="row" gap={2}>
                    <Controller
                      name="relationType"
                      control={control}
                      defaultValue=""
                      render={({ field: relationTypeField }) => (
                        <>
                          <RadioGroup
                            orientation="horizontal"
                            color="neutral"
                            {...relationTypeField}
                          >
                            {agentRelationCodeGroup?.items.map(
                              ({ code, name }) => (
                                <Radio key={code} label={name} value={code} />
                              ),
                            )}
                          </RadioGroup>
                          <Controller
                            name="relationDescription"
                            control={control}
                            defaultValue=""
                            disabled={relationTypeField.value !== 'ETC'}
                            render={({ field }) => (
                              <Input placeholder="직접입력" {...field} />
                            )}
                          />
                        </>
                      )}
                    />
                  </Stack>
                </Stack>

                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      label="대리인 이름"
                      placeholder="이름 입력"
                      slotProps={{
                        input: { maxLength: 30 },
                      }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="nameClass"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      label="이름 구분"
                      placeholder="이름 구분 입력"
                      slotProps={{
                        input: { maxLength: 10 },
                      }}
                      {...field}
                    />
                  )}
                />

                <Stack flexDirection="row" gap={2}>
                  <Controller
                    name="birth"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label="생년월일"
                        placeholder="숫자만 입력"
                        validateOptions={{
                          regex: /^[0-9]*$/,
                          maxLength: 8,
                        }}
                        fullWidth
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup
                        label="성별"
                        orientation="horizontal"
                        color="neutral"
                        {...field}
                      >
                        <Radio label="남" value="M" />
                        <Radio label="여" value="F" />
                      </RadioGroup>
                    )}
                  />
                </Stack>

                <Controller
                  name="phoneNo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      label="연락처"
                      placeholder="숫자만 입력"
                      validateOptions={{
                        maxLength: 11,
                        regex: /^[0-9]*$/,
                      }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="submit" loading={isPendingUpadte}>
              수정
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="neutral"
              sx={{ ml: 'auto' }}
            >
              취소
            </Button>
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="danger"
              sx={{ alignSelf: 'flex-start' }}
              loading={isPendingDelete}
            >
              삭제
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default UnionRegisterAgentModifyModal;
