import {
  Button,
  DataGrid,
  DateRangePicker,
  Grid,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PostsParams, usePostsQuery } from 'apis/post/apis';
import { POST_TABS } from 'apis/post/constants';
import { POST_RECORD_COLUMNS } from 'apis/post/fixtures';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useLayoutContext from 'hooks/useLayoutContext';
import postRecordDetailPage from 'pages/main/union-management/posts/records/[postSeq]';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DEFAULT_SEARCH_VALUES = {
  requestedAtFromTo: '',
  sentAtFromTo: '',
  deliveredAtFromTo: '',
};

const DEFAULT_SEARCH_PARAMS = {
  requestedAtFrom: '',
  requestedAtTo: '',
  sentAtFrom: '',
  sentAtTo: '',
  deliveredAtFrom: '',
  deliveredAtTo: '',
};

interface SearchParamsValues {
  requestedAtFromTo: string;
  sentAtFromTo: string;
  deliveredAtFromTo: string;
}

const PostRecordPage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { unionSeq } = params;
  const { unionBasename } = pageContext || {};

  const [searchParams, setSearchParams] = useState<PostsParams>({
    unionSeq: Number(unionSeq),
    ...DEFAULT_SEARCH_PARAMS,
  });

  const {
    data: records,
    isPending,
    isError,
    error,
  } = usePostsQuery(searchParams);

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'post-records',
    activeSaveSnapshot: true,
  });

  const rows = useMemo(() => records?.data || [], [records]);

  const { columns } = useCreateGridColumns({
    columns: POST_RECORD_COLUMNS,
  });

  const handleSearchParams = (values: SearchParamsValues) => {
    if (!values) {
      return;
    }

    const {
      requestedAtFromTo: [requestedAtFrom, requestedAtTo],
      sentAtFromTo: [sentAtFrom, sentAtTo],
      deliveredAtFromTo: [deliveredAtFrom, deliveredAtTo],
    } = values;

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      requestedAtFrom,
      requestedAtTo,
      sentAtFrom,
      sentAtTo,
      deliveredAtFrom,
      deliveredAtTo,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  if (isError) {
    throw error;
  }

  return (
    <Tabs
      color="neutral"
      selectedTabVariant="plain"
      selectedTabColor="primary"
      tabIndicatorInset
      defaultValue={1}
      value={1}
    >
      <TabList disableUnderline>
        {POST_TABS.map(({ label, path }, index) => (
          <Tab
            key={`tab_${path}`}
            component={Link}
            value={index}
            href={`${unionBasename}${path}`}
          >
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanel value={1}>
        <Stack gap={2}>
          <Stack gap={1.75}>
            <Search
              defaultValues={DEFAULT_SEARCH_VALUES}
              onSubmit={handleSearchParams}
              onReset={handleSearchReset}
            >
              <Grid container gap={2}>
                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="신청일시"
                      name="requestedAtFromTo"
                      sx={{ field: { xs: 12, maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="발송일시"
                      name="sentAtFromTo"
                      sx={{ field: { xs: 12, maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="배달 완료일시"
                      name="deliveredAtFromTo"
                      sx={{ field: { xs: 12, maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>
              </Grid>
            </Search>
          </Stack>

          <Stack gap={2}>
            <Stack flexDirection="row" gap={1}>
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                전체{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalDataCount || 0)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                조회 목록{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalElements || 0)}
                </Typography>
              </Typography>
            </Stack>

            <Stack height={442}>
              <DataGrid
                apiRef={datagridApiRef}
                rows={rows}
                columns={columns}
                loading={isPending}
                disableRowSelectionOnClick
                getRowId={(row) => row.postSeq}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </TabPanel>
    </Tabs>
  );
};

const postRecordPage: CustomRouteObject = {
  path: 'records',
  children: [
    { index: true, element: <PostRecordPage /> },
    postRecordDetailPage,
  ],
  handle: {
    getTitle: () => '내역 관리',
  },
};

export default postRecordPage;
