import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import {
  RouteObject as DefaultRouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { RouteObject } from 'common-providers/providers/ReactRouterProvider/types';

const recursivelyExtendRoutes = (
  routes: RouteObject[],
  queryClient: QueryClient,
): DefaultRouteObject[] => {
  return routes.map(({ loader, children, ...other }) => {
    const extendedRoute = {
      ...other,
      loader: loader?.(queryClient),
    } as DefaultRouteObject;

    if (children) {
      extendedRoute.children = recursivelyExtendRoutes(children, queryClient);
    }

    return extendedRoute;
  });
};

export type ReactRouterProviderProps<T extends RouteObject> =
  PropsWithChildren<{
    routes: T[];
  }>;

const ReactRouterProvider = <T extends RouteObject>({
  routes,
}: ReactRouterProviderProps<T>) => {
  const queryClient = useQueryClient();

  const extendedRoutes = useMemo(
    () => recursivelyExtendRoutes(routes, queryClient),
    [routes, queryClient],
  );
  const router = createBrowserRouter(extendedRoutes, {
    basename: '/',
  });

  return <RouterProvider router={router} />;
};

export default ReactRouterProvider;
