import { Button, Stack } from '@wooriga/design-system';

import { MeetMethods, MeetStatus } from 'apis/types/meet';

interface StatusButtonProps {
  isElectronicVoteEnded: boolean;
  isMock: boolean;
  meetStatus: MeetStatus;
  meetMethod: MeetMethods;

  onReset?: () => void;
}

const StatusButton = ({
  isElectronicVoteEnded,
  isMock,
  meetStatus,
  meetMethod,

  onReset,
}: StatusButtonProps) => {
  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';
  const isOverall = meetMethod === 'ONSITE_ELECTRONIC';

  if (isElectronic && isMock && meetStatus === 'COMPLETE') {
    return <Button type="submit">모의 총회 정식 총회로 전환</Button>;
  }

  if (isElectronic) {
    switch (meetStatus) {
      case 'COUNTING':
        return <Button type="submit">의결선택 완료 및 총회 종료</Button>;

      default:
        return;
    }
  }

  if (isOnsite) {
    switch (meetStatus) {
      case 'PROCESS':
        return <Button type="submit"> 개표 결과 입력하기</Button>;

      case 'COUNTING':
        return (
          <Stack gap={1} flexDirection="row">
            <Button fullWidth onClick={() => onReset?.()}>
              개표 결과 재입력
            </Button>
            <Button name="complete" fullWidth type="submit">
              의결선택 완료 및 총회 종료
            </Button>
          </Stack>
        );
      default:
    }
  }

  if (isOverall) {
    switch (meetStatus) {
      case 'PROCESS': {
        if (isElectronicVoteEnded) {
          return <Button type="submit"> 개표 결과 입력하기</Button>;
        }
        return;
      }

      case 'COUNTING':
        return (
          <Stack gap={1} flexDirection="row">
            <Button fullWidth onClick={() => onReset?.()}>
              개표 결과 재입력
            </Button>
            <Button name="complete" fullWidth type="submit">
              의결선택 완료 및 총회 종료
            </Button>
          </Stack>
        );
      default:
        return;
    }
  }
};

export default StatusButton;
