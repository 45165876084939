// import * as Sentry from '@sentry/react';
import { convertObject2QueryString } from '@wooriga/common-utils';
import axios, { InternalAxiosRequestConfig, isAxiosError } from 'axios';

import { useBoundStore } from 'stores';
import type { ApiError, ApiResponse } from 'types/api';
import { getCookie, removeCookie } from 'utils/cookie';

// const refreshCnt = 0;
// const isRefreshing = false;
// let refreshSubscribers: ((newToken: Token) => void)[] = [];

// const onAccessTokenRefreshed = (newToken: Token) => {
//   refreshSubscribers.forEach((callback) => callback(newToken));
//   refreshSubscribers = [];
// };

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

const axiosInstance = axios.create({
  withCredentials: true,
  paramsSerializer: convertObject2QueryString,
  headers: {
    'Content-Type': 'Application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getCookie('accessToken');

    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: ApiResponse<unknown, boolean>) => response,
  async (error) => {
    const { config, response } = error;

    if (isAxiosError<ApiError>(error)) {
      const originalRequest: InternalAxiosRequestConfig | undefined = config;

      if (originalRequest && response) {
        if (response.status === 429 && response.data.code === 'E00017') {
          useBoundStore
            .getState()
            .snackbar(response.data.message, { color: 'danger' });
          return new Promise(() => {});
        }

        switch (response.data.code) {
          case 'E00500':
          case 'E00505':
          case 'E00506':
            useBoundStore
              .getState()
              .snackbar('세션이 만료되었습니다. 다시 로그인 해주세요.', {
                color: 'danger',
              });
            removeCookie('accessToken', {
              secure: true,
              sameSite: 'strict',
            });
            break;

          // case 'E00502':
          case 'E00503':
          case 'E00504':
            removeCookie('accessToken', {
              secure: true,
              sameSite: 'strict',
            });
            break;
        }
      }

      // if (isAxiosError(error) && error.response && error.config) {
      //   const { config, response } = error;

      //   const { method, url, params, data: configData, headers } = config;
      //   const { data, status, statusText } = response;

      //   if (status !== 401 && status !== 403) {
      //     Sentry.withScope((scope) => {
      //       scope.setContext('API Request Detail', {
      //         method,
      //         url,
      //         params,
      //         headers,
      //         data: configData,
      //       });
      //       scope.setContext('API Response Detail', { data, status, statusText });

      //       scope.setFingerprint([
      //         method ?? '',
      //         url ?? '',
      //         String(status),
      //         String(data.errorCode),
      //       ]);

      //       Sentry.captureException(error);
      //     });
      //   }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
