import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CheckOption,
  Checkbox,
  CheckboxGroup,
  DateTimePicker,
  FormControl,
  FormHelperText,
  FormLabel,
  PanelCloseButton,
  PanelHeader,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  CounselCreateParams,
  useCounselCreateMutation,
} from 'apis/counsel/apis';
import { useUnionRegisterQuery } from 'apis/union/register/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterAgentDescriptions from 'components/pages/common/UnionRegisterAgentDescriptions';
import UnionRegisterDescriptions from 'components/pages/common/UnionRegisterDescriptions';
import BeforeCounselPannel from 'components/pages/counsels/BeforeCounselPannel';
import SidePanel from 'components/SidePanel';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { formatDateTime } from 'utils/format';

const schema = yup.object({
  method: yup.string().required('상담방식을 선택해 주세요.'),
  memo: yup.string().required('상담내용을 입력해 주세요.'),
  counselAt: yup.string().required('상담일시를 입력해 주세요.'),
  tendency: yup.string().required(),
  topics: yup
    .array()
    .of(yup.string().required())
    .min(1, '상담주제를 최소 한 개 이상 선택해 주세요.')
    .required('상담주제를 최소 한 개 이상 선택해 주세요.'),
  votingWrittenType: yup.string().required(),
  meetAttendType: yup.string().required(),
});

type CounselFormValues = yup.InferType<typeof schema>;

const CounselCreateInputPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const unionRegisterSeq = Number(params.unionRegisterSeq);

  const { getGroupCode } = useCommonCodes();

  const {
    counselMethodCodeGroup,
    tendencyCodeGroup,
    counselTopicCodeGroup,
    votingWrittenCodeGroup,
    attendExpectedCodeGroup,
  } = useMemo(
    () => ({
      counselMethodCodeGroup: getGroupCode('COUNSEL_METHOD_TYPE'),
      tendencyCodeGroup: getGroupCode('TENDENCY_TYPE'),
      counselTopicCodeGroup: getGroupCode('COUNSEL_TOPIC_TYPE'),
      votingWrittenCodeGroup: getGroupCode('VOTING_WRITTEN_TYPE'),
      attendExpectedCodeGroup: getGroupCode('ATTEND_TYPE'),
    }),
    [getGroupCode],
  );

  const navigate = useNavigate();

  const { snackbar, confirmDialog } = useFeedback();

  const {
    data: unionRegisterInfoReturnData,
    isError,
    error,
  } = useUnionRegisterQuery({
    unionSeq,
    unionRegisterSeq,
  });

  const unionRegisterInfo = unionRegisterInfoReturnData?.data;
  const unionRegisterAgentInfo = unionRegisterInfo?.agent;

  const { mutate: registerCounsel } = useCounselCreateMutation();

  const [open, setOpen] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<CounselFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      method: 'VISIT',
      memo: '',
      counselAt: '',
      tendency: 'UNDETERMINED',
      topics: [],
      votingWrittenType: 'NOT_APPLICABLE',
      meetAttendType: 'NOT_APPLICABLE',
    },
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleBackToParentPage = useCallback(() => {
    if (isDirty) {
      confirmDialog('작성된 내용이 사라질 수 있습니다.', {
        onConfirm: () => navigate('..', { relative: 'path' }),
      });
      return;
    }

    navigate('..', { relative: 'path' });
  }, [confirmDialog, navigate, isDirty]);

  const enhancedHandleSubmit = handleSubmit((data) => {
    const formData: CounselCreateParams = {
      ...data,
      unionSeq,
      unionRegisterSeq,
      serviceType: 'OMS',
    };

    registerCounsel(formData, {
      onSuccess: () => {
        navigate('..');
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  });

  if (isError) throw error;

  return (
    <>
      {open && (
        <SidePanel open={open} onClose={handleClose}>
          <PanelHeader>이전 상담 내역</PanelHeader>
          <BeforeCounselPannel
            unionSeq={unionSeq}
            unionRegisterNo={unionRegisterSeq}
          />
          <PanelCloseButton />
        </SidePanel>
      )}

      <Stack gap={2}>
        <Stack component="form" onSubmit={enhancedHandleSubmit} gap={2}>
          <Stack gap={5}>
            <Stack gap={3}>
              <UnionRegisterDescriptions data={unionRegisterInfo} />
              <UnionRegisterAgentDescriptions data={unionRegisterAgentInfo} />
            </Stack>

            <Stack gap={1}>
              <Typography
                level="h3"
                fontSize="lg"
                fontWeight="xl"
                lineHeight="xl"
              >
                상담 등록
              </Typography>

              <Stack gap={3}>
                <Stack flexDirection="row" gap={2}>
                  <Stack>
                    <FormControl error={!!errors.method}>
                      <FormLabel>상담 방식</FormLabel>
                      <Controller
                        control={control}
                        name="method"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            placeholder="선택"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {counselMethodCodeGroup?.items &&
                              counselMethodCodeGroup.items.map(
                                (counselMethodCode) => (
                                  <CheckOption
                                    key={`method-code-${counselMethodCode.code}`}
                                    value={counselMethodCode.code}
                                  >
                                    {counselMethodCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                      <FormHelperText>{errors.method?.message}</FormHelperText>
                    </FormControl>
                  </Stack>
                  <Stack>
                    <FormControl error={!!errors.counselAt}>
                      <Controller
                        control={control}
                        name="counselAt"
                        render={({ field: { value, onChange, ...other } }) => (
                          <DateTimePicker
                            {...other}
                            size="md"
                            color="neutral"
                            label="상담 일시"
                            disableFuture
                            value={value}
                            onChange={(newValue) =>
                              onChange(
                                formatDateTime(
                                  newValue ?? '',
                                  "yyyy-MM-dd'T'HH:mm:ss",
                                ),
                              )
                            }
                            fullWidth
                          />
                        )}
                      />
                      <FormHelperText>
                        {errors.counselAt?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack flexDirection="row" gap={2}>
                  <Stack>
                    <FormControl>
                      <FormLabel>우호도</FormLabel>
                      <Controller
                        control={control}
                        name="tendency"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            placeholder="선택"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {tendencyCodeGroup?.items &&
                              tendencyCodeGroup.items.map((tendencyCode) => (
                                <CheckOption
                                  key={`tendency-code-${tendencyCode.code}`}
                                  value={tendencyCode.code}
                                >
                                  {tendencyCode.name}
                                </CheckOption>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <FormControl>
                      <FormLabel>투표/서면</FormLabel>

                      <Controller
                        control={control}
                        name="votingWrittenType"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            placeholder="선택"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {votingWrittenCodeGroup?.items &&
                              votingWrittenCodeGroup.items.map(
                                (votingWrittenCode) => (
                                  <CheckOption
                                    key={`voting-written-code-${votingWrittenCode.code}`}
                                    value={votingWrittenCode.code}
                                  >
                                    {votingWrittenCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <FormControl>
                      <FormLabel>참석 유형</FormLabel>

                      <Controller
                        control={control}
                        name="meetAttendType"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            placeholder="선택"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {attendExpectedCodeGroup?.items &&
                              attendExpectedCodeGroup.items.map(
                                (votingWrittenCode) => (
                                  <CheckOption
                                    key={`attend-expected-code-${votingWrittenCode.code}`}
                                    value={votingWrittenCode.code}
                                  >
                                    {votingWrittenCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack>
                  <Controller
                    control={control}
                    name="topics"
                    render={({ field }) => (
                      <CheckboxGroup
                        {...field}
                        label="상담 주제"
                        orientation="horizontal"
                        onChange={(values) => field.onChange(values)}
                        error={!!errors.topics}
                        helperText={errors.topics?.message}
                      >
                        {counselTopicCodeGroup?.items &&
                          counselTopicCodeGroup.items.map(
                            (votingWrittenCode) => (
                              <Checkbox
                                key={`attend-expected-code-${votingWrittenCode.code}`}
                                value={votingWrittenCode.code}
                                label={votingWrittenCode.name}
                              />
                            ),
                          )}
                      </CheckboxGroup>
                    )}
                  />
                </Stack>

                <Stack>
                  <FormControl error={!!errors.memo}>
                    <FormLabel>상담 내용</FormLabel>
                    <Controller
                      control={control}
                      name="memo"
                      render={({ field, fieldState }) => (
                        <Textarea
                          {...field}
                          variant="outlined"
                          color="neutral"
                          size="md"
                          placeholder="내용 입력"
                          error={fieldState.invalid}
                          sx={{
                            height: '442px',
                          }}
                        />
                      )}
                    />
                    <FormHelperText>{errors.memo?.message}</FormHelperText>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Stack flexDirection="row" justifyContent="space-between">
            <Button
              size="lg"
              variant="outlined"
              color="neutral"
              onClick={handleBackToParentPage}
            >
              목록
            </Button>

            <Stack flexDirection="row" gap={1}>
              <Button size="lg" variant="outlined" onClick={handleOpen}>
                이전 상담 내역
              </Button>

              <Button size="lg" type="submit">
                상담 등록
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const counselCreateInputPage: CustomRouteObject = {
  path: ':unionRegisterSeq',
  element: <CounselCreateInputPage />,
};

export default counselCreateInputPage;
