import {
  Box,
  Button,
  DataGrid,
  Grid,
  Link,
  Pagination,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useRegistrationChangeHistoriesQuery } from 'apis/union/register/registration-chages/apis';
import { REGISTRATION_CHANGES_GRID_COLUMNS } from 'apis/union/register/registration-chages/fixtures';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useQueryControls from 'hooks/useQueryControls';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultSearchParams = {
  registrationNo: '',
  registrationPurpose: '',
  // receiptStep: '',
};

export interface UnionMembersParams {
  registrationNo: string;
  registrationPurpose: string;
}

const RegistersHistoriesPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  unionSeq;

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'registers-histories',
    activeSaveSnapshot: true,
  });

  const {
    searchState,
    setSearchState,
    paginationState,
    setPaginationState,
    initState,
  } = useQueryControls({
    defaultSearchParams,
    initSearchParams: defaultSearchParams,
  });

  const searchParams = useMemo(() => {
    const { ...other } = searchState;
    const params = { ...other };
    return params;
  }, [searchState]);

  const handleSearchSubmit = useCallback(
    (data: UnionMembersParams) => setSearchState(data),
    [setSearchState],
  );

  const handleSearchReset = useCallback(() => {
    initState();
  }, [initState]);

  const registrationChangeHistories = useRegistrationChangeHistoriesQuery({
    unionSeq,
    ...searchParams,
  });

  const rows = useMemo(
    () => registrationChangeHistories.data?.data || [],
    [registrationChangeHistories.data?.data],
  );
  const { columns } = useCreateGridColumns({
    columns: REGISTRATION_CHANGES_GRID_COLUMNS,
  });

  const totalElements =
    registrationChangeHistories.data?.pagination?.totalElements || 0;
  const totalDataCount =
    registrationChangeHistories.data?.pagination?.totalDataCount || 0;
  const count = Math.ceil(totalElements / 10);

  return (
    <Stack gap={2}>
      <Typography fontSize="lg" fontWeight="xl">
        변동 이력
      </Typography>
      <Stack gap={1.75}>
        <Search
          values={searchParams}
          onSubmit={handleSearchSubmit}
          onReset={handleSearchReset}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  name="registrationNo"
                  label="등기소 등록번호"
                  placeholder='숫자 또는 "-"입력'
                  validateOptions={{
                    maxLength: 11,
                    regex: /^(?!.*--)[0-9-]*$/,
                  }}
                  fullWidth
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  name="registrationPurpose"
                  label="등기 목적"
                  placeholder="등기 목적 입력"
                  slotProps={{
                    input: { maxLength: 30 },
                  }}
                  fullWidth
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search>
      </Stack>
      <Stack
        flexDirection="row-reverse"
        flexWrap="wrap"
        justifyContent="start"
        gap={1}
      >
        <Stack flexDirection="row" gap={2} alignSelf="flex-end">
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Box>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.changeHistorySeq}
            loading={
              registrationChangeHistories.isLoading ||
              registrationChangeHistories.isPending
            }
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationState}
            onPaginationModelChange={setPaginationState}
          />
        </Box>
        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={count}
            page={paginationState.page + 1}
            onChange={(_, page) => {
              page &&
                setPaginationState({
                  page: page - 1,
                  pageSize: paginationState.pageSize,
                });
            }}
          />
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <Button variant="outlined" color="neutral" component={Link} href="..">
            목록
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => exportExcel()}
          >
            내역 다운
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const registersHistoriesPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/registers/histories',
  children: [{ index: true, element: <RegistersHistoriesPage /> }],
  handle: {
    getTitle: () => '조합원 명부 변동 이력',
  },
};

export default registersHistoriesPage;
