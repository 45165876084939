import {
  // DefinedUseQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
// import { generateInitRealData } from 'lim/_api';
import { UnionRegisterInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

import { UnionRegisterGroupResponse } from '../apis';

// import {
//   unionRegisterGroupData,
//   // unionRegisterGroupRegistersData,
// } from './json';

export type UnionRegisterGroupResponseQuery = UseQueryResult<
  ApiResponseData<UnionRegisterGroupResponse>,
  AxiosError
>;

export const useUnionRegisterGroupDetailQuery = (
  unionSeq: number,
  unionRegisterGroupSeq: number,
): UnionRegisterGroupResponseQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/register-groups/${unionRegisterGroupSeq}`],
    // initialData: generateInitRealData(
    //   unionRegisterGroupData(unionRegisterGroupSeq),
    // ),
  });

export type UnionRegisterGroupRegistersQuery = UseQueryResult<
  ApiResponseData<UnionRegisterInfo[]>,
  AxiosError
>;

export interface UnionRegisterGroupRegistersParams {
  name?: string;
  unionRegisterNo?: string;
  phoneNo?: string;
  shareType?: string;
  localAddress?: string;
  postAddress?: string;
  positionDescription?: string;
}

// 조합원 그룩에 속한 조합원 리스트

export const useUnionRegisterGroupRegistersQuery = (
  unionSeq: number,
  unionRegisterGroupSeq: number,
  params?: UnionRegisterGroupRegistersParams,
): UnionRegisterGroupRegistersQuery =>
  useQuery({
    queryKey: [
      `/unions/${unionSeq}/register-groups/${unionRegisterGroupSeq}/registers`,
      params,
    ],
    // initialData: generateInitRealData(unionRegisterGroupRegistersData),
  });

export type UnionRegistersParams = Partial<{
  unionRegisterNo: string;
  name: string;
  phoneNo: string;
  localAddress: string;
  realAddress: string;
}>;

export const useUnionRegisterAllQuery = (
  unionSeq: number,
  params?: UnionRegistersParams,
): UnionRegisterGroupRegistersQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/registers/all`, params],
    // initialData: generateInitRealData(unionRegisterGroupRegistersData),
  });

export interface RegisterGroupBody {
  name: string;
  memo: string;
  unionRegisterSeqs: number[];
}

export type RegisterGroupMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  RegisterGroupBody
>;

export const useRegisterGroupMutation = (
  unionSeq: number,
): RegisterGroupMutation =>
  useMutation({
    mutationFn: async (formData: RegisterGroupBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/unions/${unionSeq}/register-groups`,
        formData,
      );
      return data;
    },
  });

export type UpdateRegisterGroupMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  RegisterGroupBody
>;

export const useUpdateRegisterGroupMutation = (
  unionSeq: number,
  unionRegisterGroupSeq: number,
): UpdateRegisterGroupMutation =>
  useMutation({
    mutationFn: async (formData: RegisterGroupBody) => {
      const { data } = await axiosInstance.put<ApiResponseData>(
        `/unions/${unionSeq}/register-groups/${unionRegisterGroupSeq}`,
        formData,
      );
      return data;
    },
  });
