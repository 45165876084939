import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import addressGroupsPage from 'pages/main/union-management/address-groups';
import agreementPage from 'pages/main/union-management/agreements';
import archivesPage from 'pages/main/union-management/archives';
import documentIssuancesPage from 'pages/main/union-management/document-issuances';
import open from 'pages/main/union-management/electronic-posts';
import formsPage from 'pages/main/union-management/forms';
import messagesPage from 'pages/main/union-management/messages';
import postsPage from 'pages/main/union-management/posts';
import registersPage from 'pages/main/union-management/registers';
import { CustomRouteObject } from 'types/route';

const unionManagementPage: CustomRouteObject = {
  handle: {
    getTitle: () => '조합업무 관리',
    getIcon: () => <FolderOutlinedIcon />,
    getMenuCode: () => 'M04',
  },
  children: [
    registersPage,
    agreementPage,
    messagesPage,
    postsPage,
    documentIssuancesPage,
    formsPage,
    archivesPage,
    addressGroupsPage,
    open,
  ],
};

export default unionManagementPage;
