import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { ApiError, ApiResponseData } from 'types/api';

export interface ReadLiveStreamRequest {
  // Path
  meetSeq: number;
}

export type ReadLiveStreamResponse = ApiResponseData<{
  url: string;
}>;

export const useReadLiveStream = ({
  meetSeq,
}: ReadLiveStreamRequest): UseQueryResult<ReadLiveStreamResponse, ApiError> =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/live-stream`],
  });

export interface UpdateLiveStreamRequest {
  meetSeq: number;
  url: string;
}

export type UpdateLiveStreamResponse = ApiResponseData;

export const useUpdateLiveStream = (): UseMutationResult<
  UpdateLiveStreamResponse,
  ApiError,
  UpdateLiveStreamRequest
> =>
  useMutation({
    mutationFn: async ({ meetSeq, url }) => {
      const { data } = await axiosInstance.put<ApiResponseData>(
        `/meets/${meetSeq}/live-stream`,
        { url },
      );

      return data;
    },
  });

export interface DeleteLiveStreamRequest {
  meetSeq: number;
}

export type DeleteLiveStreamResponse = ApiResponseData;

export const useDeleteLiveStream = (): UseMutationResult<
  DeleteLiveStreamResponse,
  ApiError,
  DeleteLiveStreamRequest
> =>
  useMutation({
    mutationFn: async ({ meetSeq }) => {
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/meets/${meetSeq}/live-stream`,
      );

      return data;
    },
  });
