import { UseMutationResult, useMutation } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { MeetRequest } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export type CreateMeetMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  MeetRequest
>;

export const useCreateMeetMutation = (): CreateMeetMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        '/meets',
        formData,
      );
      return data;
    },
  });

export interface CalculateCostBody {
  unionSeq: number;
  meetMethod: string;
  unionRegisterSeqs: number[];
}

export interface CalculateCostResponse {
  totalCost: number;
}

export type CalculateCostMutation = UseMutationResult<
  ApiResponseData<CalculateCostResponse>,
  ApiError,
  CalculateCostBody
>;

export const useCalculateCostMutation = (): CalculateCostMutation =>
  useMutation({
    mutationFn: async (formData: CalculateCostBody) => {
      const { data } = await axiosInstance.post<
        ApiResponseData<CalculateCostResponse>
      >('/meets/cost/calculate', formData);

      return data;
    },
  });
