import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientConfig } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { PropsWithChildren, useState } from 'react';

export interface ReactQueryProviderProps extends PropsWithChildren {
  config?: QueryClientConfig;
}

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'REACT_QUERY_STORAGE',
});

const ReactQueryProvider = ({ config, children }: ReactQueryProviderProps) => {
  const [queryClient] = useState(new QueryClient(config));

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            const isPersist = !!query.meta?.persist,
              hasData = !!query.state.data;
            return isPersist && hasData;
          },
        },
      }}
    >
      {children}
    </PersistQueryClientProvider>
  );
};

export default ReactQueryProvider;
