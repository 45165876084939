import {
  Button,
  DateTimePicker,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  ElectronicVoteBase,
  MeetMethods,
  SignatureType,
} from 'apis/types/meet';
import { getCookie } from 'utils/cookie';

import MessagePreviewModal from '../modals/MessagePreviewModal';

type VoteDates = 'voteStartAt' | 'voteEndAt';

interface ElectronicRadioProps {
  defaultData?: ElectronicVoteBase;
  meetMethod: MeetMethods;

  meetName: string;
  meetAt: string;

  onChange: (formData: ElectronicVoteBase) => void;
}

const ElectronicRadio = ({
  defaultData,
  meetName,

  meetMethod,
  meetAt,

  onChange,
}: ElectronicRadioProps) => {
  const dates = useRef({
    voteStartAt: '',
    voteEndAt: '',
  });
  // const times = useRef({
  //   voteStartAt: '',
  //   voteEndAt: '',
  // });

  const [electronicVoteInfo, setElectronicVoteInfo] =
    useState<ElectronicVoteBase>({
      signatureType: 'ELECTRONIC',
      senderName: '',
      contactNo: '',
      description: '',
      voteStartAt: '',
      voteEndAt: '',
    });

  const [unionName, setUnionName] = useState('');
  const [showPreviewMessageInfo, setShowPreviewMessageInfo] = useState(false);

  const handleStateChange = useCallback(
    (value: ElectronicVoteBase) => {
      setElectronicVoteInfo(value);
      onChange(value);
    },
    [onChange],
  );

  const handleVoteInfoChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    const newValue = {
      ...electronicVoteInfo,
      [name]: value,
    };

    handleStateChange(newValue);
  };

  const handlePreview = async () => {
    const unionName = await getCookie('unionName');

    setUnionName(unionName);
    setShowPreviewMessageInfo(true);
  };

  const handleRadioChange = ({ target }: { target: EventTarget }) => {
    const signatureType = (target as HTMLInputElement).value as SignatureType;

    const newValue = {
      ...electronicVoteInfo,
      signatureType,
    };

    handleStateChange(newValue);
  };

  const handleChangeDate = useCallback(
    (at: 'StartAt' | 'EndAt', value: string | number | null) => {
      const key: VoteDates = `vote${at}`;
      dates.current = {
        ...dates.current,
        [key]: value,
      };
      handleStateChange({ ...electronicVoteInfo, [key]: value });
    },
    [electronicVoteInfo, handleStateChange],
  );

  useEffect(() => {
    if (defaultData) {
      setElectronicVoteInfo(defaultData);
    }
  }, [defaultData]);

  if (!meetMethod.includes('ELECTRONIC')) return;

  return (
    <>
      <Stack flexDirection="row" alignItems="center" gap={3}>
        <Typography level="title-md">전자투표 정보</Typography>
        <Button variant="outlined" onClick={handlePreview}>
          발송 메세지 미리보기
        </Button>
      </Stack>
      <Stack gap={1}>
        <Typography level="body-md">간편인증 전자서명 진행여부</Typography>

        <RadioGroup
          name="signatureType"
          orientation="horizontal"
          value={electronicVoteInfo.signatureType}
          onChange={handleRadioChange}
        >
          <Radio label="전자서명 진행" value="ELECTRONIC" />
          <Radio label="전자서명 안함" value="HOLOGRAPHIC" />
        </RadioGroup>
      </Stack>
      <Stack gap={1}>
        <Typography level="body-md">전자투표 시작일시*</Typography>
        <Stack flexDirection="row" gap={1}>
          <DateTimePicker
            name="voteStartAt"
            maxDateTime={dates.current.voteEndAt}
            value={dates.current.voteStartAt}
            onChange={(value) => handleChangeDate('StartAt', value)}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography level="body-md">전자투표 종료일시*</Typography>
        <Stack flexDirection="row" gap={1}>
          <DateTimePicker
            name="voteEndAt"
            minDateTime={dates.current.voteStartAt}
            maxDateTime={
              meetMethod === 'ONSITE_ELECTRONIC' && meetAt ? meetAt : ''
            }
            value={dates.current.voteEndAt}
            onChange={(value) => handleChangeDate('EndAt', value)}
          />
          {/* <DatePicker
            color="neutral"
            name="voteEndAt"
            onChange={handleOnDateChange('date', 'EndAt')}
          />
          <TimePicker
            color="neutral"
            onChange={handleOnDateChange('time', 'EndAt')}
          /> */}
        </Stack>
      </Stack>

      <Grid container gap={2} width={832}>
        <Grid xs={5}>
          <FormControl>
            <FormLabel>발송단체명*</FormLabel>
            <Input
              placeholder="발송단체명 입력"
              name="senderName"
              value={electronicVoteInfo.senderName}
              onChange={handleVoteInfoChange}
            />
          </FormControl>
        </Grid>

        <Grid xs={5}>
          <FormControl>
            <FormLabel>문의 전화번호*</FormLabel>
            <Input
              placeholder="문의 전화번호 입력"
              name="contactNo"
              value={electronicVoteInfo.contactNo}
              onChange={handleVoteInfoChange}
              validateOptions={{
                regex: /^[0-9\s]*$/,
                maxLength: 11,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <FormControl>
        <FormLabel>투표설명*</FormLabel>
        <Textarea
          placeholder="발송하실 전자투표에 대한 설명을 작성하세요. 
작성하신 내용은 수신자의 메시지 상단에 표시됩니다.
수신자의 열람률과 투표율을 높일 수 있습니다.
                "
          name="description"
          value={electronicVoteInfo.description}
          onChange={handleVoteInfoChange}
          validateOptions={{
            maxLength: 200,
          }}
        />
      </FormControl>

      <MessagePreviewModal
        info={{ ...electronicVoteInfo, meetName, unionName }}
        open={showPreviewMessageInfo}
        onClose={setShowPreviewMessageInfo}
      />
    </>
  );
};

export default ElectronicRadio;
