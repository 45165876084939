import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  DataGrid,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MockMeetProps } from 'apis/types/meet';
import { UNION_REGISTER_TABLE_COLUMNS } from 'apis/union/register/fixtures/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import {
  useMeetsAgendasQuery,
  useMeetsDetailQuery,
  useMeetsParticipantsQuery,
  useUnionRegistersQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { commaizeNumber } from 'utils/format';

interface VoterEditModalProps {
  open: boolean;
  onClose: (open: false) => void;
}

const VoterEditModal = ({ open, onClose }: VoterEditModalProps) => {
  const { unionSeq, meetSeq } = useParams();
  const navigate = useNavigate();

  const { alertDialog, confirmDialog } = useFeedback();

  const unionId = Number(unionSeq);
  const meetId = Number(meetSeq);

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [unionRegisterSeq, selectedUnionRegisterSeq] = useState(0);

  const { data: currentParticipantData } = useMeetsParticipantsQuery(meetId);
  const { data, isLoading, isError, error } = useUnionRegistersQuery(unionId);
  const { data: meetInfoData } = useMeetsDetailQuery(meetId);
  const { data: agendaData } = useMeetsAgendasQuery(meetId);

  const rows = useMemo(() => data?.data || [], [data?.data]);

  const handleOnRowSelect = <Model,>(ids: Model) => {
    setSelectedIds(ids as number[]);
  };

  const handleSubmit = () => {
    if (!selectedIds.length) {
      return alertDialog('선택된 조합원이 없습니다.', {
        color: 'danger',
      });
    }

    confirmDialog(
      <>
        <Typography component="p" display="block">
          총 선거인 {selectedIds.length}명을 선택하셨습니다.
        </Typography>
        <Typography component="p" display="block">
          이대로 선거인 명부를 확정하고 총회 정보를 입력하시겠습니까?
        </Typography>
      </>,
      {
        color: 'success',
        onConfirm: () => {
          navigate(`/unions/${unionId}/meet-management/open/agenda`, {
            state: {
              ids: selectedIds,
              openType: meetInfoData?.data.openType,
              isMock: false,
              mockMeetData: generateMockMeetData(),
            },
          });
        },
      },
    );
  };

  const generateMockMeetData = (): MockMeetProps | null => {
    if (!meetInfoData?.data.electronicVote) return null;
    if (!agendaData?.data) return null;

    const {
      name,
      moverName,
      openType,
      meetType,
      meetMethod,
      noticeFile,
      electronicVote: {
        senderName,
        contactNo,
        description,
        voteEndAt,
        voteStartAt,
        signatureType,
      },
    } = meetInfoData.data;

    const agendas = agendaData.data.map((agenda) => {
      const { agendaSeq, ...reset } = agenda;
      console.log(agendaSeq);
      return reset;
    });

    return {
      name,
      moverName,
      openType,
      meetType,
      meetMethod,
      noticeFile,
      electronicVote: {
        senderName,
        contactNo,
        description,
        voteEndAt,
        voteStartAt,
        signatureType,
      },
      agendas,
    };
  };

  const handleOnNameClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    selectedUnionRegisterSeq(Number(id));
  };

  const { columns } = useCreateGridColumns({
    handlers: {
      onLinkClick: handleOnNameClick,
    },
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  useEffect(() => {
    const checkedData = data?.data
      ?.filter((data) => {
        const isExist = currentParticipantData?.data.some(
          (originRow) => originRow.unionRegisterSeq === data.unionRegisterSeq,
        );

        return isExist;
      })
      .map((data) => data.unionRegisterSeq);

    if (checkedData) setSelectedIds(checkedData);
  }, [data?.data, currentParticipantData?.data, open]);

  if (isError) throw error;

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog>
          <DialogTitle>선거인 명부 재확정하기</DialogTitle>
          <DialogContent>
            <Stack gap={1} marginBottom={3}>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                <span>
                  <strong>
                    정식 총회 전환전 선거인 명부를 재확정 하기위한 기능
                  </strong>{' '}
                  입니다. 다시 한번 확인해 주세요.
                </span>
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                선거인을 불러오시려면 조합원 명부 관리에서 추가하시려는 선거인을
                조합원 명부에 등록해 주세요
              </Typography>

              <Typography
                level="body-md"
                color="danger"
                startDecorator={<InfoIcon />}
              >
                이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력 된 경우
                전자투표의 수신 또는 열람이 불가합니다. 조합원 명부 관리
                페이지의 조합원 정보에서 수정해 주세요.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                전자투표인 경우 대리인을 선임하지 않은 법인의 경우 본인인증이
                불가하여 전자투표 진행이 어려울 수 있습니다.
              </Typography>
            </Stack>

            <Stack gap={2}>
              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  조회 목록{' '}
                  <Typography color="primary">
                    {commaizeNumber(selectedIds.length)}
                  </Typography>
                </Typography>
              </Stack>
              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  getRowId={(row) => row.unionRegisterSeq}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleOnRowSelect}
                  rowSelectionModel={selectedIds}
                  keepNonExistentRowsSelected
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq: unionId, unionRegisterSeq }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

export default VoterEditModal;
