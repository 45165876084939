import { GridColDef } from '@mui/x-data-grid-premium';
import { Button, Link } from '@wooriga/design-system';

import { GridColumnsHandlers } from 'types/grid';
import { formatDateTime, formatTelephone } from 'utils/format';

//todo types에 추가하고 정리하기

export interface Electronic_Posts {
  title: string;
  postsSeq: number;
  recipientCount: number;
  counselAt: string;
  documentStatus: string;
  isSendable: boolean;
}

export interface Recipients {
  electronicDocumentRecipientSeq: number;
  unionRegisterSeq: number;
  name: string;
  birth: string;
  isAgent: boolean;
  sentAt: string;
  receivedAt: string;
  openedAt: string;
  completedAt: string;
  expiredAt: string;
  status: string;
  failReason: string;
}

export interface Electronic_posts_List_ColumnProps {
  onSendDocument: (electronicDocumentSeq: number) => void;
}
export interface Electronic_Posts_Recipients_List_ColumnProps {
  onClickName: (unionRegisterSeq: number) => void;
}

export const ELECTRONIC_POSTS_LIST_GRID_COLUMNS = ({
  getCommonCode,
  onSendDocument,
}: GridColumnsHandlers<Electronic_posts_List_ColumnProps>): GridColDef<Electronic_Posts>[] => [
  {
    field: 'title',
    headerName: '제목',
  },
  {
    field: 'recipientCount',
    headerName: '수신인 수',
    width: 150,
  },
  {
    field: 'sentAt',
    headerName: '발송일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },
  {
    field: 'documentStatus',
    headerName: '문서 상태',
    width: 50,
    valueGetter: (_, row) =>
      (row?.documentStatus &&
        getCommonCode('ELECTRONIC_DOCUMENT_STATUS', row.documentStatus)
          ?.name) ||
      row.documentStatus,
  },

  {
    field: 'send',
    headerName: '',
    width: 100,
    type: 'actions',
    renderCell: (params) => {
      if (params?.row?.isSendable === true) {
        return (
          <Button
            variant="soft"
            size="sm"
            onClick={() => {
              onSendDocument(Number(params.id));
            }}
          >
            발송
          </Button>
        );
      }
    },
  },
  {
    field: 'send2',
    headerName: '',
    width: 100,
    type: 'actions',
    renderCell: (params) => {
      return (
        <Button
          disabled={params?.row?.documentStatus === 'TEMP' ? true : false}
          size="sm"
          variant="soft"
          component={Link}
          href={`detail/${params.id}`}
        >
          상세보기
        </Button>
      );
    },
  },
];

export const ELECTRONIC_POSTS_SEND_LISTS_GRID_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<Electronic_Posts_Recipients_List_ColumnProps>): GridColDef<Recipients>[] => [
  {
    field: 'name',
    headerName: '이름',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => {
            onClickName(params?.row?.unionRegisterSeq || 0);
          }}
        >
          {params?.row?.name || ''}
        </Link>
      );
    },
  },
  {
    field: 'phoneNo',
    headerName: '전화번호',
    width: 150,
    valueGetter: (value) => (value && formatTelephone(value)) || '',
  },
  {
    field: 'sentAt',
    headerName: '발송일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },

  {
    field: 'receivedAt',
    headerName: '수신일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },
  {
    field: 'openedAt',
    headerName: '열람일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },
  {
    field: 'completedAt',
    headerName: '완료일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },
  {
    field: 'expiredAt',
    headerName: '만료일',
    width: 150,
    valueGetter: (value) =>
      (value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss')) || '',
  },
  {
    field: 'status',
    headerName: '상태',
    width: 50,
    valueGetter: (_, row) =>
      (row?.status &&
        getCommonCode('ELECTRONIC_DOCUMENT_RECIPIENT_STATUS', row.status)
          ?.name) ||
      row?.status,
  },
  {
    field: 'failReason',
    headerName: '상태 메시지',
    width: 100,
  },
];
