import InfoIcon from '@mui/icons-material/Info';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { expandData, exportExcel } from '@wooriga/common-utils';
import {
  Stack,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Link,
  DataGrid,
  Modal,
  ModalDialog,
  DialogTitle,
  Callout,
  Checkbox,
  DialogActions,
  useGridUtils,
  Switch,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { UnionRegisterInfoAll } from 'apis/types/union';
import {
  useRegisterStatusQuery,
  useUnionRegistersDeleteMutation,
  useUpdateRegisterStatusMutation,
} from 'apis/union/register/apis';
import { UNION_REGISTERS_GRID_COLUMNS } from 'apis/union/register/fixtures/register';
import {
  useUnionRegistrationTraceActivateMutation,
  useUnionRegistrationTraceDeactivateMutation,
  useUnionRegistrationTraceQuery,
} from 'apis/union/registration-trace/apis';
import UnionRegisterAgentCreateModal, {
  UnionRegisterAgentCreateParams,
} from 'components/pages/registers/modal/UnionRegisterAgentCreateModal';
import UnionRegisterAgentModifyModal, {
  UnionRegisterAgentModifyParams,
} from 'components/pages/registers/modal/UnionRegisterAgentModifyModal';
import RegistrationChangesModal, {
  RegistrationChangesModalParams,
} from 'components/RegistrationChangesModal';
import Search from 'components/Search';
import { REGISTRATION_BUTTON_STYLES } from 'constants/unionRegistrationTrace';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import {
  UnionRegistersParams,
  useUnionRegisterAllQuery,
} from 'lim/address-group/detail/apis';
import { ApiError } from 'types/api';
import { commaizeNumber } from 'utils/format';

export interface ConfirmStepProps {
  unionSeq: number;
}

const ConfirmStep = ({ unionSeq }: ConfirmStepProps) => {
  const { snackbar, alertDialog, confirmDialog, setLoading } = useFeedback();

  const [registrationChangesModalInfo, setRegistrationChangesModalInfo] =
    useState<
      { open: boolean; params: RegistrationChangesModalParams } | undefined
    >(undefined);
  const [isOpenConfirmRegisters, setIsOpenConfirmRegisters] = useState(false);
  const [
    unionRegisterAgentCreateModalInfo,
    setUnionRegisterAgentCreateModalInfo,
  ] = useState<
    { open: boolean; params: UnionRegisterAgentCreateParams } | undefined
  >(undefined);
  const [
    unionRegisterAgentModifyModalInfo,
    setUnionRegisterAgentModifyModalInfo,
  ] = useState<
    { open: boolean; params: UnionRegisterAgentModifyParams } | undefined
  >(undefined);

  const defaultSearchParams = {
    unionRegisterNo: '',
    name: '',
    localAddress: '',
    realAddress: '',
    phoneNo: '',
  };

  const [hasSearchCriteria, setHasSearchCriteria] = useState<boolean>(true);
  const [isCheckConfireAgree, setIsCheckConfireAgree] =
    useState<boolean>(false);

  const [searchParams, setSearchParams] =
    useState<UnionRegistersParams>(defaultSearchParams);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const {
    data: unionRegisterAllReturnData,
    isLoading,
    isPending,
    isError,
    error,
    refetch: refetchUnionRegisters,
  } = useUnionRegisterAllQuery(unionSeq, searchParams);

  const { data: unionRegisterStatus, refetch: refetchStatus } =
    useRegisterStatusQuery({ unionSeq });

  const registerStatus = useMemo(
    () => unionRegisterStatus?.data?.status,
    [unionRegisterStatus?.data?.status],
  );

  const {
    data: registrationTraceReturnData,
    refetch: registrationTraceQueryRefetch,
  } = useUnionRegistrationTraceQuery({
    unionSeq,
  });

  const registrationTraceStatus = useMemo(
    () => registrationTraceReturnData?.data?.registrationTraceStatus,
    [registrationTraceReturnData?.data?.registrationTraceStatus],
  );

  const { mutate: unionRegistrationTraceActivateMutate } =
    useUnionRegistrationTraceActivateMutation();
  const { mutate: unionRegistrationTraceDeactivateMutate } =
    useUnionRegistrationTraceDeactivateMutation();

  const { mutate: updateRegisterStatusMutate } =
    useUpdateRegisterStatusMutation();
  const { mutate: unionRegistersDeleteMutate } =
    useUnionRegistersDeleteMutation();

  const { datagridApiRef, initialState, getExcelData } = useGridUtils({
    key: 'union-registers',
    activeSaveSnapshot: true,
  });

  const handleClickAgentName = useCallback(
    (unionRegisterAgentSeq: number) => {
      setUnionRegisterAgentModifyModalInfo({
        open: true,
        params: { unionSeq, unionRegisterAgentSeq },
      });
    },
    [unionSeq],
  );

  const handleClickNotification = useCallback(
    (unionRegisterSeq: number) => {
      setRegistrationChangesModalInfo({
        open: true,
        params: { unionSeq, unionRegisterSeq },
      });
    },
    [unionSeq],
  );

  const columnsHandlers = useMemo(
    () => ({
      onClickAgentName: handleClickAgentName,
      onClickNotification: handleClickNotification,
    }),
    [handleClickAgentName, handleClickNotification],
  );

  const { columns } = useCreateGridColumns<{
    onClickAgentName: (unionRegisterAgentSeq: number) => void;
    onClickNotification: (unionRegisterSeq: number) => void;
  }>({
    handlers: columnsHandlers,
    columns: UNION_REGISTERS_GRID_COLUMNS,
  });

  const { totalElements, totalDataCount, rows } = useMemo(() => {
    return {
      totalElements: unionRegisterAllReturnData?.pagination?.totalElements || 0,
      totalDataCount:
        unionRegisterAllReturnData?.pagination?.totalDataCount || 0,
      rows: unionRegisterAllReturnData?.data || [],
    };
  }, [unionRegisterAllReturnData]);

  const handleExportExcel = useCallback(async () => {
    const { headers, rows } = getExcelData<UnionRegisterInfoAll[]>();
    const expandRows = expandData(rows);

    try {
      await exportExcel(headers, expandRows as string[][], {
        fileName: '조합원_명부.xlsx',
        worksheetName: '조합원 명부 목록',
      });
    } catch (error) {
      snackbar('다운로드에 실패하였습니다.', { color: 'danger' });
    }
  }, [getExcelData, snackbar]);

  const handleSearchParams = (values: Partial<UnionRegistersParams>) => {
    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...values }));
  };

  const handleSearchReset = () => {
    setSearchParams(defaultSearchParams);
  };

  const handleClickRegistersDelete = useCallback(() => {
    if (selectedRows.length === 0) {
      return alertDialog('선택하신 조합원 정보가 없습니다.', {
        message: '알림',
      });
    }

    confirmDialog('선택하신 조합원 정보를 삭제하시겠습니까?', {
      onConfirm: () => {
        unionRegistersDeleteMutate(
          {
            unionSeq,
            unionRegisterSeqs: selectedRows as number[],
          },
          {
            onSuccess: () => {
              snackbar('삭제가 완료되었습니다.', { color: 'success' });
              refetchUnionRegisters();
            },
            onError: (error) => {
              snackbar(error.message, { color: 'danger' });
            },
          },
        );
      },
      message: '확인',
    });
  }, [
    alertDialog,
    confirmDialog,
    refetchUnionRegisters,
    selectedRows,
    snackbar,
    unionRegistersDeleteMutate,
    unionSeq,
  ]);

  const handleRowSelectedModelChange = useCallback(
    (
      rowSelectionModel: GridRowSelectionModel,
      // details: GridCallbackDetails,
    ) => {
      setSelectedRows(rowSelectionModel);
    },
    [],
  );

  const handleConfirmRegisters = useCallback(() => {
    setLoading(true);

    updateRegisterStatusMutate(
      {
        unionSeq,
        status: 'CONFIRM',
      },
      {
        onSuccess: () => {
          setIsOpenConfirmRegisters(false);
          snackbar('명부 확정이 완료 되었습니다.', { color: 'success' });
          refetchStatus();
        },
        onError: (error) => {
          snackbar(error.message, { color: 'danger' });
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
    // 제출 로직
  }, [
    setLoading,
    updateRegisterStatusMutate,
    unionSeq,
    snackbar,
    refetchStatus,
  ]);

  const handleCancelConfrimRegisters = useCallback(() => {
    setIsCheckConfireAgree(false);
    setIsOpenConfirmRegisters(false);
  }, []);

  const handleChangeRegistrationTrace = useCallback(
    (status?: string) => {
      if (!status) return;

      const handleSuccess = () => registrationTraceQueryRefetch();
      const handleError = (error: ApiError) =>
        snackbar(error.message, { color: 'danger' });

      if (status === 'DEACTIVATED') {
        return unionRegistrationTraceActivateMutate(
          { unionSeq },
          {
            onSuccess: handleSuccess,
            onError: handleError,
          },
        );
      }

      unionRegistrationTraceDeactivateMutate(
        { unionSeq },
        {
          onSuccess: handleSuccess,
          onError: handleError,
        },
      );
    },
    [
      registrationTraceQueryRefetch,
      snackbar,
      unionRegistrationTraceActivateMutate,
      unionRegistrationTraceDeactivateMutate,
      unionSeq,
    ],
  );

  if (isError) throw error;

  return (
    <>
      <Stack width="100%" height="100%" overflow="hidden">
        {hasSearchCriteria && (
          <Box flex={0} mb={3}>
            <Search
              defaultValues={defaultSearchParams}
              onSubmit={handleSearchParams}
              onReset={handleSearchReset}
            >
              <Grid container gap={2}>
                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      name="unionRegisterNo"
                      label="연번"
                      placeholder='숫자 또는 "-"입력'
                      validateOptions={{
                        maxLength: 11,
                        regex: /^(?!.*--)[0-9-]*$/,
                      }}
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      name="name"
                      label="조합원 이름"
                      placeholder="조합원 이름 입력"
                      slotProps={{
                        input: { maxLength: 30 },
                      }}
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <TextField
                      name="localAddress"
                      label="소재지"
                      placeholder="주소 입력"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <TextField
                      name="realAddress"
                      label="거주지 및 수령지"
                      placeholder="주소 입력"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      label="연락처"
                      name="phoneNo"
                      placeholder="숫자만 입력"
                      fullWidth
                      validateOptions={{
                        maxLength: 11,
                        regex: /^[0-9]*$/,
                      }}
                    />
                  </Search.Field>
                </Grid>
              </Grid>
            </Search>
          </Box>
        )}

        <Stack
          mb={2}
          flexDirection="row-reverse"
          flexWrap="wrap"
          justifyContent="start"
          gap={1}
        >
          <Stack
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            ml="auto"
            justifyContent="end"
          >
            {registerStatus === 'CONFIRM' && (
              <>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Typography
                    textColor="neutral.500"
                    fontSize="md"
                    fontWeight="md"
                    lineHeight="md"
                  >
                    검색조건 표시
                  </Typography>
                  <Switch
                    size="lg"
                    checked={hasSearchCriteria}
                    onChange={(event) =>
                      setHasSearchCriteria(event.target.checked)
                    }
                  />
                </Stack>

                <Button
                  variant="plain"
                  {...(registrationTraceStatus &&
                    REGISTRATION_BUTTON_STYLES?.[registrationTraceStatus])}
                  onClick={() =>
                    handleChangeRegistrationTrace(registrationTraceStatus)
                  }
                />

                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={handleExportExcel}
                >
                  명부 다운로드
                </Button>
                <Button
                  variant="outlined"
                  color="neutral"
                  component={Link}
                  href="histories"
                >
                  변동 이력
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setUnionRegisterAgentCreateModalInfo({
                      open: true,
                      params: { unionSeq },
                    })
                  }
                >
                  대리인 등록
                </Button>
              </>
            )}
            <Button variant="outlined" component={Link} href="create">
              조합원 개별등록
            </Button>
            <Button
              variant="outlined"
              color="danger"
              onClick={handleClickRegistersDelete}
            >
              삭제
            </Button>
            {registerStatus === 'CONVEY' && (
              <Button onClick={() => setIsOpenConfirmRegisters(true)}>
                조합원 명부 확정
              </Button>
            )}
          </Stack>

          <Stack flexDirection="row" gap={2} alignSelf="flex-end">
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>

            {selectedRows.length > 0 && (
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                선택{' '}
                <Typography color="primary">
                  {commaizeNumber(selectedRows.length)}
                </Typography>
              </Typography>
            )}
          </Stack>
        </Stack>

        <Box flex="1 1 0" overflow="hidden">
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            autosizeOnMount={false}
            getRowHeight={(params) =>
              (params?.model?.localAddresses?.length || 1) * 40 || 40
            }
            onRowSelectionModelChange={handleRowSelectedModelChange}
            loading={isLoading || isPending}
            getRowId={(row) => row.unionRegisterSeq}
            checkboxSelection
            disableRowSelectionOnClick
            initialState={initialState}
          />
        </Box>
      </Stack>

      {unionRegisterAgentCreateModalInfo && (
        <UnionRegisterAgentCreateModal
          {...unionRegisterAgentCreateModalInfo}
          onClose={() => {
            setUnionRegisterAgentCreateModalInfo(undefined);
            refetchUnionRegisters();
          }}
        />
      )}

      {unionRegisterAgentModifyModalInfo && (
        <UnionRegisterAgentModifyModal
          {...unionRegisterAgentModifyModalInfo}
          onClose={() => {
            setUnionRegisterAgentModifyModalInfo(undefined);
            refetchUnionRegisters();
          }}
        />
      )}

      {registrationChangesModalInfo && (
        <RegistrationChangesModal
          {...registrationChangesModalInfo}
          onClose={() => {
            setRegistrationChangesModalInfo(undefined);
            refetchUnionRegisters();
          }}
        />
      )}

      <Modal
        open={isOpenConfirmRegisters}
        onClose={() => setIsOpenConfirmRegisters(false)}
      >
        <ModalDialog>
          <DialogTitle>조합원 명부 확정</DialogTitle>
          <Callout color="warning" startDecorator={<InfoIcon />}>
            해당 명부는 각 조합원을 선택하여 수정이 가능하며, 조합원 명부
            수정∙등록함으로써 발생하는 법적인 책임은 최종확인자에게 있으며
            서비스를 제공하는 회사가 책임지지 않습니다.
          </Callout>
          <Stack>
            <Typography>
              {`조합에서 ‘우리가'로 전달한 조합원 명부를 ‘우리가' 명부 양식에 맞게 정리한 것입니다.`}
              업로드된 조합원 명부는 관리 시스템상의 모든 기능과 연동되므로,
              정보가 일치하는지 다시 한번 꼼꼼히 확인해주시기 바랍니다.
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Checkbox
              label="위 사항을 모두 확인하였습니다."
              checked={isCheckConfireAgree}
              onChange={(event) => setIsCheckConfireAgree(event.target.checked)}
            />
          </Stack>
          <DialogActions orientation="horizontal-reverse">
            <Button
              onClick={handleConfirmRegisters}
              disabled={!isCheckConfireAgree}
            >
              확인
            </Button>
            <Button
              color="neutral"
              variant="outlined"
              onClick={handleCancelConfrimRegisters}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ConfirmStep;
