import {
  Stack,
  Typography,
  DataGrid,
  Pagination,
  Button,
  Link,
  useGridUtils,
  Box,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import { UnionRegisterInfo } from 'apis/types/union';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  MeetsParticipantsQuery,
  UnionRegistersQuery,
  VoterMeetsParticipantsParams,
} from 'lim/generalMeetingHistoryDetail/apis';
import {
  VOTER_MANAGEMENT_TABLE_COLUMNS,
  VoterManagementTableColumns,
} from 'lim/generalMeetingHistoryDetail/fixtures';
import { commaizeNumber } from 'utils/format';

import SearchFilter from './SearchFilter';
import VoterAddListModal from './VoterAddListModal';
import VoterAddModal from './VoterAddModal';

export interface VoterManagementTabProps {
  meetMethod: MeetMethods;
  meetStatus: MeetStatus;
  unionRegistersQuery: UnionRegistersQuery;
  meetsParticipantsQuery: MeetsParticipantsQuery;
  onAddVoter: (unionRegisterSeqs: number[]) => void;
  onSearch: (params: VoterMeetsParticipantsParams) => void;
}

const VoterManagementTab = ({
  meetMethod,
  meetStatus,
  unionRegistersQuery,
  meetsParticipantsQuery,
  onAddVoter,
  onSearch,
}: VoterManagementTabProps) => {
  const { pageContext } = useLayoutContext();

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-voter',
    activeSaveSnapshot: false,
  });

  const unionSeq = Number(pageContext?.memberUnionInfo?.unionSeq);

  const [showVoterAddModal, setShowVoterAddModal] = useState(false);
  const [showVoterAddListModal, setShowVoterAddListModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState<UnionRegisterInfo[]>([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { data, isLoading, isError, error } = meetsParticipantsQuery;

  const handleVoterAddModalSubmit = (rows: UnionRegisterInfo[]) => {
    setShowVoterAddListModal(true);
    setSelectedRows(rows);
  };

  const handleOnSubmit = (ids: number[]) => {
    setShowVoterAddModal(false);
    setShowVoterAddListModal(false);

    onAddVoter(ids);

    // onAddConfirm(ids);
  };

  const isDisabled = (progressStatus: string, meetStatus: string) => {
    if (meetStatus && (meetStatus === 'COUNTING' || meetStatus === 'COMPLETE'))
      return true;
    if (meetMethod === 'ELECTRONIC') {
      if (progressStatus === 'WAIT') return true;
      return false;
    }
    if (meetMethod === 'ONSITE') {
      if (meetStatus !== 'PROCESS') return true;
      return false;
    }
    if (meetMethod === 'ONSITE_ELECTRONIC') {
      if (progressStatus === 'WAIT') return true;
      return false;
    }
  };

  const rows: VoterManagementTableColumns[] = useMemo(() => {
    return (
      data?.data?.map((participant) => {
        const {
          meetParticipantSeq,
          unionRegisterSeq,
          unionRegisterNo,
          position,
          agent,
          name,
          gender,
          birth,
          phoneNo,
          realAddress,
          postAddress,

          shareType,
          electronicVoteStatus,
        } = participant;

        return {
          id: meetParticipantSeq,
          unionRegisterSeq,
          unionRegisterNo,
          position,
          agent: agent ? true : false,
          shareType,
          name: (name?.name || '') + (name?.nameClass || ''),
          gender,
          birth,
          phoneNo,
          progressStatus: electronicVoteStatus?.progressStatus,
          postAddress:
            (postAddress?.address || '') + (postAddress?.detailAddress || ''),
          realAddress:
            (realAddress?.address || '') + (realAddress?.detailAddress || ''),
          isDisabled:
            isDisabled(electronicVoteStatus?.progressStatus, meetStatus) ||
            false,
        };
      }) || []
    );
  }, [data?.data, isDisabled, meetStatus]);

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  const isAddButtonDisabled = !(
    meetStatus === 'PROCESS' || meetStatus === 'BEFORE'
  );

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <SearchFilter onSearchSubmit={onSearch} />
      </Stack>

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>

        <Box>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={VOTER_MANAGEMENT_TABLE_COLUMNS}
            loading={isLoading}
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>

        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton={false}
            showLastButton={false}
            hidePrevButton={false}
            hideNextButton={false}
            count={pageTotalCount}
            onChange={(_, page) => {
              page &&
                setPaginationModel({
                  ...paginationModel,
                  page: page - 1,
                });
            }}
          />
        </Stack>

        <Stack direction="row" alignSelf="end" gap={2}>
          <Button
            component={Link}
            variant="outlined"
            href="participant-histories"
          >
            변동이력
          </Button>
          <Button
            disabled={isAddButtonDisabled}
            variant="outlined"
            onClick={() => setShowVoterAddModal(true)}
          >
            선거인 추가하기
          </Button>
          <Button variant="outlined" onClick={() => exportExcel()}>
            내역 다운
          </Button>
        </Stack>
      </Stack>

      <VoterAddModal
        unionSeq={unionSeq}
        unionRegistersQuery={unionRegistersQuery}
        addedUnionRows={rows}
        open={showVoterAddModal}
        onClose={setShowVoterAddModal}
        onSubmit={handleVoterAddModalSubmit}
      />

      <VoterAddListModal
        meetMethod={meetMethod}
        rows={selectedRows}
        open={showVoterAddListModal}
        onClose={setShowVoterAddListModal}
        onSubmit={handleOnSubmit}
      />
    </Stack>
  );
};

export default VoterManagementTab;
