import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Callout,
  //DateTimePicker,
  FileDragDrop,
  //FormControl,
  FormLabel,
  //Grid,
  Link,
  Sheet,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { useFileUploadMutation } from 'apis/common/apis';
import {
  Documents,
  useElectronicDocumentsMutation,
} from 'apis/electronic-posts/apis';
import { FileInfo } from 'apis/types/common';
import useFeedback from 'hooks/useFeedback';
import ElectronicPostsAddModal from 'pages/main/union-management/electronic-posts/modal/ElectronicPostsAddModal';
import { ApiResponseData } from 'types/api';
import { CustomRouteObject } from 'types/route';

interface ElectronicPostInfo {
  unionSeq: number;
  unionRegisterSeqs: number[];
}

const schema = yup.object().shape({
  documents: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required('제목을 입력해주세요.'),
        attach: yup.object().shape({
          fileSeq: yup
            .number()
            .required('첨부된 파일이 없습니다.')
            .min(0, '첨부된 파일이 없습니다.'),
          originName: yup.string(),
        }),
      }),
    )
    .min(1, '등기 정보를 입력해주세요.'),
  //scheduledAt: yup.string().required(),
});

const CreatePage = () => {
  const params = useParams();
  const unionId = Number(params.unionSeq);
  const location = useLocation();
  const navigate = useNavigate();
  const { snackbar } = useFeedback();

  const { mutateAsync: uploadFilesMutate } = useFileUploadMutation();
  const { mutate: electronicDocumentMutate } = useElectronicDocumentsMutation();

  const [isBalanceOpen, setIsBalanceOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState({});

  const [electronicPostInfo, setElectronicPostInfo] =
    useState<ElectronicPostInfo>({
      unionSeq: unionId, // 초기값으로 unionId 사용 (number)
      unionRegisterSeqs: [], // 빈 숫자 배열로 초기화
    });
  const { control, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    fields: electronicPostFields,
    append: appendElectronicPostFields,
    update: updateElectronicPostFields,
    remove: removeElectronicPostFields,
  } = useFieldArray({
    control,
    //  변수명은 변경 가능
    name: 'documents',
    shouldUnregister: true,
  });

  const handleSelectFiles = useCallback(
    (
      upcomingFiles: FileList | null,
      onSuccess: (response: ApiResponseData<FileInfo[]>) => void,
    ) => {
      if (!upcomingFiles) {
        return;
      }

      const fileList = new DataTransfer();
      fileList.items.add(upcomingFiles[0]);
      const fileLimitSize = 1024 * 1024 * 10;
      const targetFileSize = fileList.files[0].size;
      // 공통 컴포넌트 기능 추가 전  임시 조치
      if (targetFileSize > fileLimitSize) {
        snackbar('파일은 10MB를 초과 할 수 없습니다.', {
          color: 'danger',
        });
        return;
      }
      uploadFilesMutate(
        {
          divisionCode: 'UNION_ELECTRONIC_DOCUMENT',
          files: fileList.files,
        },
        {
          onSuccess,
          onError: (error) => {
            console.log('error', error);
          },
        },
      );
    },
    [uploadFilesMutate], // 의존성 배열: setValue를 의존성으로 추가
  );

  const transformDocuments = (docs: Documents) => {
    return docs?.map((doc) => ({
      title: doc.title, // title 필드 유지
      fileSeq: doc.attach.fileSeq, // attach의 fileSeq 사용
    }));
  };

  const enhancedHandleSubmit = handleSubmit(
    (data) => {
      // 비용 조회
      if (!data) {
        return;
      }

      // documents가 비어있는지 확인
      if (data?.documents?.length === 0) {
        snackbar('등기 정보를 입력해주세요', {
          color: 'danger',
        });
        return;
      }
      if (!data?.documents) return;

      // documents 배열의 구조 변환
      const transformedDocuments = transformDocuments(data.documents);

      const updatedData = {
        ...data,
        documents: transformedDocuments,
        scheduledAt: '',
        sendMethod: 'KAKAO_PAY',
        signatureExpireIn: 168,
        isScheduled: false,
      };

      setFormData(updatedData);
      setIsBalanceOpen(true);
    },
    () => {
      snackbar('입력을 확인해 주세요', {
        color: 'danger',
      });
    },
  );

  const handleSubmitPosts = () => {
    electronicDocumentMutate(
      { ...formData, ...electronicPostInfo },
      {
        onSuccess: () => {
          snackbar('전자 등기가 생성 되었습니다.', {
            color: 'primary',
          });
          navigate(`/unions/${unionId}/electronic-posts`);
        },
        onError: (error) => {
          snackbar(error?.response?.data?.message || '', {
            color: 'danger',
          });
        },
      },
    );
  };

  useEffect(() => {
    const state = location.state as { data?: [] };
    if (state?.data) {
      return setElectronicPostInfo({
        ...electronicPostInfo,
        unionRegisterSeqs: state.data?.map((data) => {
          return Number(data?.id);
        }),
      });
    }

    navigate(`/unions/${unionId}/electronic-posts/recipient`);
  }, []);

  return (
    <>
      <Stack gap={2} maxWidth="md">
        <Stack maxWidth={600} marginBottom={3}>
          <Stepper>
            <Step
              indicator={
                <StepIndicator variant="soft" color="primary">
                  1
                </StepIndicator>
              }
            >
              수신 대상자 선택
            </Step>
            <Step
              indicator={
                <StepIndicator variant="solid" color="primary">
                  2
                </StepIndicator>
              }
            >
              등기 입력
            </Step>
            <Step
              indicator={
                <StepIndicator variant="soft" color="primary">
                  3
                </StepIndicator>
              }
            >
              등기 생성
            </Step>
          </Stepper>
        </Stack>
        <Stack>
          <Callout>
            <Typography>
              전자 등기 우편 발송을 위해서 제목과 발송 일시를 입력하세요.
            </Typography>
            <Typography>
              첨부할 파일이 있다면 파일을 첨부해주시고, 파일의 제목을
              입력해주세요.
            </Typography>
            <Typography>
              첨부 파일 개수만큼의 전자 등기 우편이 등록 됩니다.
            </Typography>
            <Typography>
              이후 전자 등기 목록에서 문서를 발송해주세요.
            </Typography>
          </Callout>
        </Stack>
        <Stack gap={2}>
          {/* <Typography level="title-lg">등기 정보 입력</Typography> */}
          {/* <Stack>
            <Grid container gap={2}>
              <Grid>
                <FormControl>
                  <FormLabel>발송 일시</FormLabel>
                </FormControl>
                <Controller
                  name="scheduledAt"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      minDate={new Date()}
                      //   onChange={(value) => handleDateChange('postSendDate', value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack> */}

          <Stack gap={2} onSubmit={enhancedHandleSubmit} component="form">
            <Typography level="title-lg">등기 정보 입력</Typography>

            <Stack gap={2}>
              {electronicPostFields.map((item, index) => (
                <Sheet
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    p: 2,
                    borderRadius: 'md',
                    gap: 8,
                  }}
                  key={item.id}
                >
                  <Stack flex={1}>
                    <Controller
                      name={`documents.${index}`}
                      control={control}
                      render={({ field }) => (
                        <Stack gap={1}>
                          <Controller
                            name={`${field.name}.title`}
                            control={control}
                            render={({ field: titleField }) => (
                              <TextField
                                fullWidth
                                label="제목"
                                {...titleField}
                              />
                            )}
                          />

                          <Stack gap={1}>
                            <FormLabel>첨부 파일</FormLabel>
                            <FileDragDrop
                              fullWidth
                              orientation="horizontal"
                              accept="application/pdf"
                              id={`${field.name}.fileSeq`}
                              name={`${field.name}.fileSeq`}
                              onSelectFiles={(files) =>
                                handleSelectFiles(files, (response) => {
                                  response?.data?.[0] &&
                                    updateElectronicPostFields(index, {
                                      ...field.value,
                                      attach: response.data[0],
                                    });
                                })
                              }
                            />
                            <Typography textColor="neutral.500">
                              {field.value.attach?.originName
                                ? `첨부파일명: ${
                                    field.value.attach?.originName || ''
                                  }`
                                : ''}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    />
                  </Stack>
                  <Button
                    variant="outlined"
                    color="danger"
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={() => {
                      removeElectronicPostFields(index);
                    }}
                  >
                    삭제
                  </Button>
                </Sheet>
              ))}

              <Button
                size="md"
                startDecorator={<AddIcon />}
                variant="outlined"
                color="primary"
                onClick={() => {
                  appendElectronicPostFields({
                    title: '',

                    // fileSeq: -1,
                    // originName: '',
                    attach: {
                      fileSeq: -1,
                      originName: '',
                    },
                  });
                }}
              >
                전자 등기 우편 항목 추가
              </Button>
              <Stack flexDirection="row" justifyContent="space-between">
                <Button
                  size="lg"
                  variant="outlined"
                  color="neutral"
                  component={Link}
                  href=".."
                >
                  취소
                </Button>
                <Button type="submit" size="lg">
                  제출
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isBalanceOpen && (
        <ElectronicPostsAddModal
          open={isBalanceOpen}
          onClose={setIsBalanceOpen}
          unionSeq={unionId}
          count={electronicPostInfo?.unionRegisterSeqs.length || 0}
          handleSubmitPosts={handleSubmitPosts}
        />
      )}
    </>
  );
};

const route: CustomRouteObject = {
  path: 'create',
  index: true,
  element: <CreatePage />,
  handle: {
    getTitle: () => '등기 작성',
  },
};

const createPage = {
  path: '/unions/:unionSeq/electronic-posts/recipient',
  children: [route],
  handle: {
    getTitle: () => '등기 작성',
  },
};

export default createPage;
