import {
  Button,
  Card,
  Chip,
  ChipProps,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { createElement, useMemo } from 'react';

import {
  UnionRegisterParams,
  useUnionRegisterQuery,
} from 'apis/union/register/apis';
import CommonCode from 'components/CommonCode';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { formatBirth, formatTelephone } from 'utils/format';

interface UnionRegisterInfoDetailModalProps {
  params: UnionRegisterParams;
  open: boolean;
  onClose: (open: false) => void;
}

const UnionRegisterInfoDetailModal = ({
  params,
  open,
  onClose,
}: UnionRegisterInfoDetailModalProps) => {
  const { data, isPending } = useUnionRegisterQuery(params);

  const unionRegister = data?.data;

  const localAddressDescriptions = useMemo(() => {
    const { localAddresses } = unionRegister || {};

    const addresses =
      localAddresses?.map(
        ({
          city,
          gu,
          dong,
          jibun,
          zoneRoad,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        }) => {
          const subAddress = dong ? [dong, jibun] : [zoneRoad];
          return [
            city,
            gu,
            ...subAddress,
            apartmentDong,
            apartmentFloor,
            apartmentHosu,
          ].join(' ');
        },
      ) || [];

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          소재지 정보
        </Typography>
        <Card variant="outlined">
          <Stack gap={2} sx={{ maxHeight: '94px', overflowY: 'auto' }}>
            {addresses.map((address, index) => (
              <Typography
                key={`local_address_${index}`}
                level="body-sm"
                textColor="text.secondary"
              >
                {address}
              </Typography>
            ))}
          </Stack>
        </Card>
      </Stack>
    );
  }, [unionRegister]);

  const unionRegisterDescriptions = useMemo(() => {
    const {
      ownerType,
      unionRegisterNo,
      name,
      birth,
      gender,
      companyNo,
      shareType,
      positionDescription,
      hasVotingRight,
      salesCount,
      memo,
    } = unionRegister || {};

    const infoJSON =
      ownerType === 'PERSON'
        ? generateDescriptionsJSON(
            [
              '이름',
              '구분',
              '연번',
              '소유구분',
              '직책',
              '생년월일',
              '성별',
              '의결권',
              '분양권',
              '비고',
            ],
            [
              name && `${name?.name} ${name?.nameClass ?? ''}`,
              ownerType && (
                <CommonCode
                  groupCode="UNION_REGISTER_USER_DIVIDE"
                  code={ownerType}
                />
              ),
              unionRegisterNo,
              shareType && (
                <CommonCode
                  groupCode="UNION_REGISTER_SHARE_TYPE"
                  code={shareType}
                />
              ),
              positionDescription,
              formatBirth(birth),
              gender === 'M' ? '남자' : '여자',
              hasVotingRight ? '있음' : '없음',
              salesCount,
              memo,
            ],
            [2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
          )
        : ownerType === 'COMPANY'
        ? generateDescriptionsJSON(
            [
              '이름',
              '구분',
              '연번',
              '소유구분',
              '직책',
              '사업자번호',
              '의결권',
              '분양권',
              '비고',
            ],
            [
              name && `${name?.name} ${name?.nameClass ?? ''}`,
              ownerType && (
                <CommonCode
                  groupCode="UNION_REGISTER_USER_DIVIDE"
                  code={ownerType}
                />
              ),
              unionRegisterNo,
              shareType && (
                <CommonCode
                  groupCode="UNION_REGISTER_SHARE_TYPE"
                  code={shareType}
                />
              ),
              positionDescription,
              formatBirth(companyNo),
              hasVotingRight ? '있음' : '없음',
              salesCount,
              memo,
            ],
            [2, 1, 1, 1, 1, 1, 1, 1, 2],
          )
        : generateDescriptionsJSON(
            [
              '이름',
              '구분',
              '연번',
              '소유구분',
              '직책',
              '의결권',
              '분양권',
              '비고',
            ],
            [
              name && `${name?.name} ${name?.nameClass ?? ''}`,
              ownerType && (
                <CommonCode
                  groupCode="UNION_REGISTER_USER_DIVIDE"
                  code={ownerType}
                />
              ),
              unionRegisterNo,
              shareType && (
                <CommonCode
                  groupCode="UNION_REGISTER_SHARE_TYPE"
                  code={shareType}
                />
              ),
              positionDescription,
              hasVotingRight ? '있음' : '없음',
              salesCount,
              memo,
            ],
            [2, 1, 1, 1, 1, 1, 1, 2],
          );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          조합원 정보
        </Typography>
        <DescriptionsRenderer columns={2} json={infoJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const phoneDescriptions = useMemo(() => {
    const { mainPhone, subPhones } = unionRegister || {};

    const subPhoneLabels = subPhones?.map(() => '기타 연락처') || [];
    const subPhoneNos =
      subPhones?.map((subPhone, index) =>
        createElement(
          Grid,
          {
            key: `sub_phone_${index}`,
            container: true,
            spacing: 2,
            direction: 'row',
            justifyContent: 'space-between',
          },
          [
            formatTelephone(subPhone.phoneNo),
            subPhone.memo,
            createElement(
              Chip,
              {
                variant: 'soft',
                color: subPhone?.isContactAllowed
                  ? ('blue' as ChipProps['color'])
                  : 'danger',
              },
              subPhone?.isContactAllowed ? '수신' : '거부',
            ),
          ].map((value, index, { length }) =>
            createElement(
              Grid,
              {
                key: `sub_phone${value}_${index}`,
                ...(index !== 0 && index !== length - 1 ? { xs: 6 } : null),
              },
              value,
            ),
          ),
        ),
      ) || [];

    const phoneJSON = generateDescriptionsJSON(
      ['조합원 연락처', ...subPhoneLabels],
      [
        createElement(
          Grid,
          {
            container: true,
            spacing: 2,
            direction: 'row',
            justifyContent: 'space-between',
          },
          [
            formatTelephone(mainPhone?.phoneNo),
            '',
            createElement(
              Chip,
              {
                variant: 'soft',
                color: mainPhone?.isContactAllowed
                  ? ('blue' as ChipProps['color'])
                  : 'danger',
              },
              mainPhone?.isContactAllowed ? '수신' : '거부',
            ),
          ].map((value, index, { length }) =>
            createElement(
              Grid,
              {
                key: `main_phone_${value}_${index}`,
                ...(index !== 0 && index !== length - 1 ? { xs: 6 } : null),
              },
              value,
            ),
          ),
        ),
        ...subPhoneNos,
      ],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          연락처 정보
        </Typography>
        <DescriptionsRenderer columns={1} json={phoneJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const realAddressDescriptions = useMemo(() => {
    const { realAddress, postAddress, etcAddress } = unionRegister || {};

    let mainRealAddress = '';
    let mainPostAddress = '';

    if (realAddress) {
      const { address, detailAddress } = realAddress;
      mainRealAddress = `${address} ${detailAddress ?? ''}`;
    }

    if (postAddress) {
      const { address, detailAddress } = postAddress;
      mainPostAddress = [address, detailAddress].join(' ');
    }

    const postJSON = generateDescriptionsJSON(
      ['실제 거주지', '우편물 수령지', '기타 주소지'],
      [mainRealAddress, mainPostAddress, etcAddress],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          주소 정보
        </Typography>
        <DescriptionsRenderer columns={1} json={postJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const agentDescriptions = useMemo(() => {
    const { agent } = unionRegister || {};

    const { relationType, relationDescription, gender, name, birth, phoneNo } =
      agent || {};

    const relation =
      relationDescription ??
      (relationType && (
        <CommonCode groupCode="AGENT_RELATION_TYPE" code={relationType} />
      ));

    const agentInfoJSON = generateDescriptionsJSON(
      ['관계', '성별', '이름', '생년월일', '연락처'],
      [
        relation,
        gender === 'M' ? '남자' : gender === 'F' ? '여자' : '',
        name && `${name?.name} ${name?.nameClass ?? ''}`,
        formatBirth(birth),
        formatTelephone(phoneNo),
      ],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          대리인 정보
        </Typography>
        <DescriptionsRenderer columns={2} json={agentInfoJSON} />
      </Stack>
    );
  }, [unionRegister]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalOverflow>
        <ModalDialog sx={{ width: 600 }}>
          <DialogTitle>조합원 정보</DialogTitle>

          <DialogContent sx={{ py: 1.5 }}>
            <Stack minHeight={700} gap={2}>
              {isPending && <CircularProgress sx={{ m: 'auto' }} />}
              {!isPending && (
                <>
                  {localAddressDescriptions}
                  {unionRegisterDescriptions}
                  {phoneDescriptions}
                  {realAddressDescriptions}
                  {agentDescriptions}
                </>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Stack>
              <Button fullWidth onClick={() => onClose(false)}>
                확인
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default UnionRegisterInfoDetailModal;
