import { Input, Stack, Typography } from '@wooriga/design-system';
import { useState } from 'react';

import { ElectronicVote } from 'apis/meet/certificate-meets/api';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { UpdateMeetBody } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface ElectronicDescriptionsProps {
  electronicVote: ElectronicVote;
  onChange: (electronicVoteProps: UpdateMeetBody['electronicVote']) => void;
}

const ElectronicDescriptions = ({
  electronicVote,
  onChange,
}: ElectronicDescriptionsProps) => {
  const { contactNo, voteStartAt, voteEndAt, senderName, description } =
    electronicVote;

  const [phone, setPhone] = useState(contactNo);

  const blockMinusKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const json = generateDescriptionsJSON(
    [
      '발송 단체명',
      '전자 투표 시작',
      '전자 투표 종료',
      '문의 전화번호',
      '전자 투표내용',
    ],
    [
      senderName,
      formatDateTime(voteStartAt, 'yyyy-MM-dd HH:MM'),
      formatDateTime(voteEndAt, 'yyyy-MM-dd HH:MM'),

      <Input
        key="문의 전화번호"
        size="sm"
        value={phone}
        validateOptions={{
          maxLength: 11,
          regex: /^[0-9]*$/,
        }}
        onKeyDown={blockMinusKey}
        onChange={(e) => {
          const value = e.target.value;
          setPhone(value);
          onChange({
            contactNo: value,
          });
        }}
      />,
      description,
    ],
  );

  return (
    <Stack>
      <Typography level="title-lg">전자투표 정보</Typography>
      <DescriptionsRenderer columns={1} json={json} />
    </Stack>
  );
};

export default ElectronicDescriptions;
