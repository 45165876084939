import styled from '@mui/joy/styles/styled';
import { ColorPaletteProp } from '@mui/joy/styles/types/colorSystem';
import { VariantProp } from '@mui/joy/styles/types/variants';
import {
  Children,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  isValidElement,
  useMemo,
} from 'react';

import type { DescriptionsItemProps } from 'design-system/components/dataDisplay/descriptions/DescriptionsItem';
import Row from 'design-system/components/dataDisplay/descriptions/Row';

export type DescriptionsSlot = 'root';

export type DescriptionsProps = PropsWithChildren<{
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: 'sm' | 'md' | 'lg';
  columns?: number;
}>;

export type DescriptionsOwnerState = DescriptionsProps;

const Descriptions = forwardRef<HTMLDivElement, DescriptionsProps>(
  (props: DescriptionsProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      variant = 'outlined',
      color = 'neutral',
      size = 'md',
      columns = 1,
      children,
      ...rest
    } = props;

    const ownerState = { variant, color, size };

    const items = useMemo(() => {
      const rows: DescriptionsItemProps[][] = [];
      let row: DescriptionsItemProps[] = [];
      let cumulativeSpan = 0;

      Children.forEach(children, (child) => {
        if (isValidElement<DescriptionsItemProps>(child)) {
          const { span = 1 } = child.props;

          if (cumulativeSpan + span > columns) {
            rows.push(row);
            row = [];
            cumulativeSpan = 0;
          }

          row.push(child.props);
          cumulativeSpan += span;
        }
      });

      row.length !== 0 && rows.push(row);
      return rows;
    }, [children, columns]);

    return (
      <Root {...rest} ownerState={ownerState} ref={ref}>
        <table>
          <tbody>
            {items.map((item, index) => (
              <Row key={`item-${index}`} row={item} ownerState={ownerState} />
            ))}
          </tbody>
        </table>
      </Root>
    );
  },
);

const Root = styled('div', {
  name: 'JoyDescriptions',
  slot: 'root',
})<{ ownerState: DescriptionsOwnerState }>(
  ({
    theme,
    ownerState: { variant = 'outlined', size = 'md', color = 'neutral' },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.surface,
    borderRadius: theme.radius[size],

    ...theme.variants[variant][color],

    ['& table']: {
      width: '100%',
    },
  }),
);

Descriptions.displayName = 'Descriptions';

export default Descriptions;
