import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { MessageReceiver } from 'apis/types/message';
import { GridColumnsHandlers } from 'types/grid';
import { formatDateTime, formatTelephone } from 'utils/format';

export interface MessageReceiverColumnProps {
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}

export const MESSAGE_RECEIVER_COLUMNS = ({
  onClickUnionMember,
  getCommonCode,
}: GridColumnsHandlers<MessageReceiverColumnProps>) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   width: 78,
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current.getAllRowIds().indexOf(row?.messageId) + 1 || '',
    // },
    {
      field: 'name',
      headerName: '수신자',
      width: 90,
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'phoneNo',
      headerName: '수신번호',
      width: 110,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'sentAt',
      headerName: '발송일시',
      width: 100,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'completedAt',
      headerName: '완료일시',
      width: 100,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'isAgent',
      headerName: '대리인',
      type: 'boolean',
      width: 80,
    },
    {
      field: 'sendResult',
      headerName: '결과',
      width: 78,
    },
    {
      field: 'sendStatus',
      headerName: '상태',
      width: 78,
      valueGetter: (code) => getCommonCode('UNION_SMS_STATUS', code)?.name,
    },
  ] as GridColDef<MessageReceiver>[];
