import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  AspectRatio,
  Button,
  Chip,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useState } from 'react';

import { MeetRequest } from 'apis/types/meet';
import FileView from 'components/pages/common/MeetsInfoDescription/VoteModal/FileView';
// import { useState } from 'react';

interface ChoiceAgendaProps {
  agendaName: string;
  agendaNo: number;
  agendaDes: string;
  selectCount: number;
  candidates: MeetRequest['agendas'][number]['candidates'];
}

const ChoiceAgenda = ({
  agendaNo,
  agendaName,
  agendaDes,
  selectCount,
  candidates,
}: ChoiceAgendaProps) => {
  const [checkedLists, setCheckedLists] = useState<number[]>([]);

  const handleCheck = (no: number) => {
    if (checkedLists.includes(no)) {
      return setCheckedLists((lists) => lists.filter((list) => list !== no));
    }
    if (checkedLists.length === selectCount) {
      const copied = [...checkedLists];
      copied.shift();
      copied.push(no);
      return setCheckedLists(copied);
    }
    setCheckedLists([...checkedLists, no]);
  };

  return (
    <Stack gap={3}>
      <Stack>
        <Stack
          position="relative"
          borderRadius="md"
          padding="24px 24px 16px 24px"
          bgcolor={(theme) => theme.palette.background.body}
          boxShadow={(theme) => `0px 0px 5px ${theme.palette.neutral[300]}`}
          gap={3}
        >
          <Chip
            sx={{
              color: '#ffffff',
              backgroundColor: '#215DBA',
              position: 'absolute',
              padding: '4px 16px',
              top: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              transform: 'translateY(-50%)',
            }}
          >
            {agendaNo}호 안건
          </Chip>
          <Stack>
            <Typography sx={{ wordBreak: 'break-word' }} level="title-lg">
              {agendaName}
            </Typography>

            <AccordionGroup sx={{ width: 130 }}>
              <Accordion>
                <AccordionSummary sx={{ alignSelf: 'center', width: 130 }}>
                  안건 보기
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineBreak: 'anywhere' }}>
                    {agendaDes}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </Stack>
        </Stack>
      </Stack>
      <Chip
        sx={{
          backgroundColor: '#ffffff',
          color: '#215DBA',
          fontWeight: 'bold',
        }}
      >
        투표 {checkedLists.length}/{selectCount}
      </Chip>
      {candidates.map((candidate) => {
        const { no, name, description, attachFileSeq } = candidate;
        const isChecked = checkedLists.includes(no);

        return (
          <Stack
            key={no}
            borderRadius="md"
            padding="14px"
            bgcolor={(theme) => theme.palette.background.body}
            boxShadow={(theme) => `0px 0px 5px ${theme.palette.neutral[300]}`}
            gap={3}
          >
            <Stack alignItems="center" gap={2} flexDirection="row">
              <AspectRatio
                variant="plain"
                objectFit="contain"
                sx={{ width: 166 }}
                ratio="1/1"
              >
                {attachFileSeq ? (
                  <FileView type="img" fileSeq={attachFileSeq} />
                ) : (
                  <img
                    src="/images/meet-open/candidate.svg"
                    alt="candidate_image"
                  />
                )}
              </AspectRatio>
              <Stack
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Stack>
                  <Typography
                    sx={{ wordBreak: 'break-word' }}
                    level="title-lg"
                    textAlign="center"
                  >
                    {no}번. {name}
                  </Typography>

                  <AccordionGroup>
                    <Accordion>
                      <AccordionSummary
                        sx={{ alignSelf: 'center', width: 150 }}
                      >
                        상세설명 보기
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{ wordBreak: 'break-word' }}>
                          {description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionGroup>
                </Stack>

                <Button
                  variant="plain"
                  color="danger"
                  value={no}
                  onClick={() => handleCheck(no)}
                >
                  <img
                    width={48}
                    src={
                      isChecked
                        ? '/images/meet-open/vote_checked.svg'
                        : '/images/meet-open/vote.svg'
                    }
                    alt="vote_image"
                  />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ChoiceAgenda;
