import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MeetRequest } from 'apis/types/meet';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import useFeedback from 'hooks/useFeedback';
import { useCreateMeetMutation } from 'lim/meetOpen/apis';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';

interface OpenConfirmModalProps {
  formData: MeetRequest;
  cost: number;
  count: number;
  open: boolean;
  onClose: (show: false) => void;
}

const OpenConfirmModal = ({
  open,
  cost,
  count,
  formData,
  onClose,
}: OpenConfirmModalProps) => {
  const navigate = useNavigate();
  const { snackbar } = useFeedback();
  const { getCode } = useCommonCodes();

  const {
    name,
    meetType,
    meetMethod,
    moverName,
    agendas,
    electronicVote,
    onsiteVote,
  } = formData;

  const [isChecked, setIsChecked] = useState(false);

  const { mutate, isPending } = useCreateMeetMutation();

  const meetInfoJSON = generateDescriptionsJSON(
    ['총회명', '총회종류', '총회방식', '소집/발의자 명', '총 선거인', '안건수'],
    [
      name,
      getCode('MEET_TYPE', meetType)?.name,
      getCode('MEET_METHOD', meetMethod)?.name,
      moverName,
      `${count} 명`,
      `${agendas.length} 건`,
    ],
  );

  const electronicVoteInfoJSON = generateDescriptionsJSON(
    ['발송단체명', '문의 전화번호', '투표 시작일시', '투표 종료일시'],
    [
      electronicVote?.senderName,
      formatTelephone(electronicVote?.contactNo),
      formatDateTime(electronicVote?.voteStartAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(electronicVote?.voteEndAt, 'yyyy-MM-dd HH:mm'),
    ],
  );

  const onsiteVoteInfoJSON = generateDescriptionsJSON(
    ['서면 제출 시작', '서면 제출 마감', '총회일시'],
    [
      formatDateTime(onsiteVote?.submissionStartAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(onsiteVote?.submissionEndAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(onsiteVote?.meetAt, 'yyyy-MM-dd HH:mm'),
    ],
  );

  const costJSON = generateDescriptionsJSON(
    ['총 신청 금액'],
    [`${commaizeNumber(cost)}원`],
  );

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      return snackbar('주의사항을 확인하시고 내용에 동의해 주세요.', {
        color: 'danger',
      });
    }

    const { electronicVote, onsiteVote, ...rest } = formData;

    const methodData =
      meetMethod === 'ELECTRONIC'
        ? { electronicVote }
        : meetMethod === 'ONSITE'
        ? { onsiteVote }
        : { electronicVote, onsiteVote };

    const newFormData = {
      ...rest,
      ...methodData,
    };

    mutate(newFormData, {
      onSuccess: () => {
        snackbar('총회 개설이 완료되었습니다.', {
          color: 'success',
        });
        navigate('..');
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <DialogTitle sx={{ pb: 1.5 }}>
            <Stack gap={1.5}>
              <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                총회개설
              </Typography>
              <Typography textColor="text.tertiary">
                입력한 내용으로 총회개설을 진행하시겠습니까?
              </Typography>
            </Stack>
          </DialogTitle>

          <DialogContent>
            <Stack gap={3}>
              <Stack gap={0.75}>
                <Typography level="title-md">총회정보</Typography>

                <DescriptionsRenderer columns={1} json={meetInfoJSON} />
              </Stack>
              {meetMethod.includes('ELECTRONIC') && (
                <Stack gap={0.75}>
                  <Typography level="title-md">전자투표 정보</Typography>

                  <DescriptionsRenderer
                    columns={1}
                    json={electronicVoteInfoJSON}
                  />
                </Stack>
              )}
              {meetMethod.includes('ONSITE') && (
                <Stack gap={0.75}>
                  <Typography level="title-md">현장투표 정보</Typography>

                  <DescriptionsRenderer columns={1} json={onsiteVoteInfoJSON} />
                </Stack>
              )}
              <Stack gap={0.75}>
                <Typography level="title-md">신청 금액</Typography>

                <DescriptionsRenderer columns={1} json={costJSON} />
              </Stack>

              <Stack gap={1}>
                <Typography
                  textColor="text.tertiary"
                  textAlign="center"
                  fontSize="md"
                  fontWeight="md"
                  lineHeight="md"
                >
                  입력하신 모든 내용은 작성자에게 책임이 있으며, <br />
                  총회개설 내용 및 선거인 정보에 오류가 없는지 다시 한번 확인해
                  주세요. <br />
                  개설 이후 취소 및 환불이 불가능합니다.
                </Typography>
              </Stack>
              <Stack alignItems="center" marginBottom={3}>
                <Checkbox
                  label="위 사항을 모두 확인하였습니다."
                  checked={isChecked}
                  onChange={handleChecked}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" gap={1}>
              <Button color="neutral" variant="outlined" onClick={handleClose}>
                취소
              </Button>
              <Button onClick={handleSubmit} loading={isPending}>
                총회개설
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default OpenConfirmModal;
