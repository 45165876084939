import { SxProps } from '@mui/material';
import { Button, Stack } from '@wooriga/design-system';
import { Form, useParams } from 'react-router-dom';

import { useFileDownloadMutation } from 'apis/common/apis';
import { MeetMethods, MeetStatus } from 'apis/types/meet';
import ResultGrid from 'components/pages/meet-management/history/detail/common/ResultGrid';
import useFeedback from 'hooks/useFeedback';
import {
  MeetsDetailQuery,
  useElectronicVoteResultQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import {
  MEETS_VOTE_RESULT_COMPLETE_TABLE_COLUMNS,
  MEETS_VOTE_RESULT_TABLE_COLUMNS,
  MeetsVoteResultTableColumnsProps,
  ResultState,
} from 'lim/generalMeetingHistoryDetail/fixtures';

import ResultCallout from './ResultCallout';
import StatusButton from './StatusButton';

export interface ResultContentProps {
  commonStyle: SxProps;
  isMock: boolean;

  meetMethod: MeetMethods;
  meetsDetailQuery: MeetsDetailQuery;
  rows?: MeetsVoteResultTableColumnsProps[];

  resultState?: ResultState;

  onSubmit?: (meetStatus: MeetStatus) => void;
  onReset?: () => void;

  onRowResultSelect?: (id: number | string, value: boolean | null) => void;
}

const ResultContent = ({
  commonStyle,
  isMock,

  meetMethod,
  meetsDetailQuery,
  rows,

  resultState,

  onSubmit,
  onReset,
  onRowResultSelect,
}: ResultContentProps) => {
  const { snackbar } = useFeedback();
  const { meetSeq } = useParams();
  const meetId = Number(meetSeq);

  const { data } = useElectronicVoteResultQuery(meetId);
  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const hasElectronicRows = !!data?.data.length;

  const meetStatus = meetsDetailQuery.data?.data.meetStatus || 'BEFORE';

  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';
  const isOverall = meetMethod === 'ONSITE_ELECTRONIC';

  const electronicVoteEndTime =
    meetsDetailQuery.data?.data.electronicVote?.voteEndAt;

  const isElectronicVoteEnded = !!(
    electronicVoteEndTime &&
    new Date(electronicVoteEndTime).getTime() <= new Date().getTime()
  );

  const handlePDFDownload = () => {
    downloadFile(
      { fileSeq: meetsDetailQuery.data?.data.voteResultFileSeq },
      {
        onSuccess: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;

          link.download = '총 투표결과.pdf';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        },
        onError: (error) =>
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          }),
      },
    );
  };

  const showGrid = () => {
    if (isElectronic) {
      switch (meetStatus) {
        case 'COUNTING':
          return true;
        case 'COMPLETE':
          return true;
        default:
          return false;
      }
    }

    if (isOnsite || isOverall) {
      switch (meetStatus) {
        case 'PROCESS':
          return false;
        case 'COUNTING':
          return true;
        case 'COMPLETE':
          return true;
        default:
          return false;
      }
    }

    return false;
  };

  return (
    <Form
      // method="post"
      onSubmit={() => onSubmit?.(meetStatus)}
    >
      <Stack gap={3} sx={commonStyle}>
        <ResultCallout
          hasRows={hasElectronicRows}
          meetMethod={meetMethod}
          meetStatus={meetStatus}
        />

        {!!rows?.length && resultState && showGrid() && (
          <ResultGrid
            rows={rows}
            columns={
              meetStatus === 'COMPLETE'
                ? MEETS_VOTE_RESULT_COMPLETE_TABLE_COLUMNS()
                : MEETS_VOTE_RESULT_TABLE_COLUMNS(
                    resultState,
                    (id, value) => onRowResultSelect?.(id, value),
                  )
            }
            // defaultGroupingExpansionDepth={2}
            // groupingModels={['no']}
          />
        )}

        <StatusButton
          isMock={isMock}
          isElectronicVoteEnded={isElectronicVoteEnded}
          meetMethod={meetMethod}
          meetStatus={meetStatus}
          onReset={onReset}
        />

        {!isMock && meetStatus === 'COMPLETE' && (
          <Button onClick={handlePDFDownload}>총 투표결과 PDF 다운로드</Button>
        )}
      </Stack>
    </Form>
  );
};

export default ResultContent;
