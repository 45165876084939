import { Box, Chip, GridColDef, Link } from '@wooriga/design-system';

import { MeetResponse } from 'apis/types/meet';
import { OnLinkClick } from 'lim/_fixtures/type';
import { GridColumnsHandlers } from 'types/grid';
import { formatDate } from 'utils/format';

export const MEETS_HISTORY_TABLE_COLUMNS = ({
  onLinkClick,
  getCommonCode,
}: GridColumnsHandlers<{
  onLinkClick: OnLinkClick;
}>) =>
  [
    // { field: 'no', headerName: 'No', width: 78 },
    {
      field: 'name',
      headerName: '총회명',
      width: 200,
      minWidth: 150,
      renderCell: (params) => (
        <Box
          display="flex"
          width="100%"
          height="100%"
          flexDirection="row"
          alignItems="center"
        >
          {params.row?.isMock && (
            <Chip
              size="sm"
              sx={{ alignSelf: 'center', marginLeft: 'auto', mr: 1 }}
              color="warning"
            >
              모의
            </Chip>
          )}
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onLinkClick(params.id)}
          >
            {params.value}
          </Link>
        </Box>
      ),
    },
    {
      field: 'meetAt',
      headerName: '총회일자',
      width: 78,
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'meetType',
      headerName: '총회종류',
      width: 78,
      valueGetter: (value) => getCommonCode('MEET_TYPE', value)?.name,
    },
    {
      field: 'meetMethod',
      headerName: '총회방식',
      width: 78,
      valueGetter: (value) => getCommonCode('MEET_METHOD', value)?.name,
    },
    {
      field: 'openType',
      headerName: '회의유형',
      width: 78,
      valueGetter: (value) =>
        value === '모의 총회'
          ? '모의 총회'
          : getCommonCode('MEET_OPEN_TYPE', value)?.name,
    },
    {
      field: 'moverName',
      headerName: '소집/발의자',
      width: 90,
    },
    {
      field: 'agendaCount',
      headerName: '안건수',
      type: 'number',
      width: 64,
    },
    {
      field: 'createdAt',
      headerName: '등록일시',
      width: 100,
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'meetStatus',
      headerName: '상태',
      width: 78,
      valueGetter: (value) => getCommonCode('MEET_STATUS', value)?.name,
      renderCell: (params) => {
        return <Chip color="primary">{params.value}</Chip>;
      },
    },
  ] as GridColDef<MeetResponse>[];
