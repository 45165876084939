import { Box, Stack } from '@wooriga/design-system';
import { ReactElement } from 'react';

export interface IndexTemplateProps {
  SearchComponent?: ReactElement;
  TopPanelComponent?: ReactElement;
  IndexComponent?: ReactElement;
  PaginationComponent?: ReactElement;
  BottomPanelComponent?: ReactElement;
}

const IndexTemplate = (props: IndexTemplateProps) => {
  const {
    SearchComponent,
    TopPanelComponent,
    IndexComponent,
    PaginationComponent,
    BottomPanelComponent,
  } = props;

  return (
    <Box>
      {SearchComponent && <Box mb={3}>{SearchComponent}</Box>}

      <Stack>
        <Stack gap={2}>
          {TopPanelComponent}

          {IndexComponent && <Box>{IndexComponent}</Box>}
          {PaginationComponent && (
            <Stack alignItems="center">{PaginationComponent}</Stack>
          )}

          {BottomPanelComponent}
        </Stack>
      </Stack>
    </Box>
  );
};

export default IndexTemplate;
