import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/joy';
import {
  Button,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import MeetStatusChip from 'components/Chips/MeetStatusChip';
import VoteModal from 'components/pages/common/MeetsInfoDescription/VoteModal';
import EditModal from 'components/pages/meet-management/history/detail/common/EditModal';
import ElectronicTabPanel from 'components/pages/meet-management/history/detail/tabPanels/ElectronicTabPanels';
import OnsiteTabPanel from 'components/pages/meet-management/history/detail/tabPanels/OnsiteTabPanels';
import OverallTabPanel from 'components/pages/meet-management/history/detail/tabPanels/OverallTabPanels';
import useFeedback from 'hooks/useFeedback';
import {
  UpdateMeetBody,
  useMeetsAgendasQuery,
  useMeetsDetailQuery,
  useUpdateMeetMutation,
} from 'lim/generalMeetingHistoryDetail/apis';
import { CustomRouteObject } from 'types/route';

import MeetHistoryDetailEdit from './edit';
import participantHistoriesPage from './ParticipantHistories';

const MeetHistoryDetailPage = () => {
  const tabListRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const { alertDialog, snackbar } = useFeedback();
  const { unionSeq, meetSeq } = useParams();
  const unionId = Number(unionSeq);
  const meetId = Number(meetSeq);

  const theme = useTheme();

  const meetsDetailQuery = useMeetsDetailQuery(meetId);
  const meetsAgendasQuery = useMeetsAgendasQuery(meetId);
  const { mutate } = useUpdateMeetMutation(meetId);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);

  const [currentTabValue, setCurrentTabValue] = useState('ELECTRONIC');
  const [isHome, setIsHome] = useState(true);

  const handleOnTabChange = (e: React.SyntheticEvent | null) => {
    if (e) {
      const target = e.target as HTMLButtonElement;

      if (target.name === 'HOME') {
        return setIsHome(true);
      }

      if (target.name) {
        setCurrentTabValue(target.name);
      }
      setIsHome(false);
    }
  };

  const handleOnEdit = (formData: UpdateMeetBody) => {
    const { data, refetch } = meetsDetailQuery;

    if (
      data?.data.electronicVote &&
      !Number(formData.electronicVote?.contactNo?.split('-')[0])
    ) {
      return alertDialog('문의전화번호를 입력하세요.');
    }
    mutate(formData, {
      onSuccess: () => {
        refetch();
        snackbar('총회 정보가 수정되었습니다.', {
          color: 'success',
        });

        setShowEditModal(false);
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const meetMethod = useMemo(
    () => meetsDetailQuery.data?.data.meetMethod,
    [meetsDetailQuery.data?.data.meetMethod],
  );

  useEffect(() => {
    const currentTab = location?.state?.currentTab;

    setTimeout(() => {
      if (tabListRef.current && currentTab) {
        (
          tabListRef.current.querySelector(
            `[name=${currentTab}]`,
          ) as HTMLButtonElement
        ).click();
      }
    }, 0);
  }, [location?.state?.currentTab, tabListRef.current]);

  if (!meetId) return <div>meetId 문제</div>;
  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';
  const isOverall = meetMethod === 'ONSITE_ELECTRONIC';

  return (
    <>
      <Stack gap={2}>
        <Stack>
          {meetsDetailQuery.data?.data.isMock && (
            <Typography
              color="warning"
              level="title-lg"
              marginBottom={2}
              startDecorator={<InfoIcon />}
            >
              모의 총회 내역입니다.
            </Typography>
          )}
          <Typography level="title-lg" marginBottom="15px">
            총회 내역 상세
          </Typography>

          <Stack>
            <Tabs
              defaultValue={0}
              selectedTabColor="primary"
              selectedTabVariant="plain"
              tabIndicatorInset
              sx={{
                marginBottom: '30px',
                background: theme.palette.background.body,
              }}
              onChange={handleOnTabChange}
            >
              <TabList ref={tabListRef}>
                {isElectronic && (
                  <>
                    <Tab name="HOME" value={0}>
                      HOME
                    </Tab>
                    <Tab name="ELECTRONIC" value={1}>
                      전자투표 관리
                    </Tab>
                    <Tab value={2}>집계 결과</Tab>
                    <Tab name="VOTER" value={3}>
                      선거인 관리
                    </Tab>
                  </>
                )}

                {isOnsite && (
                  <>
                    <Tab name="HOME" value={0}>
                      HOME
                    </Tab>
                    <Tab name="ONSITE" value={1}>
                      서면투표 관리
                    </Tab>
                    <Tab value={2}>실시간 참석 현황</Tab>
                    <Tab value={3}>집계 결과</Tab>
                    <Tab name="VOTER" value={4}>
                      선거인 관리
                    </Tab>
                  </>
                )}

                {isOverall && (
                  <>
                    <Tab name="HOME" value={0}>
                      HOME
                    </Tab>
                    <Tab name="ELECTRONIC" value={1}>
                      전자투표 관리
                    </Tab>
                    <Tab name="ONSITE" value={2}>
                      서면투표 관리
                    </Tab>
                    <Tab value={3}>실시간 참석 현황</Tab>
                    <Tab value={4}>집계 결과</Tab>
                    <Tab name="VOTER" value={5}>
                      선거인 관리
                    </Tab>
                    <Tab name="YOUTUBE" value={6}>
                      현장중계
                    </Tab>
                  </>
                )}
              </TabList>

              <Stack
                flexDirection="row"
                justifyContent="space-between"
                padding="16px"
                height={68}
              >
                <Stack flexDirection="row" gap={1.75} alignItems="center">
                  <Typography level="title-md">
                    {meetsDetailQuery.data?.data.name}
                  </Typography>

                  <MeetStatusChip
                    code={meetsDetailQuery.data?.data.meetStatus}
                  />
                </Stack>

                {isHome && (
                  <Stack
                    justifyContent="flex-end"
                    flexDirection="row"
                    gap={1.75}
                  >
                    {meetsDetailQuery?.data?.data.electronicVote && (
                      <Button onClick={() => setShowVoteModal(true)}>
                        투표 보기
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      onClick={() => setShowEditModal(true)}
                    >
                      정보 수정
                    </Button>
                  </Stack>
                )}
              </Stack>

              {isElectronic && (
                <ElectronicTabPanel
                  currentTab={currentTabValue}
                  unionSeq={unionId}
                  meetSeq={meetId}
                />
              )}

              {isOnsite && (
                <OnsiteTabPanel
                  currentTab={currentTabValue}
                  unionSeq={unionId}
                  meetSeq={meetId}
                />
              )}

              {isOverall && (
                <OverallTabPanel
                  currentTab={currentTabValue}
                  meetSeq={meetId}
                  unionSeq={unionId}
                  meetName={meetsDetailQuery.data?.data.name}
                  meetAt={meetsDetailQuery.data?.data.meetAt}
                />
              )}
            </Tabs>
          </Stack>
        </Stack>
      </Stack>

      {meetsAgendasQuery?.data?.data &&
        meetsDetailQuery?.data?.data.electronicVote && (
          <VoteModal
            voteName={meetsDetailQuery.data.data.name}
            voteDescription={
              meetsDetailQuery.data.data.electronicVote.description
            }
            agendas={meetsAgendasQuery.data.data}
            open={showVoteModal}
            onClose={setShowVoteModal}
          />
        )}

      {meetsDetailQuery?.data?.data && (
        <EditModal
          data={meetsDetailQuery.data.data}
          open={showEditModal}
          onClose={setShowEditModal}
          onConfirm={handleOnEdit}
        />
      )}
    </>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <MeetHistoryDetailPage />,
  handle: {
    getTitle: () => '총회 내역 상세',
  },
};

const meetHistoryDetail: CustomRouteObject = {
  path: ':meetSeq',
  children: [route, MeetHistoryDetailEdit, participantHistoriesPage],
  handle: {
    getTitle: () => '총회 내역 상세',
  },
};

export default meetHistoryDetail;
