import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as _ from 'lodash-es';

export const expandData = (data: unknown[][]): unknown[][] => {
  return _.flatMap(data, (row) => {
    const maxLength =
      _.max(_.map(row, (value) => (_.isArray(value) ? value.length : 1))) || 1;

    return _.times(maxLength, (i) => {
      return row.map((value) => {
        if (_.isArray(value)) {
          return value[i] !== undefined ? value[i] : null;
        } else {
          return value;
        }
      });
    });
  });
};

export const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

export const columnsAutoSizing = (
  sheet: ExcelJS.Worksheet,
  fromRow: number,
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return;

  const maxColumnLengths: Array<number> = [];

  sheet.eachRow((row, rowNum) => {
    if (rowNum < fromRow) {
      return;
    }

    row.eachCell((cell, num) => {
      if (typeof cell.value === 'string') {
        if (maxColumnLengths[num] === undefined) {
          maxColumnLengths[num] = 0;
        }

        const fontSize = cell.font && cell.font.size ? cell.font.size : 11;
        ctx.font = `${fontSize}pt Arial`;
        const metrics = ctx.measureText(cell.value);
        const cellWidth = metrics.width;

        maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
      }
    });
  });

  for (let i = 1; i <= sheet.columnCount; i++) {
    const col = sheet.getColumn(i);
    const width = maxColumnLengths[i];
    if (width) {
      col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1;
    }
  }
};

export interface ExportExcelOptions {
  fileName: string;
  worksheetName?: string;
  headerOptions?: {
    numFmt?: string;
    font?: Partial<ExcelJS.Font>;
    alignment?: Partial<ExcelJS.Alignment>;
    border?: Partial<ExcelJS.Border>;
    fill?: ExcelJS.Fill;
    protection?: Partial<ExcelJS.Protection>;
  };
  rowOptions?: {
    numFmt?: string;
    font?: Partial<ExcelJS.Font>;
    alignment?: Partial<ExcelJS.Alignment>;
    border?: Partial<ExcelJS.Border>;
    fill?: ExcelJS.Fill;
    protection?: Partial<ExcelJS.Protection>;
  };
}

export const exportExcel = async (
  headerColumns: string[],
  rows: string[][],
  options?: ExportExcelOptions,
) => {
  const {
    fileName = 'excel.xlsx',
    worksheetName = 'sheet1',
    headerOptions = {},
    rowOptions = {},
  } = options || {};

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(worksheetName);

  const header = sheet.addRow(headerColumns);
  header.font = {
    bold: true,
    ...headerOptions?.font,
  };
  header.alignment = {
    horizontal: 'center',
    ...headerOptions?.alignment,
  };
  header.border = {
    top: { style: 'thin', color: { argb: 'ff000000' } },
    left: { style: 'thin', color: { argb: 'ff000000' } },
    bottom: { style: 'thin', color: { argb: 'ff000000' } },
    right: { style: 'thin', color: { argb: 'ff000000' } },
    ...headerOptions?.border,
  };
  header.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'ffaaaaaa' },
    ...headerOptions?.fill,
  } as ExcelJS.Fill;
  header.protection = { ...headerOptions.protection };
  if (headerOptions?.numFmt) header.numFmt = headerOptions.numFmt;
  header.commit();

  rows.map((row) => {
    const cells = sheet.addRow(row);

    cells.font = {
      ...rowOptions?.font,
    };
    cells.alignment = {
      horizontal: 'left',
      ...rowOptions?.alignment,
    };
    cells.border = {
      top: { style: 'thin', color: { argb: 'ff000000' } },
      left: { style: 'thin', color: { argb: 'ff000000' } },
      bottom: { style: 'thin', color: { argb: 'ff000000' } },
      right: { style: 'thin', color: { argb: 'ff000000' } },
      ...rowOptions?.border,
    };
    rowOptions?.fill &&
      (cells.fill = {
        ...rowOptions.fill,
      } as ExcelJS.Fill);
    cells.protection = { ...rowOptions?.protection };
    if (rowOptions?.numFmt) cells.numFmt = rowOptions.numFmt;

    cells.commit();
  });

  columnsAutoSizing(sheet, 0);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  saveAs(blob, fileName);
};
