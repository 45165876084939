import { GridColDef } from '@mui/x-data-grid-premium';
import { Chip, Link } from '@wooriga/design-system';

import { GridColumnsHandlers } from 'types/grid';
import { formatDate, formatDateTime } from 'utils/format';

export const ARCHIVES_TABLE_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    {
      field: 'category',
      headerName: '구분',
      width: 78,
      valueGetter: (value) => getCommonCode('ARCHIVE_CATEGORY', value)?.name,
    },
    {
      field: 'title',
      headerName: '제목',
      width: 300,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`detail/${params.id}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'isPublic',
      headerName: '공개여부',
      width: 78,
      valueGetter: (value) => (value ? '공개' : '비공개'),
      renderCell: ({ value }) => (
        <Chip
          variant="soft"
          color={value === '공개' ? 'primary' : 'neutral'}
          size="lg"
        >
          {value}
        </Chip>
      ),
    },
    {
      field: 'fileCount',
      type: 'number',
      headerName: '첨부파일',
      width: 64,
    },
    {
      field: 'nickname',
      headerName: '작성자',
      width: 90,
      valueGetter: (_, row) => row?.unionMember?.nickname || '',
    },
    {
      headerName: '생성일',
      field: 'createdAt',
      width: 100,
      valueGetter: (value) => value && formatDate(value),
    },
    {
      headerName: '수정일',
      field: 'modifiedAt',
      width: 100,
      valueGetter: (value) => value && formatDate(value),
    },
  ] as GridColDef[];

export const ARCHIVES_HISTORY_TABLE_COLUMNS = () =>
  [
    {
      field: 'fileName',
      headerName: '파일명',
    },
    {
      field: 'nickname',
      type: 'number',
      headerName: '닉네임',
      align: 'left',
    },
    {
      headerName: '다운로드 일시',
      field: 'occurredAt',
      valueGetter: (value) =>
        value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
  ] as GridColDef[];
