import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

export interface LinkModalProps {
  open: boolean;
  onSubmit: (url: string) => void;
  onClose: (show: false) => void;
}

const LinkModal = ({ open, onClose, onSubmit }: LinkModalProps) => {
  const [inputLink, setInputLink] = useState('');
  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputLink(e.target.value);
  };
  const handleOnSubmit = () => {
    if (!inputLink) {
      return;
    }
    onSubmit(inputLink);
  };

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog minWidth="400px">
          <DialogTitle>생중계 링크</DialogTitle>
          <DialogContent>
            <Input
              placeholder="생중계 링크 입력"
              name="link"
              onChange={inputHandler}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={handleOnSubmit}>
              저장
            </Button>
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default LinkModal;
