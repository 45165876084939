import { GridColDef } from '@mui/x-data-grid-premium';

import { Caller } from 'apis/types/message';
import { GridColumnsHandlers } from 'types/grid';
import { formatTelephone } from 'utils/format';

export const MESSAGE_CALLER_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    {
      field: 'outgoingPhoneNo',
      headerName: '발신번호',
      width: 200,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'name',
      headerName: '소유자명',
      width: 240,
    },
    {
      field: 'status',
      headerName: '상태',
      width: 100,
      valueGetter: (value) => getCommonCode('SMS_CALLER_STATUS', value)?.name,
    },
  ] as GridColDef<Caller>[];
