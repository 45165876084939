import { Box } from '@mui/joy';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
} from '@mui/x-data-grid-premium';
import { ForwardedRef, forwardRef } from 'react';

import JoyToolbarQuickFilter from 'design-system/components/dataGrid/JoyToolbarQuickFilter';

export interface JoyGridToolbarProps extends GridToolbarProps {
  showColumnsButton?: boolean;
  showFilterButton?: boolean;
  showDensitySelector?: boolean;
  showExport?: boolean;
}

const JoyGridToolbar = (
  props: JoyGridToolbarProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    showColumnsButton,
    showFilterButton,
    showDensitySelector,
    showExport,
    showQuickFilter,
    quickFilterProps,
    ...other
  } = props;

  return (
    <GridToolbarContainer {...other} ref={ref}>
      {showColumnsButton && <GridToolbarColumnsButton />}
      {showFilterButton && <GridToolbarFilterButton />}
      {showDensitySelector && (
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
      )}
      {showExport && <GridToolbarExport />}

      <Box sx={{ flexGrow: 1 }} />

      {showQuickFilter && <JoyToolbarQuickFilter {...quickFilterProps} />}
    </GridToolbarContainer>
  );
};

JoyGridToolbar.displayName = 'JoyGridToolbar';

export default forwardRef<HTMLDivElement, JoyGridToolbarProps>(JoyGridToolbar);
