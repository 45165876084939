import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import {
  useDeleteLiveStream,
  useReadLiveStream,
  useUpdateLiveStream,
} from 'apis/meet/live-stream/api';
import { MeetMethods, MeetStatus } from 'apis/types/meet';
import LinkModal from 'components/pages/meet-management/history/detail/tabs/BroadcastManagementTab/LinkModal';
import MessageModal from 'components/pages/meet-management/history/detail/tabs/VoteManagementTab/MessageModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { formatDateTime } from 'utils/format';

export interface BroadcastManagementProps<MeetMethod extends MeetMethods> {
  meetSeq: number;
  meetMethod: MeetMethod;
  meetStatus: MeetStatus;
  meetName: string;
  meetAt: string;
}

const BroadcastManagementTab = <MeetMethod extends MeetMethods>({
  meetSeq,
  meetMethod, // meetStatus,
  meetName,
  meetAt,
}: BroadcastManagementProps<MeetMethod>) => {
  const { pageContext } = useLayoutContext();

  const unionName = pageContext?.memberUnionInfo?.name;

  const { snackbar } = useFeedback();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [broadcastUrl, setBroadcastUrl] = useState<string>('');
  const [embededBroadcastUrl, setEmbededBroadCasturl] = useState<string>('');
  const regYoutubeUrl =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?(\w+)(\S+)?$/;

  const readLiveStream = useReadLiveStream({ meetSeq });
  const updateLiveStream = useUpdateLiveStream();
  const deleteLiveStream = useDeleteLiveStream();

  const checkUrl = (url: string) => {
    const match = regYoutubeUrl.exec(url);
    if (!match || !match[5]) {
      return false;
    }
    return true;
  };
  const enrollLinkHandler = () => {
    setShowLinkModal(true);
  };
  const convertToEmbedUrl = (url: string): string | null => {
    const regYoutubeUrl =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|live\/)?([\w-]{11})(\S+)?$/;

    const match = regYoutubeUrl.exec(url);

    if (match && match[5]) {
      const videoId = match[5];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return null;
  };
  const handleDeleteLink = () => {
    deleteLiveStream.mutate(
      { meetSeq },
      {
        onSuccess: () => {
          readLiveStream.refetch();
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleLinkSubmit = (link: string) => {
    if (!checkUrl(link)) {
      snackbar(
        '총회 현장중계 URL주소 형식이 올바르지 않습니다. 다시 확인해주세요.',
        { color: 'danger' },
      );
      return;
    }

    updateLiveStream.mutate(
      {
        meetSeq,
        url: link,
      },
      {
        onSuccess: () => {
          snackbar('총회 현장중계 URL주소 등록이 완료되었습니다.', {
            color: 'success',
          });
          setShowLinkModal(false);
          readLiveStream.refetch();
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };
  useEffect(() => {
    const convertedUrls = convertToEmbedUrl(
      readLiveStream?.data?.data?.url || '',
    );
    setBroadcastUrl(readLiveStream?.data?.data?.url || '');

    setEmbededBroadCasturl(convertedUrls || '');
  }, [readLiveStream?.data?.data]);

  return (
    <Stack gap={2}>
      <Callout variant="outlined" startDecorator={<InfoIcon />}>
        <Typography level="body-md">
          총회 현장중계 URL주소를 등록하세요.
        </Typography>
        <Typography level="body-md">
          URL주소 등록 후, 선거인에게 현장중계를 볼 수 있도록 링크주소가 포함된
          문자를 발송할 수 있습니다.
        </Typography>
      </Callout>
      <Stack>
        <Card>
          <Stack display="flex" flexDirection="row">
            <CardContent>
              <Typography>총회 현장중계 URL주소: {broadcastUrl}</Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => setShowMessageModal(true)}
                disabled={!broadcastUrl}
              >
                문자발송
              </Button>
              {broadcastUrl ? (
                <Button
                  color="danger"
                  variant="outlined"
                  onClick={handleDeleteLink}
                >
                  삭제
                </Button>
              ) : (
                ''
              )}
              <Button onClick={enrollLinkHandler}>
                {broadcastUrl ? '링크 수정' : '링크 등록'}
              </Button>
            </CardActions>
          </Stack>
        </Card>
      </Stack>

      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin={2}
      >
        <Box>
          <div>
            <iframe
              width="560"
              height="315"
              src={embededBroadcastUrl}
              title="총회 라이브 중계"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              onError={() => {
                console.log('error');
              }}
            ></iframe>
          </div>
        </Box>
      </Stack>

      {showLinkModal && (
        <LinkModal
          open={showLinkModal}
          onClose={setShowLinkModal}
          onSubmit={handleLinkSubmit}
        />
      )}

      {showMessageModal && (
        <MessageModal
          meetSeq={meetSeq}
          meetMethod={meetMethod}
          defaultValues={{
            title: `${meetName || ''} 현장 중계 방송 시청 안내`,
            message: `안녕하세요. ${unionName}입니다.

${meetName || ''} 현장중계 방송 안내를 드립니다.
현장참석이 어려우신 경우 아래 링크를 통해 현장중계 방송 시청이 가능하니 많은 관심 부탁드립니다.

<방송시작 시간>
${formatDateTime(meetAt, 'yyyy-MM-dd (EEE) HH시 mm분')}

<현장중계 링크>
${broadcastUrl}

<참고>
1. 현장 사정에 따라 방송 시작 시간이 변경될 수 있습니다.
2. 인터넷 환경에 따라 실제 현장 상황과 5~10초 정도 차이가 날수 있습니다.
`,
            files: [],
          }}
          open={showMessageModal}
          onClose={setShowMessageModal}
        />
      )}
    </Stack>
  );
};

export default BroadcastManagementTab;
