import { Stack, Typography, Button, Sheet } from '@wooriga/design-system';

import {
  useRegisterStatusQuery,
  useUpdateRegisterStatusMutation,
} from 'apis/union/register/apis';
import useFeedback from 'hooks/useFeedback';

export interface RegisteringStepProps {
  unionSeq: number;
}

const RegisteringStep = ({ unionSeq }: RegisteringStepProps) => {
  const { snackbar } = useFeedback();

  const { refetch: refetchStatus } = useRegisterStatusQuery({ unionSeq });
  const { mutate: updateRegisterStatusMutate } =
    useUpdateRegisterStatusMutation();

  const handleCancelRegisters = () => {
    updateRegisterStatusMutate(
      {
        unionSeq: unionSeq,
        status: 'CANCEL',
      },
      {
        onSuccess: () => {
          snackbar('요청이 취소 되었습니다.', { color: 'success' });
          refetchStatus();
        },
        onError: (error) => {
          snackbar(error.message, { color: 'danger' });
        },
      },
    );
  };

  return (
    <Stack>
      <Typography fontSize="lg" fontWeight="xl" lineHeight="xl" mb={1}>
        조합원명부 등록
      </Typography>
      <Stack gap={2}>
        <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'md' }}>
          <Typography>
            {`관리자 시스템은 조합원 명부를 등록하여 등급이 확인된
                    '조합장/위원장','운영진'등급만 이용하실 수 있습니다. `}
          </Typography>
          <Typography>{` 등록한 조합원 명부를 확인중에 있습니다.`}</Typography>
          <Typography>
            {`문의사항은 아래의 메일 또는
                    화면 우측 하단의 문의하기로 연락 부탁드립니다.`}
          </Typography>
          <Typography>
            {`관리자 문의
                    help@wooriga.kr`}
          </Typography>
        </Sheet>
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          <Button
            color="danger"
            variant="outlined"
            size="lg"
            onClick={handleCancelRegisters}
          >
            요청 취소
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RegisteringStep;
