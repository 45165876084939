import { TimePicker as XTimePicker } from '@mui/x-date-pickers-pro';
import type {
  PickerChangeHandlerContext,
  TimeValidationError,
  TimePickerProps as XTimePickerProps,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import TimeField, {
  TimeFieldProps,
} from 'design-system/components/pickers/time/TimeField';
import { formatDateTime } from 'design-system/components/pickers/utils';

export interface TimePickerProps
  extends Omit<
    XTimePickerProps<Date, false>,
    'value' | 'defaultValue' | 'onChange'
  > {
  variant?: TimeFieldProps['variant'];
  color?: TimeFieldProps['color'];
  size?: TimeFieldProps['size'];
  fullWidth?: TimeFieldProps['fullWidth'];
  value?: string | number | null;
  defaultValue?: string | number | null;
  onChange?: (
    value: string | number | null,
    context: PickerChangeHandlerContext<TimeValidationError>,
  ) => void;
}

const TimePicker = (
  props: TimePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    variant = 'outlined',
    color = 'neutral',
    size = 'md',
    slots,
    slotProps,
    value: valueProp,
    defaultValue: defaultValueProp,
    onChange,
    ...other
  } = props;

  const defaultValue = useMemo(
    () => (defaultValueProp ? new Date(defaultValueProp) : null),
    [defaultValueProp],
  );

  const value = useMemo(
    () => (valueProp ? new Date(valueProp) : null),
    [valueProp],
  );

  const handleChange = useCallback(
    (
      value: Date | null,
      context: PickerChangeHandlerContext<TimeValidationError>,
    ) => {
      if (!value) {
        return onChange?.(null, context);
      }

      const formattedDateValue = formatDateTime(value, 'HH:mm:ss') || null;
      return onChange?.(formattedDateValue, context);
    },
    [onChange],
  );

  return (
    <XTimePicker
      ref={ref}
      slots={{
        ...slots,
        field: TimeField,
      }}
      slotProps={{
        ...slotProps,
        field: {
          variant,
          color,
          size,
          ...slotProps?.field,
        } as never,
      }}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      {...other}
    />
  );
};

export default forwardRef<HTMLDivElement, TimePickerProps>(TimePicker);
