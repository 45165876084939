import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { Counsel } from 'apis/types/counsel';
import { ApiError, ApiResponseData } from 'types/api';

export interface CounselParams {
  unionSeq: number;
  counselSeq: number;
}

export type CounselReturnData = Counsel;

export const useCounselQuery = (
  params: CounselParams,
): UseQueryResult<ApiResponseData<CounselReturnData>, ApiError> => {
  const { unionSeq, counselSeq } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/counsels/${counselSeq}`],
  });
};

export interface CounselsParams {
  unionSeq: number;
  unionRegisterNo?: string;
  unionRegisterName?: string;
  unionRegisterSeq?: string;
  searchFrom?: string;
  searchTo?: string;
  serviceType?: string;
  page?: number;
  pageSize?: number;
}

export type CounselsReturnData = Counsel[];

export const useCounselsQuery = ({
  unionSeq,
  ...other
}: CounselsParams): UseQueryResult<
  ApiResponseData<CounselsReturnData, true>,
  ApiError
> => useQuery({ queryKey: [`/unions/${unionSeq}/counsels`, other] });

export interface CounselCreateParams {
  unionSeq: number;
  unionRegisterSeq: number;
  method: string;
  memo: string;
  counselAt: string;
  tendency: string;
  serviceType: string; //'OMS'
  topics: string[];
  votingWrittenType: string;
  meetAttendType: string;
}

export const useCounselCreateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  CounselCreateParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, ...other } = params;

      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/counsels`,
        other,
      );

      return data;
    },
  });

export interface CounselUpdateParams {
  unionSeq: number;
  counselSeq: number;
  unionRegisterSeq: number;
  method: string;
  memo: string;
  counselAt: string;
  tendency: string;
  serviceType: string; //'OMS'
  topics: string[];
  votingWrittenType: string;
  meetAttendType: string;
}

export const useCounselUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  CounselUpdateParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, counselSeq, ...other } = params;

      const { data } = await axiosInstance.put(
        `/unions/${unionSeq}/counsels/${counselSeq}`,
        other,
      );

      return data;
    },
  });

export interface CounselDeleteParams {
  unionSeq: number;
  counselSeq: number;
}

export interface CounselDeleteReturnData {}

export const useCounselDeleteMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  CounselDeleteParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, counselSeq } = params;

      const { data } = await axiosInstance.delete(
        `/unions/${unionSeq}/counsels/${counselSeq}`,
      );

      return data;
    },
  });
