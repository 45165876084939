import { extendTheme, tabClasses, tabsClasses, type Theme } from '@mui/joy';
import { buttonClasses } from '@mui/joy/Button';
import {
  experimental_extendTheme as materialExtendTheme,
  type Theme as MaterialTheme,
} from '@mui/material/styles';
import * as _ from 'lodash-es';
import 'pretendard/dist/web/variable/pretendardvariable-dynamic-subset.css';

import LinkBehavior from 'design-system/components/navigation/LinkBehavior';
import localization from 'design-system/providers/localizaion';

const DEFAULT_FONT_FAMILY =
  '"Pretendard Variable", Pretendard, var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")';

const defaultMaterialTheme = materialExtendTheme(
  {
    typography: {
      fontFamily: DEFAULT_FONT_FAMILY,
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: { fontSize: 'var(--Icon-fontSize, var(--joy-fontSize-xl2))' },
        },
      },
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: '#29978D',
          },
        },
      },
      dark: {
        palette: {
          primary: {
            main: '#29978D',
          },
        },
      },
    },
  },
  localization.datagridKoKR,
  localization.pickersKoKR,
  localization.materialKoKR,
);

export const defaultTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#F1F7F6',
          '100': '#E3F2F1',
          '200': '#DAF3F0',
          '300': '#B1E1DD',
          '400': '#69BDB5',
          '500': '#29978D',
          '600': '#117268',
          '700': '#004D40',
          '800': '#052D28',
          '900': '#021A16',
        },
        neutral: {
          '50': '#FBFBFB',
          '100': '#ECECEC',
          '200': '#E2E3E3',
          '300': '#CED2D1',
          '400': '#B2BCBC',
          '500': '#6E7979',
          '600': '#4C5C5C',
          '700': '#2C3434',
          '800': '#181D1D',
          '900': '#0B0D0D',
        },
        blue: {
          '50': '#EDF5FD',
          '100': '#E3EFFB',
          '200': '#C7DFF7',
          '300': '#97C3F0',
          '400': '#4393E4',
          '500': '#0B6BCB',
          '600': '#185EA5',
          '700': '#12467B',
          '800': '#0A2744',
          '900': '#051423',
          solidBg: 'var(--joy-palette-blue-500)',
          solidActiveBg: 'var(--joy-palette-blue-700)',
          outlinedBorder: 'var(--joy-palette-blue-300)',
          outlinedColor: 'var(--joy-palette-blue-500)',
          outlinedActiveBg: 'var(--joy-palette-blue-200)',
          softColor: 'var(--joy-palette-blue-700)',
          softBg: 'var(--joy-palette-blue-100)',
          softActiveBg: 'var(--joy-palette-blue-300)',
          plainColor: 'var(--joy-palette-blue-500)',
          plainActiveBg: 'var(--joy-palette-blue-200)',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          '50': '#F1F7F6',
          '100': '#E3F2F1',
          '200': '#DAF3F0',
          '300': '#B1E1DD',
          '400': '#69BDB5',
          '500': '#29978D',
          '600': '#117268',
          '700': '#004D40',
          '800': '#052D28',
          '900': '#021A16',
        },
        neutral: {
          '50': '#FBFBFB',
          '100': '#ECECEC',
          '200': '#E2E3E3',
          '300': '#CED2D1',
          '400': '#B2BCBC',
          '500': '#6E7979',
          '600': '#4C5C5C',
          '700': '#2C3434',
          '800': '#181D1D',
          '900': '#0B0D0D',
        },
        blue: {
          '50': '#EDF5FD',
          '100': '#E3EFFB',
          '200': '#C7DFF7',
          '300': '#97C3F0',
          '400': '#4393E4',
          '500': '#0B6BCB',
          '600': '#185EA5',
          '700': '#12467B',
          '800': '#0A2744',
          '900': '#051423',
          solidBg: 'var(--joy-palette-blue-500)',
          solidActiveBg: 'var(--joy-palette-blue-700)',
          outlinedBorder: 'var(--joy-palette-blue-700)',
          outlinedColor: 'var(--joy-palette-blue-200)',
          outlinedActiveBg: 'var(--joy-palette-blue-700)',
          softColor: 'var(--joy-palette-blue-200)',
          softBg: 'var(--joy-palette-blue-800)',
          softActiveBg: 'var(--joy-palette-blue-600)',
          plainColor: 'var(--joy-palette-blue-300)',
          plainActiveBg: 'var(--joy-palette-blue-700)',
        },
      },
    },
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    xl2: '1.5rem',
    xl3: '1.875rem',
    xl4: '2.25rem',
  },
  fontWeight: {
    sm: 300,
    md: 500,
    lg: 600,
    xl: 700,
  },
  lineHeight: {
    xs: 1.33334,
    sm: 1.42858,
    md: 1.5,
    lg: 1.55556,
    xl: 1.66667,
  },
  fontFamily: {
    display: DEFAULT_FONT_FAMILY,
    body: DEFAULT_FONT_FAMILY,
  },
  typography: {
    h1: {
      fontWeight: 'var(--joy-fontWeight-xl, 700)',
      fontSize: 'var(--joy-fontSize-xl4, 2.25rem)',
      lineHeight: 'var(--joy-lineHeight-xs, 1.33334)',
    },
    h2: {
      fontWeight: 'var(--joy-fontWeight-xl, 700)',
      fontSize: 'var(--joy-fontSize-xl3, 1.875rem)',
      lineHeight: 'var(--joy-lineHeight-xs, 1.33334)',
    },
    h3: {
      fontWeight: 'var(--joy-fontWeight-xl, 700)',
      fontSize: 'var(--joy-fontSize-xl2, 1.5rem)',
      lineHeight: 'var(--joy-lineHeight-md, 1.5)',
    },
    h4: {
      fontWeight: 'var(--joy-fontWeight-xl, 700)',
      fontSize: 'var(--joy-fontSize-xl, 1.25rem)',
      lineHeight: 'var(--joy-lineHeight-lg, 1.55556)',
    },
    'title-lg': {
      fontWeight: 'var(--joy-fontWeight-xl, 700)',
      fontSize: 'var(--joy-fontSize-lg, 1.125rem)',
      lineHeight: 'var(--joy-lineHeight-xl, 1.66667)',
    },
    'title-md': {
      fontWeight: 'var(--joy-fontWeight-lg, 600)',
      fontSize: 'var(--joy-fontSize-md, 1rem)',
      lineHeight: 'var(--joy-lineHeight-md, 1.55556)',
    },
    'title-sm': {
      fontWeight: 'var(--joy-fontWeight-lg, 600)',
      fontSize: 'var(--joy-fontSize-sm, 0.875rem)',
      lineHeight: 'var(--joy-lineHeight-sm, 1.42858)',
    },
    'body-lg': {
      fontWeight: 'var(--joy-fontWeight-md, 500)',
      fontSize: 'var(--joy-fontSize-lg, 1.125rem)',
      lineHeight: 'var(--joy-lineHeight-lg, 1.55556)',
    },
    'body-md': {
      fontWeight: 'var(--joy-fontWeight-md, 500)',
      fontSize: 'var(--joy-fontSize-md, 1rem)',
      lineHeight: 'var(--joy-lineHeight-md, 1.50)',
    },
    'body-sm': {
      fontWeight: 'var(--joy-fontWeight-md, 500)',
      fontSize: 'var(--joy-fontSize-sm, 0.875rem)',
      lineHeight: 'var(--joy-lineHeight-sm, 1.42858)',
    },
    'body-xs': {
      fontWeight: 'var(--joy-fontWeight-md, 500)',
      fontSize: 'var(--joy-fontSize-xs, 0.75rem)',
      lineHeight: 'var(--joy-lineHeight-xl, 1.66667)',
    },
  },
  zIndex: {
    appBar: 900,
    navigationBar: 1000,
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'var(--Icon-fonSize, var(--joy-fontSize-xl2))',
        },
      },
    },

    JoyLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },

    JoyDialogActions: {
      styleOverrides: {
        root: {
          [`&>.${buttonClasses.root}:only-child`]: {
            flex: 'none',
          },
        },
      },
    },

    JoyTabs: {
      styleOverrides: {
        root: {
          [`&.${tabsClasses.variantPlain}, &.${tabsClasses.variantOutlined}`]: {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    JoyTabList: {
      styleOverrides: {
        root: {
          [`&& .${tabClasses.selected}.${tabClasses.variantPlain}`]: {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    JoyStepIndicator: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&>svg': {
            width: '1.25em',
            height: '1.25em',

            ...(ownerState.variant === 'plain' && {
              width: '2em',
              height: '2em',
            }),
          },
        }),
      },
    },

    JoyTextarea: {
      styleOverrides: {
        startDecorator: ({ theme, ownerState }) => ({
          color: theme.palette.neutral[400],

          ...(ownerState.variant === 'solid' && {
            color: theme.palette.common.white,
          }),
        }),
        endDecorator: ({ theme, ownerState }) => ({
          color: theme.palette.neutral[400],

          ...(ownerState.variant === 'solid' && {
            color: theme.palette.common.white,
          }),
        }),
      },
    },
  },
});

export interface CreateThemeProps {
  theme?: Theme;
  materialTheme?: MaterialTheme;
}

export const createTheme = (props?: CreateThemeProps) => {
  const { materialTheme, theme } = props || {};

  return {
    materialTheme: materialTheme
      ? _.merge(defaultMaterialTheme, materialTheme)
      : defaultMaterialTheme,
    theme: theme ? _.merge(defaultTheme, theme) : defaultTheme,
  };
};
