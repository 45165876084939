import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Option,
  Button,
  Select,
  FormControl,
  FormLabel,
  useGridUtils,
  Grid,
  Box,
  Pagination,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReadMeets } from 'apis/meet/api';
import { MEETS_HISTORY_TABLE_COLUMNS } from 'apis/meet/fixtures';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import PasswordConfirmModal from 'components/pages/common/PasswordConfirmModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useQueryControls from 'hooks/useQueryControls';
import meetHistoryDetail from 'pages/main/meet-management/history/detail';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultSearchParams = {
  meetName: '',
  moverName: '',
  meetStatus: 'ALL',
  openType: 'ALL',
  meetMethod: 'ALL',
  meetType: 'ALL',
};

const MeetHistoryPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const { getGroupCode } = useCommonCodes();

  const { statusGroup, openTypeGroup, methodGroup, meetTypeGroup } = useMemo(
    () => ({
      statusGroup: getGroupCode('MEET_STATUS'),
      openTypeGroup: getGroupCode('MEET_OPEN_TYPE'),
      methodGroup: getGroupCode('MEET_METHOD'),
      meetTypeGroup: getGroupCode('MEET_TYPE'),
    }),
    [getGroupCode],
  );

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-history',
  });

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const {
    searchState,
    setSearchState,
    paginationState,
    setPaginationState,
    initState,
  } = useQueryControls({
    defaultSearchParams: { unionSeq, ...defaultSearchParams },
    initSearchParams: { unionSeq, ...defaultSearchParams },
  });

  const searchParams = useMemo(() => {
    const { meetStatus, openType, meetMethod, meetType, ...other } =
      searchState;
    const params = {
      ...other,
      meetStatus: meetStatus === 'ALL' ? '' : meetStatus,
      openType: openType === 'ALL' ? '' : openType,
      meetMethod: meetMethod === 'ALL' ? '' : meetMethod,
      meetType: meetType === 'ALL' ? '' : meetType,
    };
    return params;
  }, [searchState]);

  const { data, isLoading, isPending, isError, error } =
    useReadMeets(searchParams);

  const handleConfirmModal = useCallback((id: number | string) => {
    setIsShowConfirmModal(true);

    setSelectedId(Number(id));
  }, []);

  const handleSearchReset = useCallback(() => {
    initState();
  }, [initState]);

  const rows = useMemo(() => data?.data || [], [data]);

  const handlers = useMemo(
    () => ({ onLinkClick: handleConfirmModal }),
    [handleConfirmModal],
  );
  const { columns } = useCreateGridColumns({
    columns: MEETS_HISTORY_TABLE_COLUMNS,
    handlers,
  });

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;
  const count = Math.ceil((data?.pagination?.totalElements || 0) / 10);

  if (isError) throw error;

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Search
            values={searchState}
            onSubmit={setSearchState}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="총회명을 입력하세요"
                      label="총회명"
                      name="meetName"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="소집/발의자를 입력하세요"
                      label="소집/발의자"
                      name="moverName"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>상태</FormLabel>
                    <Search.SelectField>
                      <Select fullWidth name="meetStatus">
                        <Option value="ALL">전체</Option>
                        {statusGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Search.SelectField>
                  </FormControl>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>총회 방식</FormLabel>

                    <Search.SelectField>
                      <Select fullWidth name="meetMethod">
                        <Option value="ALL">전체</Option>
                        {methodGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Search.SelectField>
                  </FormControl>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>회의 유형</FormLabel>
                    <Search.SelectField>
                      <Select name="openType" fullWidth>
                        <Option value="ALL">전체</Option>
                        {openTypeGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Search.SelectField>
                  </FormControl>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>총회 종류</FormLabel>
                    <Search.SelectField>
                      <Select fullWidth name="meetType">
                        <Option value="ALL">전체</Option>
                        {meetTypeGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Search.SelectField>
                  </FormControl>
                </Grid>
              </Stack>
            </Grid>
          </Search>

          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Box>
            <DataGrid
              apiRef={datagridApiRef}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.meetSeq}
              loading={isLoading || isPending}
              disableRowSelectionOnClick
              pagination
              paginationModel={paginationState}
              onPaginationModelChange={setPaginationState}
            />
          </Box>
        </Stack>
        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={count}
            page={paginationState.page + 1}
            onChange={(_, page) => {
              page &&
                setPaginationState({
                  page: page - 1,
                  pageSize: paginationState.pageSize,
                });
            }}
          />
        </Stack>
        <Stack direction="row" alignSelf="end" gap={2}>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              exportExcel();
            }}
          >
            내역 다운
          </Button>
        </Stack>
      </Stack>

      <PasswordConfirmModal
        meetSeq={selectedId}
        open={isShowConfirmModal}
        onClose={setIsShowConfirmModal}
      />
    </>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <MeetHistoryPage />,
  handle: {
    getTitle: () => '총회 내역관리',
  },
};

const meetHistory = {
  path: '/unions/:unionSeq/meet-management/history',
  children: [route, meetHistoryDetail],
  handle: {
    getTitle: () => '총회 내역 관리',
    getMenuCode: () => 'M1004',
  },
};

export default meetHistory;
