import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { UnionRegisterInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionRegisterParams {
  // Path
  unionSeq: number;
  unionRegisterSeq: number;
}

export type UnionRegisterReturnData = UnionRegisterInfo;

export const useUnionRegisterQuery = ({
  unionSeq,
  unionRegisterSeq,
}: UnionRegisterParams): UseQueryResult<
  ApiResponseData<UnionRegisterReturnData>,
  ApiError
> =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/registers/${unionRegisterSeq}`],
    enabled: !!unionRegisterSeq,
  });

export type UnionRegistersParams = Partial<{
  unionRegisterNo: string;
  name: string;
  phoneNo: string;
  localAddress: string;
  realAddress: string;
}>;

export const useUnionRegistersQuery = (
  unionSeq: number,
  params?: UnionRegistersParams,
): UseQueryResult<ApiResponseData<UnionRegisterInfo[]>, AxiosError> =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/registers`, params],
    // initialData: generateInitRealData(unionRegisterGroupRegistersData),
  });

export interface UnionRegisterUpdateMutationParams {
  unionSeq: number;
  unionRegisterSeq: number;
  unionRegisterNoMain?: number;
  unionRegisterNoSub?: number;
  name?: string;
  nameClass?: string;
  companyNo?: string;
  birth?: string;
  memo?: string;
  gender?: string; // UNION_REGISTER_SHARE_TYPE
  position?: string; // UNION_REGISTER_USER_DIVIDE
  positionDescription?: string;
  shareType: string;
  ownerType?: string;
  isRepresentative?: boolean;
  hasVotingRight?: boolean;
  hasSalesRight?: boolean;
  salesCount?: number;
  mainPhone?: {
    phoneNo: string;
    isContactAllowed: boolean;
  };
  subPhones?: {
    phoneSeq?: number;
    phoneNo: string;
    isContactAllowed: boolean;
    memo?: string;
  }[];
  localAddresses: {
    localAddressSeq?: number;
    city: string;
    gu: string;
    dong: string;
    jibun?: string;
    zoneRoad?: string;
    building?: string;
    apartmentDong?: string;
    apartmentFloor?: string;
    apartmentHosu?: string;
    checkStatus: 'Y' | 'N';
    registrationAddresses: {
      registrationAddressSeq?: number;
      registrationAddress: string;
      registrationNo: string;
      registrationType: string; // REGISTRATION_TYPE
      registrationState: '현행' | '폐쇄' | '현행+폐쇄';
    }[];
  }[];
  realAddress?: {
    city?: string;
    gu?: string;
    dong?: string;
    address?: string;
    detailAddress?: string;
  };
  postAddress?: {
    address?: string;
    detailAddress?: string;
    zipNo?: string;
  };
  etcAddress?: string;
}

export const useUnionRegisterUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  UnionRegisterUpdateMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterSeq, ...body } = request;
      const { data } = await axiosInstance.put(
        `/unions/${unionSeq}/registers/${unionRegisterSeq}`,
        body,
      );

      return data;
    },
  });

export interface UnionRegisterCreateMutationParams {
  unionSeq: number;
  unionRegisterNoMain?: number;
  unionRegisterNoSub?: number;
  name?: string;
  nameClass?: string;
  companyNo?: string;
  birth?: string;
  memo?: string;
  gender?: string; // UNION_REGISTER_SHARE_TYPE
  position?: string; // UNION_REGISTER_USER_DIVIDE
  positionDescription?: string;
  shareType: string;
  ownerType?: string;
  isRepresentative?: boolean;
  hasVotingRight?: boolean;
  hasSalesRight?: boolean;
  salesCount?: number;
  mainPhone?: {
    phoneNo: string;
    isContactAllowed: boolean;
  };
  subPhones?: {
    phoneSeq?: number;
    phoneNo: string;
    isContactAllowed: boolean;
    memo?: string;
  }[];
  localAddresses: {
    localAddressSeq?: number;
    city: string;
    gu: string;
    dong: string;
    jibun?: string;
    zoneRoad?: string;
    building?: string;
    apartmentDong?: string;
    apartmentFloor?: string;
    apartmentHosu?: string;
    checkStatus: 'Y' | 'N';
    registrationAddresses: {
      registrationAddressSeq?: number;
      registrationAddress: string;
      registrationNo: string;
      registrationType: string; // REGISTRATION_TYPE
      registrationState: '현행' | '폐쇄' | '현행+폐쇄';
    }[];
  }[];
  realAddress?: {
    city?: string;
    gu?: string;
    dong?: string;
    address?: string;
    detailAddress?: string;
  };
  postAddress?: {
    address?: string;
    detailAddress?: string;
    zipNo?: string;
  };
  etcAddress?: string;
}

export interface UnionRegisterCreateMutationReturnData {
  seq: number;
}

export const useUnionRegisterCreateMutaion = (): UseMutationResult<
  ApiResponseData<UnionRegisterCreateMutationReturnData>,
  ApiError,
  UnionRegisterCreateMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/registers`,
        body,
      );

      return data;
    },
  });

export interface UnionRegistersDeleteMutationParams {
  unionSeq: number;
  unionRegisterSeqs: number[];
}

export const useUnionRegistersDeleteMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  UnionRegistersDeleteMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterSeqs } = request;

      const { data } = await axiosInstance.delete(
        `/unions/${unionSeq}/registers`,
        { data: { unionRegisterSeqs } },
      );

      return data;
    },
  });

export interface UnionRegisterStatusQueryParams {
  unionSeq: number;
}
export interface UnionRegisterStatusQueryReturnData {
  status: string;
}

export const useRegisterStatusQuery = (
  params: UnionRegisterStatusQueryParams,
): UseQueryResult<
  ApiResponseData<UnionRegisterStatusQueryReturnData>,
  ApiError
> => {
  const { unionSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/registers/file-status`],
  });
};

export interface UnionRegisterStatusUpdateMutationParams {
  unionSeq: number;
  status: string;
}

export const useUpdateRegisterStatusMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  UnionRegisterStatusUpdateMutationParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, status } = params;
      const { data } = await axiosInstance.patch(
        `/unions/${unionSeq}/registers/file-status`,
        {
          status,
        },
      );
      return data;
    },
  });
