import {
  Box,
  FileDragDrop,
  FileList,
  Stack,
  Typography,
  Button,
} from '@wooriga/design-system';
import { useCallback, useState } from 'react';

import { useRegistersFileUploadMutation } from 'apis/common/apis';
import { useRegisterStatusQuery } from 'apis/union/register/apis';
import HelpTooltip from 'components/HelpTooltip';
import useFeedback from 'hooks/useFeedback';

export interface NoneStepProps {
  unionSeq: number;
}

const NoneStep = ({ unionSeq }: NoneStepProps) => {
  const { snackbar, setLoading } = useFeedback();

  const [registerFile, setRegisterFile] = useState<File[]>([]);

  const { refetch: refetchStatus } = useRegisterStatusQuery({ unionSeq });
  const { mutateAsync: uploadRegistersFile } = useRegistersFileUploadMutation();

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }
    const targetFile = upcomingFiles && upcomingFiles[0];
    setRegisterFile([targetFile]);
  };

  const handleSubmitRegisterFile = useCallback(() => {
    const targetFile = registerFile && registerFile[0];

    if (!targetFile) {
      return;
    }

    console.log(targetFile);

    setLoading(true);

    uploadRegistersFile(
      {
        unionSeq,
        file: targetFile,
      },
      {
        onSuccess: () => {
          snackbar('등록이 완료되었습니다.', { color: 'success' });
          refetchStatus();
        },
        onError: (error) => {
          snackbar(error.message, { color: 'danger' });
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  }, [
    refetchStatus,
    registerFile,
    setLoading,
    snackbar,
    unionSeq,
    uploadRegistersFile,
  ]);

  return (
    <Stack width="100%" overflow="hidden" gap={2}>
      <Box display="flex">
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          조합원 명부 등록
        </Typography>

        <HelpTooltip
          variant="outlined"
          color="neutral"
          title="조합원 명부 등록"
          contents={
            <>
              <Typography>
                {`관리자 시스템은 조합원 명부를 등록하여 등급이 확인된 '조합장/위원장','운영진'등급만 이용하실 수 있습니다.`}
              </Typography>
              <Typography>
                조합원 명부를 등록하고 다양하고 간편한 조합 운영 관리 기능을
                이용하세요.
              </Typography>
              <Typography>
                기존 조합원 명부 엑셀 파일을 업로드하면 우리가 명부 양식으로
                변환하여 드립니다.
              </Typography>
              <Typography>
                별도로 사용하는 명부가 없는 경우 명부 양식을 다운로드하여 정보를
                입력해주세요.
              </Typography>
              <Typography>
                원활한 서비스를 위해 조합원 이름, 연락처, 생년월일, 정비구역
                소재지, 실거주지를 포함하여 업로드해주세요.
              </Typography>
              <Typography>
                파일은 최대 1개까지 업로드 가능합니다. 명부가 여러 파일에 나눠져
                있을 경우 하나의 파일로 작성하여 업로드해주세요.
              </Typography>
            </>
          }
        />
      </Box>
      <Stack flex="1 1 40%" gap={2}>
        <Stack>
          <FileDragDrop
            variant="outlined"
            color="neutral"
            orientation="horizontal"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            fullWidth
            onSelectFiles={handleSelectFiles}
          />
        </Stack>
        <Stack>
          <FileList
            variant="outlined"
            color="neutral"
            value={registerFile}
            labelGetter={(value) => value.name}
            onDelete={() => {
              setRegisterFile([]);
            }}
            fullWidth
            sx={{ height: '56px', overflowY: 'auto' }}
          />
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          <Button
            size="lg"
            disabled={registerFile.length === 0}
            onClick={handleSubmitRegisterFile}
          >
            등록 요청
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NoneStep;
