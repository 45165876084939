import { GridColDef } from '@wooriga/design-system';

import { GridColumnsHandlers } from 'types/grid';

export interface CreateGridColumnsProps<H> {
  handlers: GridColumnsHandlers<H>;
  columns: (handlers: GridColumnsHandlers<H>) => GridColDef[];
}

const createGridColumns = <H>(props: CreateGridColumnsProps<H>) => {
  const { handlers, columns } = props;

  return { columns: columns(handlers), handlers };
};

export default createGridColumns;
