import InfoIcon from '@mui/icons-material/Info';
import TuneIcon from '@mui/icons-material/Tune';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  Callout,
  CheckOption,
  DataGrid,
  Select,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { CreatePostSendBody } from 'apis/post/apis';
import { POST_ELECTOR_COLUMNS } from 'apis/post/fixtures';
import { MeetMethods, MeetParticipantResponse } from 'apis/types/meet';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import useStepperDialog from 'hooks/useStepperDialog';
import { MeetsParticipantsParams } from 'lim/generalMeetingHistoryDetail/apis';

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};

export interface PostElectorStepProps<T, V extends MeetMethods = 'ELECTRONIC'> {
  rows?: T[];
  unionSeq: number;
  isElectronic: boolean;
  onSearch?: (params: MeetsParticipantsParams<V>) => void;
}
const PostElectorStep = <
  T extends MeetParticipantResponse,
  V extends MeetMethods = 'ELECTRONIC',
>(
  props: PostElectorStepProps<T, V>,
) => {
  const { rows = [], unionSeq, isElectronic, onSearch } = props;

  const { values, updateValues, stepNext, PreviousButton } =
    useStepperDialog<CreatePostSendBody>();

  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(
      values.recipients.map(({ unionRegisterSeq }) => unionRegisterSeq || 0) ??
        [],
    );
  const [filters, setFilters] = useState({
    electronicVoteProgressStatus: [],
    writtenSubmissionType: [],
  });

  const { snackbar, alertDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();

  const { groupCode, filterName } = useMemo(() => {
    const groupCode = isElectronic
      ? 'MEET_PARTICIPANT_VOTE_STATUS'
      : 'WRITTEN_SUBMIT_TYPE';
    const filterName = isElectronic
      ? 'electronicVoteProgressStatus'
      : 'writtenSubmissionType';

    return { groupCode, filterName };
  }, [isElectronic]);
  const filterValue = useMemo(
    () =>
      isElectronic
        ? filters.electronicVoteProgressStatus
        : filters.writtenSubmissionType,
    [filters, isElectronic],
  );
  const meetParticipantVoteStatusCodeGroup = useMemo(
    () => getGroupCode(groupCode),
    [getGroupCode, groupCode],
  );

  const handleOpenUnionInfoModal = useCallback(
    (seq: number | undefined) => {
      if (!seq) {
        alertDialog('조합원이 아니거나 조합원 정보가 없습니다.', {
          message: '조합원 정보',
        });
        return;
      }

      setSelectedUnionState({ open: true, seq });
    },
    [alertDialog],
  );

  const handleChangeFilter =
    (name: string) => (_: unknown, value: string[]) => {
      const newValue = { ...filters, [name]: value };

      setFilters(newValue);
      // electronicVoteProgressStatus가 string[]일 경우에 대한 type 대처가 어려움

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSearch?.(newValue);
    };

  const handleSubmit = () => {
    if (rowSelectionModel.length === 0) {
      snackbar('선택된 수신자가 없습니다. 수신자를 선택해 주세요.', {
        color: 'danger',
      });
      return;
    }

    const recipients = rows
      .filter(({ unionRegisterSeq }) =>
        rowSelectionModel.includes(Number(unionRegisterSeq)),
      )
      .map(({ postAddress, unionRegisterSeq, name, phoneNo }) => ({
        ...postAddress,
        unionRegisterSeq,
        name: name?.name,
        phoneNo,
      }));

    updateValues({ ...values, recipients, sendQuantity: recipients.length });
    stepNext();
  };

  const { columns } = useCreateGridColumns({
    columns: POST_ELECTOR_COLUMNS,
    handlers: {
      isElectronic,
      onClickUnionMember: handleOpenUnionInfoModal,
    },
  });

  return (
    <>
      <Stack gap={2} maxWidth={1080}>
        <Callout color="warning" startDecorator={<InfoIcon />}>
          조합원 명부에 등록된 우편물 수령지가 표시됩니다.
          <br />
          우편물 수령지는 선거인 정보 수정 탭에서 변경할 수 있으며, 변경된
          주소는 일회성으로만 적용되고 조합원 명부에는 저장되지 않습니다.
          다음에도 이 주소를 사용하시려면 조합원 명부의 상세 화면에서
          수정해주세요.
        </Callout>

        <Stack gap={1}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <TuneIcon />
              <Typography level="title-sm">필터</Typography>
            </Stack>

            <Select<string, true>
              multiple
              placeholder="전체"
              name={filterName}
              value={filterValue}
              onChange={handleChangeFilter(filterName)}
              startDecorator={
                <Typography level="body-md">제출현황 :</Typography>
              }
            >
              {meetParticipantVoteStatusCodeGroup?.items.map(
                ({ code, name }) => (
                  <CheckOption key={`filter_option_${code}`} value={code}>
                    {name}
                  </CheckOption>
                ),
              )}
            </Select>
          </Stack>

          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowSelectionModel={rowSelectionModel}
              getRowId={(row) => row.unionRegisterSeq}
              onRowSelectionModelChange={setRowSelectionModel}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <PreviousButton />
          <Button onClick={handleSubmit}>다음</Button>
        </Stack>
      </Stack>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq,
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}
    </>
  );
};

export default PostElectorStep;
