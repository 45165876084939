import InfoIcon from '@mui/icons-material/Info';
import styled from '@mui/joy/styles/styled';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  DataGrid,
  Stack,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { UnionRegisterInfo } from 'apis/types/union';
import { UNION_REGISTER_TABLE_COLUMNS } from 'apis/union/register/fixtures/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import { isMissed } from 'components/pages/meet-management/open/utils';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useLayoutContext from 'hooks/useLayoutContext';
import { commaizeNumber } from 'utils/format';

interface VoterAddModalProps {
  rows: UnionRegisterInfo[];
  open: boolean;
  onClose: (open: false) => void;
}

const RegisterListModal = ({ rows, open, onClose }: VoterAddModalProps) => {
  const { pageContext } = useLayoutContext();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'open-agenda-register-list',
    activeSaveSnapshot: false,
  });

  const unionSeq = Number(pageContext?.memberUnionInfo?.unionSeq);

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [clickedUnionId, setClickedUnionId] = useState(0);

  const handleNameClick = useCallback((id: string | number) => {
    setShowUnionInfoModal(true);
    setClickedUnionId(Number(id));
  }, []);

  const handlers = useMemo(
    () => ({ onLinkClick: handleNameClick }),
    [handleNameClick],
  );

  const { columns } = useCreateGridColumns({
    handlers,
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog>
          <DialogTitle>선거인 명부</DialogTitle>
          <DialogContent>
            <Stack gap={1} marginBottom={3}>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                빨간색으로 표시 항목은 누락 된 정보가 있는 조합원 입니다. 다시
                한번 확인해 주세요.
              </Typography>
              <Typography
                level="body-md"
                color="danger"
                startDecorator={<InfoIcon />}
              >
                이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력 된 경우
                전자투표의 수신 또는 열람이 불가합니다. 조합원 명부 관리
                페이지의 조합원 정보에서 수정해 주세요.
              </Typography>

              <Typography level="body-md" startDecorator={<InfoIcon />}>
                대리인이 등록된 조합원의 경우 대리인유무에 ‘○’ 가 표시됩니다.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                <strong>
                  대리인을 선임하지 않은 법인의 경우 본인인증이 불가하여
                  전자투표 진행이 어려울 수 있습니다.
                </strong>
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack gap={1.75} flexDirection="row" alignItems="center">
                <Stack flexDirection="row" gap={1}>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    전체{' '}
                    <Typography color="primary">
                      {commaizeNumber(rows.length)}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>

              <Stack height={442}>
                <StyledDataGrid
                  rows={rows}
                  apiRef={datagridApiRef}
                  columns={columns}
                  disableRowSelectionOnClick
                  getRowId={(row) => row.unionRegisterSeq}
                  getRowClassName={(params) =>
                    isMissed(params.row) ? `theme--isMissed` : ''
                  }
                  keepNonExistentRowsSelected
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth onClick={() => onClose(false)}>
              확인
            </Button>
            <Button
              fullWidth
              color="neutral"
              variant="outlined"
              onClick={() => exportExcel()}
            >
              내려받기
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: clickedUnionId }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .theme--isMissed': {
    backgroundColor: theme.palette.danger[100],
    '&:hover': {
      backgroundColor: theme.palette.danger[200],
    },
  },
}));

export default RegisterListModal;
