import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from '@wooriga/design-system';
import { useState } from 'react';

import { MeetRequest } from 'apis/types/meet';

import PreviewAgenda from './PreviewAgenda';

// import Agenda from './Agenda';

interface VotePreviewModalProps {
  agendas: MeetRequest['agendas'];
  open: boolean;
  onClose: (show: false) => void;
  onConfirm: () => void;
}

const VotePreviewModal = ({
  agendas,
  open,
  onClose,
  onConfirm,
}: VotePreviewModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(
    agendas.length === 1,
  );

  const handlePrevStep = () => {
    if (currentStep === 1) {
      setIsPrevButtonDisabled(true);
    } else {
      setIsPrevButtonDisabled(false);
    }
    setIsNextButtonDisabled(false);
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (currentStep === agendas.length - 2) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
    setIsPrevButtonDisabled(false);
    setCurrentStep(currentStep + 1);
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog>
        <DialogTitle>전자투표 미리보기</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {agendas.map((agenda, i) => (
              <Stack
                display={currentStep === i ? 'flex' : 'none'}
                key={agenda.order}
              >
                <PreviewAgenda agenda={agenda} />
              </Stack>
            ))}

            <Stack flexDirection="row" gap={1}>
              <Button
                disabled={isPrevButtonDisabled}
                fullWidth
                onClick={handlePrevStep}
              >
                이전
              </Button>

              <Button
                disabled={agendas.length === 1 || isNextButtonDisabled}
                fullWidth
                onClick={handleNextStep}
              >
                다음
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onConfirm()}>확인</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default VotePreviewModal;
