import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import useFeedback from 'hooks/useFeedback';
import { commaizeNumber } from 'utils/format';

interface RequestModalProps {
  requestor: string;
  certificationType: string;
  cost: number;
  requestCount: number;
  availableCount: number;
  open: boolean;
  onClose: (show: false) => void;
  onSubmit: () => void;
}

const RequestModal = ({
  open,
  requestor,
  certificationType,
  requestCount,
  availableCount,
  cost,
  onClose,
  onSubmit,
}: RequestModalProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const { snackbar } = useFeedback();

  const price = `${commaizeNumber(cost)}원`;
  const prettyRequestCount = commaizeNumber(requestCount);
  const prettyAvailableCount = commaizeNumber(availableCount);
  const JSON = generateDescriptionsJSON(
    ['발급 신청자', '증명서 유형', '신청 건수', '신청 금액'],
    [
      requestor,
      certificationType,
      `${prettyRequestCount}건 (총 ${prettyRequestCount}건 중 ${prettyAvailableCount}건 발급 가능)`,
      price,
    ],
  );

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      snackbar('주의사항을 확인하시고 내용에 동의해 주세요.', {
        color: 'danger',
      });
      return;
    }

    onSubmit();
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>발급 신청</DialogTitle>

        <DialogContent>
          <Stack gap={3} sx={{ py: 1 }}>
            <Stack gap={1}>
              <Typography level="body-sm" textColor="text.primary">
                발급 신청 정보
              </Typography>

              <DescriptionsRenderer columns={1} json={JSON} />
            </Stack>

            <Stack alignItems="center" gap={2}>
              <Typography level="body-sm" textAlign="center" sx={{ px: 8 }}>
                발급 내용 및 증명서 정보에 오류가 없는지 다시 한번 확인해주세요.
                <br />
                신청 이후 <strong>취소 및 환불이 불가능</strong>합니다.
              </Typography>

              <Checkbox
                size="sm"
                label="위 사항을 모두 확인하였습니다."
                checked={isChecked}
                onChange={handleChecked}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>확인</Button>
          <Button variant="outlined" color="neutral" onClick={handleClose}>
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default RequestModal;
