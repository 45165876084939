import { useTheme } from '@mui/joy';
import {
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@wooriga/design-system';
import { useParams } from 'react-router-dom';

import MeetTypeTab from 'components/pages/meet-management/open/tabs/MeetTypeTab';
import agenda from 'pages/main/meet-management/open/agenda';

const OpenPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <Stack maxWidth={600} marginBottom={3}>
        <Stepper>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                1
              </StepIndicator>
            }
          >
            선거인 명부 확정
          </Step>
          <Step
            indicator={
              <StepIndicator variant="soft" color="primary">
                2
              </StepIndicator>
            }
          >
            안건 입력
          </Step>
          <Step
            indicator={
              <StepIndicator variant="soft" color="primary">
                3
              </StepIndicator>
            }
          >
            총회 개설
          </Step>
        </Stepper>
      </Stack>

      <Tabs
        defaultValue={0}
        selectedTabColor="primary"
        selectedTabVariant="plain"
        tabIndicatorInset
        sx={{
          background: theme.palette.background.body,
        }}
      >
        <TabList>
          <Tab value={0}>총회</Tab>
          <Tab value={1}>대의원회</Tab>
          <Tab value={2}>이사회</Tab>
        </TabList>
        <TabPanel value={0}>
          <MeetTypeTab unionSeq={unionSeq} openType="GENERAL" />
        </TabPanel>
        <TabPanel value={1}>
          <MeetTypeTab unionSeq={unionSeq} openType="DELEGATE" />
        </TabPanel>
        <TabPanel value={2}>
          <MeetTypeTab unionSeq={unionSeq} openType="BOARD" />
        </TabPanel>
      </Tabs>
    </Stack>
  );
};

const open = {
  path: '/unions/:unionSeq/meet-management/open',
  children: [
    {
      path: '',
      index: true,
      element: <OpenPage />,
      handle: {
        getTitle: () => '총회 개설',
      },
    },
    agenda,
  ],
  handle: {
    getTitle: () => '총회 개설',
    getMenuCode: () => 'M1003',
  },
};

export default open;
