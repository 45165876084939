import {
  Button,
  DataGrid,
  DateRangePicker,
  Grid,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  DocumentIssuancesParams,
  useDocumentIssuancesQuery,
} from 'apis/document-issuance/apis';
import { DOCUMENT_ISSUANCE_TABS } from 'apis/document-issuance/constants';
import { DOCUMENT_ISSUANCES_COLUMNS } from 'apis/document-issuance/fixtures';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useLayoutContext from 'hooks/useLayoutContext';
import documentIssuanceInfoPage from 'pages/main/union-management/document-issuances/records/[documentIssuanceSeq]';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DEFAULT_SEARCH_VALUES = {
  requestedAtFromTo: '',
  completedAtFromTo: '',
};
const DEFAULT_SEARCH_PARAMS = {
  requestedAtFrom: '',
  requestedAtTo: '',
  completedAtFrom: '',
  completedAtTo: '',
};

interface SearchParamsValues {
  requestedAtFromTo: string;
  completedAtFromTo: string;
}

const DocumentIssuanceRecordPage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { unionSeq } = params;
  const { unionBasename } = pageContext || {};

  const [searchParams, setSearchParams] = useState<DocumentIssuancesParams>({
    unionSeq: Number(unionSeq),
    ...DEFAULT_SEARCH_PARAMS,
  });

  const {
    data: records,
    isPending,
    isError,
    error,
  } = useDocumentIssuancesQuery(searchParams);

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'document-issuance-records',
    activeSaveSnapshot: false,
  });

  const rows = useMemo(() => records?.data || [], [records]);
  const { columns } = useCreateGridColumns({
    columns: DOCUMENT_ISSUANCES_COLUMNS,
  });

  const handleSearchParams = (values: SearchParamsValues) => {
    if (!values) {
      return;
    }

    const {
      requestedAtFromTo: [requestedAtFrom, requestedAtTo],
      completedAtFromTo: [completedAtFrom, completedAtTo],
    } = values;

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      requestedAtFrom,
      requestedAtTo,
      completedAtFrom,
      completedAtTo,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  if (isError) {
    throw error;
  }

  return (
    <Tabs
      color="neutral"
      selectedTabVariant="plain"
      selectedTabColor="primary"
      tabIndicatorInset
      defaultValue={1}
      value={1}
    >
      <TabList disableUnderline>
        {DOCUMENT_ISSUANCE_TABS.map(({ label, path }, index) => (
          <Tab
            key={`tab_${path}`}
            component={Link}
            value={index}
            href={`${unionBasename}${path}`}
          >
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanel value={1}>
        <Stack gap={2}>
          <Stack gap={1.75}>
            <Search
              defaultValues={DEFAULT_SEARCH_VALUES}
              onSubmit={handleSearchParams}
              onReset={handleSearchReset}
            >
              <Grid container gap={2}>
                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="신청일시"
                      name="requestedAtFromTo"
                      sx={{ field: { xs: 12, maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="완료일시"
                      name="completedAtFromTo"
                      sx={{ field: { xs: 12, maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>
              </Grid>
            </Search>

            <Stack flexDirection="row" gap={1}>
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                전체{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalDataCount || 0)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                조회 목록{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalElements || 0)}
                </Typography>
              </Typography>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <Stack height={442}>
              <DataGrid
                apiRef={datagridApiRef}
                rows={rows}
                columns={columns}
                loading={isPending}
                disableRowSelectionOnClick
                getRowId={(row) => row.documentIssuanceSeq}
              />
            </Stack>

            <Stack direction="row">
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </TabPanel>
    </Tabs>
  );
};

const documentIssuanceRecordPage: CustomRouteObject = {
  path: 'records',
  children: [
    {
      index: true,
      element: <DocumentIssuanceRecordPage />,
    },
    documentIssuanceInfoPage,
  ],
  handle: {
    getTitle: () => '내역 관리',
  },
};

export default documentIssuanceRecordPage;
