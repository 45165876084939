import {
  Button,
  DateTimePicker,
  Input,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useState } from 'react';

import { OnsiteVote } from 'apis/meet/certificate-meets/api';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';
import { UpdateMeetBody } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface OnsiteDescriptionsProps {
  onsiteVote: OnsiteVote;
  onChange: (onsiteVoteProps: UpdateMeetBody['onsiteVote']) => void;
}

const OnsiteDescriptions = ({
  onsiteVote,
  onChange,
}: OnsiteDescriptionsProps) => {
  const {
    submissionStartAt,
    submissionEndAt,
    meetAt,
    jibunAddress,
    roadAddress,
    detailAddress,
    contactNo,
  } = onsiteVote;

  const [formData, setFormData] = useState({
    meetAt: new Date(meetAt),
    submissionEndAt: new Date(submissionEndAt),
    jibunAddress: jibunAddress || '',
    roadAddress: roadAddress || '',
    detailAddress,
  });

  const handleEventChange = (value = {}) => {
    const newValue = {
      ...formData,
      ...value,
    };

    setFormData(newValue);
    onChange(newValue);
  };

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { roadAddress, jibunAddress } = result;

    handleEventChange({
      roadAddress,
      jibunAddress,
    });
  };

  const json = generateDescriptionsJSON(
    [
      '서면 제출 시작',
      '서면 제출 마감',
      '총회 일',
      '총회 장소',
      '조합 전화번호',
    ],
    [
      formatDateTime(submissionStartAt, 'yyyy-MM-dd HH:mm'),
      <DateTimePicker
        key="서면 제출 마감"
        sx={{ width: 300 }}
        value={formatDateTime(formData.submissionEndAt, 'yyyy-MM-dd HH:mm')}
        onChange={(value) => handleEventChange({ submissionEndAt: value })}
      />,

      <DateTimePicker
        key="총회 일"
        sx={{ width: 300 }}
        value={formatDateTime(formData.meetAt, 'yyyy-MM-dd HH:mm')}
        minDate={formData.submissionEndAt}
        onChange={(value) => handleEventChange({ meetAt: value })}
      />,
      <Stack key="총회 장소" gap={1}>
        <Stack width={300} gap={1} flexDirection="row">
          <Input
            fullWidth
            disabled
            defaultValue={formData.roadAddress}
            value={formData.roadAddress}
          />
          <AddressModalTrigger onComplete={handleChangeAddress}>
            <Button variant="outlined" color="neutral" sx={{ width: '80px' }}>
              검색
            </Button>
          </AddressModalTrigger>
        </Stack>
        <Input
          sx={{ width: 300 }}
          value={formData.detailAddress}
          onChange={({ target }) => {
            handleEventChange({
              detailAddress: target.value,
            });
          }}
        />
      </Stack>,
      contactNo,
    ],
  );

  return (
    <Stack>
      <Typography level="title-lg">현장 투표 정보</Typography>
      <DescriptionsRenderer columns={1} json={json} />
    </Stack>
  );
};

export default OnsiteDescriptions;
