import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useTheme } from '@mui/joy';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  DataGridPremium as XDataGrid,
} from '@mui/x-data-grid-premium';
import type {
  GridColDef as XGridColDef,
  GridRenderCellParams,
  GridSlots,
  DataGridPremiumProps as XDataGridProps,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import Typography from 'design-system/components/dataDisplay/Typography';
import JoyCheckbox from 'design-system/components/dataGrid/JoyCheckbox';
import JoyGridColumnMenu from 'design-system/components/dataGrid/JoyGridColumnMenu';
import JoyGridToolbar, {
  JoyGridToolbarProps,
} from 'design-system/components/dataGrid/JoyGridToolbar';
import Stack from 'design-system/components/layout/Stack';

export type GridColDef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends GridValidRowModel = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  V = any,
  F = V,
> = XGridColDef<R, V, F> & {
  multiline?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DataGridProps<R extends GridValidRowModel = any>
  extends Omit<XDataGridProps<R>, 'columns' | 'slotProps'> {
  defaultColumnOptions?: GridColDef<R>;
  columns: GridColDef<R>[];
  slotProps?: XDataGridProps['slotProps'] & {
    toolbar?: JoyGridToolbarProps;
  };
}

const DataGrid = (props: DataGridProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    defaultColumnOptions,
    rowHeight = 40,
    columnHeaderHeight = 40,
    hideFooter = true,
    paginationModel,
    autosizeOptions: autosizeOptionsProp,
    columns: columnsProp,
    initialState,
    rows,
    apiRef,
    checkboxSelection,
    loading,
    autosizeOnMount = true,
    slots: slotsProp,
    ...other
  } = props;

  const theme = useTheme();

  const pageSize = paginationModel?.pageSize;
  const height = pageSize && pageSize * rowHeight + columnHeaderHeight + 2;

  const slots = useMemo(
    () => ({
      baseCheckbox: JoyCheckbox as never,
      columnMenu: JoyGridColumnMenu as GridSlots['columnMenu'],
      booleanCellTrueIcon: CircleOutlinedIcon,
      booleanCellFalseIcon: () => undefined,
      toolbar: JoyGridToolbar,
      ...slotsProp,
    }),
    [slotsProp],
  );

  const ownerProps = useMemo(() => ({ slots }), [slots]);

  const customArrayCell = useCallback(
    (params: GridRenderCellParams) => {
      const { slots } = ownerProps;
      const { colDef, value } = params;
      const { type } = colDef;
      const {
        booleanCellTrueIcon: BooleanCellTrueIcon,
        booleanCellFalseIcon: BooleanCellFalseIcon,
      } = slots || {};

      if (!Array.isArray(value)) {
        return '';
      }

      return (
        <Stack flexDirection="column" height="var(--height)" mx={-1.25}>
          {value.map(
            (item: { key: number; value: number | string | boolean }) => {
              let renderValue;

              switch (type) {
                case 'string':
                  renderValue = item.value;
                  break;

                case 'number':
                  renderValue = Number(item.value) > 0 && Number(item.value);
                  break;

                case 'boolean':
                  renderValue =
                    item.value === true ? (
                      <BooleanCellTrueIcon
                        className="MuiDataGrid-booleanCell"
                        data-value="true"
                      />
                    ) : (
                      <BooleanCellFalseIcon className="MuiDataGrid-booleanCell" />
                    );
                  break;
              }

              return (
                <Stack
                  key={item.key}
                  flex={1}
                  minWidth="var(--width)"
                  maxWidth="var(--width)"
                  justifyContent="center"
                  px={1.25}
                  sx={{
                    ['&:not(:first-of-type)']: {
                      borderTop: '1px solid var(--rowBorderColor)',
                    },
                  }}
                >
                  <Typography
                    fontSize="inherit"
                    fontWeight="inherit"
                    lineHeight="initial"
                    whiteSpace="nowrap"
                    justifyContent="center"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {renderValue || ''}
                  </Typography>
                </Stack>
              );
            },
          )}
        </Stack>
      );
    },
    [ownerProps],
  );

  const defaultCheckboxDef = useMemo(
    () =>
      checkboxSelection && {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        maxWidth: 50,
      },
    [checkboxSelection],
  );

  const columns = useMemo(() => {
    const wrapColumns = columnsProp.map((column) => ({
      maxWidth: 1024,
      minWidth: 100,
      aggregable: false,
      groupable: false,
      headerAlign: 'left',
      ...defaultColumnOptions,
      ...(column?.multiline && { renderCell: customArrayCell }),
      ...column,
    }));

    if (defaultCheckboxDef) return [defaultCheckboxDef, ...wrapColumns];

    return wrapColumns;
  }, [columnsProp, customArrayCell, defaultCheckboxDef, defaultColumnOptions]);

  const autosizeOptions = useMemo(
    () => ({
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
      ...autosizeOptionsProp,
    }),
    [autosizeOptionsProp],
  );

  const handleAutosizeColumns = useCallback(() => {
    autosizeOnMount && apiRef?.current.autosizeColumns(autosizeOptions);
  }, [apiRef, autosizeOnMount, autosizeOptions]);

  useEffect(() => {
    if (paginationModel) {
      handleAutosizeColumns();
    }
  }, [handleAutosizeColumns, paginationModel]);

  useEffect(() => {
    if (!loading) {
      handleAutosizeColumns();
    }
  }, [handleAutosizeColumns, loading]);

  useEffect(() => {
    if (rows?.length && rows.length > 0 && apiRef) {
      handleAutosizeColumns();
    }
  }, [apiRef, handleAutosizeColumns, rows?.length]);

  return (
    <XDataGrid
      apiRef={apiRef}
      columns={columns as never[]}
      rows={rows}
      rowHeight={rowHeight}
      columnHeaderHeight={columnHeaderHeight}
      hideFooter={hideFooter}
      paginationModel={paginationModel}
      autosizeOptions={autosizeOptions}
      autosizeOnMount={autosizeOnMount}
      ref={ref}
      loading={loading}
      checkboxSelection={checkboxSelection}
      slots={slots}
      sx={{
        ...(height && {
          height: `calc(${height}px + max(var(--DataGrid-scrollbarSize), 14px))`,
        }),
        // MuiDataGrid-columnHeader
        // MuiDataGrid-columnHeader--alignCenter
        // MuiDataGrid-withBorderColor
        // MuiDataGrid-columnHeader--withRightBorder
        // MuiDataGrid-columnHeader--pinnedLeft
        // MuiDataGrid-columnHeaderCheckbox
        ['& .MuiDataGrid-booleanCell[data-value="true"]']: {
          width: '0.625em',
          height: '0.625em',
        },
        ['& .MuiDataGrid-columnHeaderTitle']: {
          fontWeight: theme.fontWeight.lg,
        },
        ['& .MuiDataGrid-cell']: {
          fontWeight: theme.fontWeight.md,
        },
        ['& .MuiDataGrid-columnHeader']: {
          fontWeight: theme.palette.divider,
        },
      }}
      initialState={{
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
        },
        ...initialState,
      }}
      {...other}
    />
  );
};

export default forwardRef<HTMLDivElement, DataGridProps>(DataGrid);
