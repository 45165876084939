import { Button, Chip, GridColDef, Link } from '@wooriga/design-system';

import { CertificateMeetData } from 'apis/meet/certificate-meets/api';
import { GridColumnsHandlers } from 'types/grid';
import { formatDate, formatDateTime } from 'utils/format';

export const CERTIFICATE_TABLE_COLUMNS = ({
  onClickName,
  onClickExtension,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (meetSeq: number) => void;
  onClickExtension: (meetSeq: number) => void;
}>) =>
  [
    {
      field: 'name',
      headerName: '총회명',
      width: 200,
      valueGetter: (_, row) => row.meet?.name || '',
      renderCell: (params) => {
        const meetSeq = params.row.meet.meetSeq;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(meetSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'meetMethod',
      headerName: '총회방식',
      valueGetter: (_, row) =>
        row.meet?.meetMethod &&
        getCommonCode('MEET_METHOD', row.meet.meetMethod)?.name,
    },
    {
      field: 'meetAt',
      headerName: '총회일자',
      valueGetter: (_, row) => row.meet?.meetAt && formatDate(row.meet.meetAt),
    },
    {
      field: 'meetType',
      headerName: '총회종류',
      valueGetter: (_, row) =>
        row.meet?.meetType &&
        getCommonCode('MEET_TYPE', row.meet.meetType)?.name,
    },
    {
      field: 'documentStorageEndAt',
      headerName: '문서보관 종료일',
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'documentStorageStatus',
      headerName: '문서보관 상태',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_STORAGE_STATUS', value)?.name,
    },
    {
      field: 'documentStorageExtensionStatus',
      headerName: '문서보관 연장',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_KEEP_EXTENSION_STATUS', value)?.name,
      renderCell: (params) => {
        if (params.value) return <Chip>{params.value}</Chip>;

        return (
          <Button onClick={() => onClickExtension(params.row.meet.meetSeq)}>
            신청
          </Button>
        );
      },
    },
  ] as GridColDef<CertificateMeetData>[];

export type CertificationDetailTableProps = {
  unionRegisterSeq: number;
  unionRegisterNo: string;
  agent: string;
  name: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
  electronicVoteStatus: string;
  certificateIssuanceStatus: string;
  certificateFileSeq?: number;
};

export interface CertificationDetailTableColumnProps {}

export const CERTIFICATION_DETAIL_TABLE_COLUMNS = ({
  onNameClick,
  onDownloadClick,
  getCommonCode,
}: GridColumnsHandlers<{
  onNameClick: (unionRegisterSeq: number) => void;
  onDownloadClick: (...fileSeqs: number[]) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.unionRegisterSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'receivedAt',
      headerName: '수신일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'openedAt',
      headerName: '열람일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'votedAt',
      headerName: '투표일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'electronicVoteStatus',
      headerName: '전자투표 여부',
      valueGetter: (value) => getCommonCode('VOTING_STATUS_TYPE', value)?.name,
      renderCell: ({ value }) => {
        return value && <Chip>{value}</Chip>;
      },
    },
    {
      field: 'certificateIssuanceStatus',
      headerName: '발급상태',
      valueGetter: (value) =>
        getCommonCode('MEET_CERT_ISSUABLE_STATUS', value)?.name,
      renderCell: (params) => {
        const { value, row } = params;
        const { certificateIssuanceStatus, certificateFileSeq } = row;

        if (certificateIssuanceStatus === 'COMPLETE' && certificateFileSeq)
          return (
            <Button
              variant="outlined"
              color="neutral"
              size="sm"
              onClick={() => onDownloadClick(certificateFileSeq)}
            >
              다운로드
            </Button>
          );

        return value && <Chip>{value}</Chip>;
      },
    },
  ] as GridColDef<CertificationDetailTableProps>[];
