import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  IconButton,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from '@wooriga/design-system';
import * as _ from 'lodash-es';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ELECTRONIC_POSTS_RECIPIENTS_TABLE_COLUMNS,
  ElectronicPostsRecipientsDetailTableProps,
} from 'apis/electronic-posts/fixtures/recipient';
import { useUnionRegistersQuery } from 'apis/union/register/apis';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultParams = {
  unionRegisterNo: '',
  name: '',
  phoneNo: '',
  localAddress: '',
  realAddress: '',
};

const OpenPage = () => {
  const navigate = useNavigate();

  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);
  const { snackbar } = useFeedback();

  const [params] = useState(defaultParams);

  const [selectedUnionID, setSelectedUnionID] = useState(0);

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [selectedCellIds, setSelectedCellIds] = useState<GridRowSelectionModel>(
    [],
  );

  const [selectedUnionCellIds, setSelectedUnionCellIds] =
    useState<GridRowSelectionModel>([]);

  const [assignedItems, setAssignedItems] = useState<
    ElectronicPostsRecipientsDetailTableProps[]
  >([]);

  const handleLinkClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    setSelectedUnionID(Number(id));
  };

  const { data, isLoading, isError, error } = useUnionRegistersQuery(
    unionId,
    params,
  );

  const rows = useMemo(() => {
    const formattedData = data?.data.map((row) => {
      const {
        unionRegisterSeq,
        unionRegisterNo,
        shareType,
        positionDescription,
        name,
        birth,
        memo,
        mainPhone,
        localAddresses,
        postAddress,
        agent,
      } = row;

      return {
        agent,
        birth,
        id: unionRegisterSeq,
        unionRegisterNo,
        shareType,
        positionDescription,
        name: name?.name,
        memo,
        phoneNo: mainPhone?.phoneNo || '',
        localAddresses,
        postAddress: postAddress?.address || '',
      };
    });

    return _.differenceBy(formattedData, assignedItems, 'id') || [];
  }, [data?.data, assignedItems]);

  const handleSubmit = () => {
    if (assignedItems.length === 0) {
      snackbar('선택 대상이 없습니다.', { color: 'danger' });
      return;
    }
    if (assignedItems.length > 1000) {
      snackbar('최대 1,000명까지 등록이 가능합니다.', { color: 'danger' });
      return;
    }
    navigate('create', {
      state: {
        data: assignedItems,
      },
    });
  };

  const handleAddUnion = () => {
    setAssignedItems((prev) => [
      ...prev,
      ..._.filter(rows, (row) => _.includes(selectedCellIds, row.id)),
    ]);

    setSelectedCellIds([]);
  };

  const handleRemoveUnion = () => {
    setAssignedItems((prev) =>
      _.filter(prev, (row) => !_.includes(selectedUnionCellIds, row.id)),
    );
    setSelectedUnionCellIds([]);
  };

  if (isError) throw error;

  return (
    <>
      <Stack gap={2}>
        <Stack maxWidth={600} marginBottom={3}>
          <Stepper>
            <Step
              indicator={
                <StepIndicator variant="solid" color="primary">
                  1
                </StepIndicator>
              }
            >
              수신 대상자 선택
            </Step>
            <Step
              indicator={
                <StepIndicator variant="soft" color="primary">
                  2
                </StepIndicator>
              }
            >
              등기 입력
            </Step>
            <Step
              indicator={
                <StepIndicator variant="soft" color="primary">
                  3
                </StepIndicator>
              }
            >
              등기 생성
            </Step>
          </Stepper>
        </Stack>

        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(data?.pagination?.totalDataCount || 0)}
              </Typography>
            </Typography>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택{' '}
              <Typography color="primary">
                {commaizeNumber(selectedCellIds.length)}
              </Typography>
            </Typography>
          </Stack>
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={ELECTRONIC_POSTS_RECIPIENTS_TABLE_COLUMNS(
                handleLinkClick,
              )}
              loading={isLoading}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={setSelectedCellIds}
              slotProps={{ toolbar: { showQuickFilter: true } }}
              isRowSelectable={({ row }) => {
                if ((row?.name && row.phoneNo && row.birth) || !!row?.agent) {
                  return true;
                }
                return false;
              }}
            />
          </Stack>

          <Stack direction="row" justifyContent="center" gap={1}>
            <IconButton
              variant="outlined"
              onClick={handleAddUnion}
              disabled={selectedCellIds.length === 0}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
            <IconButton variant="outlined" onClick={handleRemoveUnion}>
              <KeyboardArrowUpIcon />
            </IconButton>
          </Stack>

          <Stack height={442}>
            <DataGrid
              rows={assignedItems}
              columns={ELECTRONIC_POSTS_RECIPIENTS_TABLE_COLUMNS(
                handleLinkClick,
              )}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={setSelectedUnionCellIds}
            />
          </Stack>

          <Stack flexDirection="row" justifyContent="flex-end">
            <Button size="lg" onClick={handleSubmit}>
              수신인 확정 및 전자 등기 입력
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {!!selectedUnionID && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq: unionId, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

const route: CustomRouteObject = {
  path: 'recipient',
  index: true,
  element: <OpenPage />,
  handle: {
    getTitle: () => '전자 등기 개설',
  },
};

const openPage = {
  path: '/unions/:unionSeq/electronic-posts',
  children: [route],
  handle: {
    getTitle: () => '전자 등기',
  },
};

export default openPage;
