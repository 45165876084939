import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  Checkbox,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

// import useFeedback from 'hooks/useFeedback';
import { commaizeNumber } from 'utils/format';

export interface ElectronicPostsAddProps {
  open: boolean;
  onClose: (value: boolean) => void;
  totalCost: number;
  electronicDocumentSeq: number;
  handleSendDocument: (documentSeq: number) => void;
}

const ElectronicPostsBalanceCheckModal = (props: ElectronicPostsAddProps) => {
  const {
    onClose,
    handleSendDocument,
    electronicDocumentSeq,
    totalCost,
    ...rest
  } = props;

  const [checked, setChecked] = useState<boolean>(false);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.currentTarget.checked);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose(false);
  };

  const handleSubmit = () => {
    handleSendDocument(electronicDocumentSeq);
    onClose(false);
  };

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog maxWidth="sm">
          <DialogTitle>전자 등기 발송</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack direction="column" gap={2.5}>
              <Callout
                variant="outlined"
                color="warning"
                startDecorator={<InfoIcon />}
              >
                주의사항에 동의해주세요.
              </Callout>

              <Stack direction="column" alignItems="center" gap={2.5}>
                <Box width="100%">
                  <Descriptions columns={1}>
                    <DescriptionsItem label="비용">
                      {commaizeNumber(totalCost)} 원
                    </DescriptionsItem>
                  </Descriptions>
                </Box>

                <Typography level="body-md" color="neutral" textAlign="center">
                  전자 등기 내용 및 수신자 정보에 오류가 없는지 다시 한번
                  확인해주세요.
                  <br />
                  전자 등기의 모든 내용은 작성자에게 책임이 있으며,
                  <br />
                  발송 된 전자 등기는 취소가 불가능합니다.
                </Typography>

                <Checkbox
                  variant="outlined"
                  color="primary"
                  label="위 사항을 모두 확인하였습니다."
                  checked={checked}
                  onChange={handleChecked}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button disabled={!checked} onClick={handleSubmit}>
              제출
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default ElectronicPostsBalanceCheckModal;
