import {
  useColorScheme as useJoyColorScheme,
  CssVarsProvider as JoyCssVarsProvider,
} from '@mui/joy/styles';
import type { SupportedColorScheme, Theme } from '@mui/joy/styles/types';
import { CssBaseline } from '@mui/material';
import { type Theme as MaterialTheme } from '@mui/material/styles';
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
  useColorScheme as useMaterialColorScheme,
} from '@mui/material/styles';
import { CssVarsProviderConfig } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import { ko } from 'date-fns/locale/ko';
import { PropsWithChildren, useEffect } from 'react';

import { LICENSE_KEY } from 'design-system/providers/license';
import { createTheme } from 'design-system/providers/theme';

LICENSE_KEY && LicenseInfo.setLicenseKey(LICENSE_KEY);

type ModeChangerProps = {
  mode?: SupportedColorScheme;
};
const SyncThemeMode = ({ mode = 'light' }: ModeChangerProps) => {
  const { setMode: setMaterialMode, setColorScheme: setMaterialColorScheme } =
    useMaterialColorScheme();
  const { setMode: setJoyMode, setColorScheme: setJoyColorScheme } =
    useJoyColorScheme();

  useEffect(() => {
    setJoyMode(mode);
    setJoyColorScheme(mode);
    setMaterialMode(mode);
    setMaterialColorScheme(mode);
  }, [
    mode,
    setJoyColorScheme,
    setJoyMode,
    setMaterialColorScheme,
    setMaterialMode,
  ]);

  return null;
};

type DesignSystemProviderProps = {
  mode?: SupportedColorScheme;
  theme?: { theme?: Theme; marterialTheme?: MaterialTheme };
} & CssVarsProviderConfig<SupportedColorScheme>;

const DesignSystemProvider = ({
  mode = 'light',
  children,
  theme: themeProp,
  ...rest
}: PropsWithChildren<DesignSystemProviderProps>) => {
  const { materialTheme, theme } = createTheme(themeProp);

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider {...rest} theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
          <CssBaseline enableColorScheme />

          <SyncThemeMode mode={mode} />

          {children}
        </LocalizationProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default DesignSystemProvider;
